import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BackButton } from './components/BackButton'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@Views'
import { initSignupData } from '@Store/persisted/userSlice'
import { api } from '@Api'
import { cloneObjWithKeys } from '@Libs/hepler'
import { toastSuccess, toastError } from '@Libs/toast'

export default function CompletePage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const signupData = useSelector((state) => state.persist.signupData)

    useEffect(() => {
        if (!signupData.provider?.domain) navigate('/signup/booking-provider')
    }, [signupData, navigate])

    const handleBack = () => {
        navigate('/signup/invite-guide')
    }

    const handleSubmit = async () => {
        try {
            setLoading(true)
            const dataToCreate = cloneObjWithKeys(signupData, [
                'agency',
                'email',
                'password',
                'firstname',
                'lastname',
                'inviteEmails',
                'phone',
                'provider',
                'verifyToken',
                'source',
            ])
            // check bokun connection
            const data = await api.checkBokunConnection(dataToCreate.provider)
            if (data.invalidKey) {
                toastError(t('bookingProvider.bokunConnectionFailed'))
                return
            }
            // upload logo
            const logoAgency = signupData.agency?.logo
            if (logoAgency && logoAgency instanceof File) {
                const file = new FormData()
                file.append('file', logoAgency)
                file.append('fileName', `agency_logo`)
                const resUpload = await api.uploadAgencyLogo(file)
                Object.assign(dataToCreate, {
                    agency: { ...dataToCreate.agency, logo: resUpload.file },
                })
            } else {
                Object.assign(dataToCreate, {
                    agency: { ...dataToCreate.agency, logo: '' },
                })
            }
            // create user + agency
            await api.createAgencyUser({
                ...dataToCreate,
                loginLink: `${window.location.origin}/signin`,
                inviteLink: `${window.location.origin}/guide/signup`,
                phone: `${signupData.phonePrefix} ${signupData.phone}`,
                role: 'agency',
                isActived: true,
            })
            toastSuccess(t('complete.createSuccess'))
            navigate('/signin')
            setTimeout(() => dispatch(initSignupData({})), 100)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <BackButton onClick={handleBack} />
            <div className="mt-12 mb-7">
                <div className="text-2xl my-9 font-semibold text-center">{t('complete.title')}</div>
                <Button
                    text={'button.continue'}
                    loading={loading}
                    onClick={handleSubmit}
                    classes={{ button: 'mt-5 px-8 w-full font-medium' }}
                />
            </div>
        </>
    )
}
