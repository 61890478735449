/* eslint-disable jsx-a11y/alt-text */
import { useState, memo } from 'react'
import { Modal, Loading } from '@Views'
import ImageCrop from './ImageCrop'
import { api } from '@Api'
import { Icon } from '@Views'

const UploadAvatar = ({ onUploaded, isUpload = false, aspectImage, data = null }) => {
    const [loading, setLoading] = useState(false)
    const [isShowModalCrop, setShowModalCrop] = useState(false)
    const [fileImageSave, setFileImageSave] = useState({})

    const handleCrop = async (image, crop) => {
        setLoading(true)
        setShowModalCrop(false)
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )
        //convert to file----------------------------------
        const blob = await new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    // Reject if not blob is returned
                    reject(new Error('Canvas is empty'))
                    return
                }
                blob.name = `newFile.jpeg`
                resolve(blob)
            }, 'image/jpeg')
        })
        const file = new File([blob], 'newFile.jpeg', { type: 'image/jpeg' })

        let resFiles = []
        if (isUpload) {
            let formData = new FormData()
            formData.append('files', file)
            formData.append('fileName', `avatar_picture`)
            resFiles = await api.uploadFile(formData)
        } else {
            resFiles = file
        }

        setLoading(false)
        onUploaded(resFiles)
    }

    return (
        <div>
            {isShowModalCrop && (
                <Modal
                    classes={{
                        content: 'max-w-[80%]',
                        children: '!p-0 !pb-2 text-center !max-h-t_screen_s10',
                    }}
                    title="cropImage"
                    onCancel={() => setShowModalCrop(false)}
                >
                    <ImageCrop
                        src={URL.createObjectURL(fileImageSave)}
                        onCancel={() => setShowModalCrop(false)}
                        onSave={(image, crop) => handleCrop(image, crop)}
                        aspectImage={aspectImage}
                    />
                </Modal>
            )}
            <label className="w-20 h-20 rounded-full cursor-pointer flex items-center justify-center relative">
                <input
                    className="w-0 h-0"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                        const file = e.target.files[0]
                        setFileImageSave(file)
                        setShowModalCrop(true)
                        e.target.value = ''
                    }}
                />
                {loading ? (
                    <Loading isAbsolute />
                ) : (
                    <div className="absolute top-0 right-0">
                        <Icon name="edit" classes="w-7 h-7" />
                    </div>
                )}
                {data ? (
                    <img src={data} className="w-full h-full rounded-full object-cover" />
                ) : (
                    <div className="bg-t_gray_350 w-full h-full flex justify-center items-center rounded-full">
                        <span className="text-white text-lg">+</span>
                    </div>
                )}
            </label>
        </div>
    )
}
export default memo(UploadAvatar)
