/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { List, GuideForm, GuideItem } from '@Views'
import { guideList } from '@Constants'
import { api } from '@Api'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default function GuidePage() {
    const { t } = useTranslation()
    const user = useSelector((state) => state.persist.user)
    const params = useSelector((state) => state.user.params)
    const [loading, setLoading] = useState(false)
    const [dataList, setDataList] = useState([])

    useEffect(() => {
        handleGetData()
    }, [params])

    const handleGetData = async () => {
        try {
            setLoading(true)
            const result = await api.getUser({
                ...params,
                limit: 10,
                role: 'guide',
                agency: user.agency?._id,
            })
            setDataList(result?.users)
        } finally {
            setLoading(false)
        }
    }

    const handleActionConfirm = async ({ currentItem, actionType }) => {
        if (actionType === 'delete' && currentItem._id) {
            await api.deleteUser(currentItem._id)
        }
        if (actionType === 'changeStatus' && currentItem._id) {
            await api.updateUser(currentItem._id, { isActived: !currentItem.isActived })
        }
        handleGetData()
    }

    return (
        <>
            <div className="text-3xl font-bold my-6">{t('guide.myGuides')}</div>
            <List
                listName="guide-list"
                heads={guideList}
                loading={loading}
                data={dataList}
                classes={{ container: '!py-3' }}
                component={{ item: GuideItem, form: GuideForm }}
                modal={{
                    form: {
                        addTitle: '',
                        editTitle: '',
                        class: 'w-[580px]',
                    },
                }}
                button={{ create: { title: 'button.addGuide' } }}
                onActionConfirm={handleActionConfirm}
                onReload={handleGetData}
                itemPerPage={10}
                totalText={t('guide.teamMembers')}
            />
        </>
    )
}
