import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { BackButton } from './components/BackButton'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import VerificationInput from 'react-verification-input'
import { Button, Loading } from '@Views'
import { setSignupData } from '@Store/persisted/userSlice'
import { api } from '@Api'

export default function VerifyCodePage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const agencyToken = new URLSearchParams(useLocation().search).get('token')
    const nextUrl = agencyToken
        ? `/guide/signup/set-password?token=${agencyToken}`
        : '/signup/set-password'
    const backUrl = agencyToken ? `/guide/signup?token=${agencyToken}` : '/signup'
    const signupData = useSelector((state) => state.persist.signupData)
    const [seconds, setSeconds] = useState(60)
    const [loading, setLoading] = useState(false)
    const [loadingVerify, setLoadingVerify] = useState(false)
    const [isWrongCode, setIsWrongCode] = useState(false)

    useEffect(() => {
        if (signupData.agencyToken && signupData.agencyToken !== agencyToken) {
            navigate(`/guide/signup?token=${signupData.agencyToken}`)
        }
    }, [agencyToken, signupData])

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds === 0) {
                    clearInterval(interval)
                    return 0 // Stop the countdown
                } else {
                    return prevSeconds - 1
                }
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [seconds])

    useEffect(() => {
        if (!signupData.verifyToken) navigate(backUrl)
    }, [signupData, navigate])

    const handleBack = () => {
        navigate(backUrl)
    }

    const handleCompleteCode = async (code) => {
        try {
            setLoadingVerify(false)
            const data = await api.verifyCode({ code, verifyToken: signupData.verifyToken })
            const isWrongCode = data.isWrongCode ?? true
            setIsWrongCode(isWrongCode)
            if (!isWrongCode) navigate(nextUrl)
        } finally {
            setLoadingVerify(false)
        }
    }

    const handleResendCode = async () => {
        try {
            setLoading(true)
            const data = await api.createCode({ email: signupData.email })
            dispatch(setSignupData({ verifyToken: data.token }))
            setSeconds(60)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <BackButton onClick={handleBack} />
            <div className="mt-14 mb-6">
                <div className="text-2xl mt-5 font-semibold">{t('verifyCode.title')}</div>
                <p className="text-t_gray_500 mt-2 mb-5">
                    {t('verifyCode.description')}
                    <span className="font-medium ml-1">{signupData.email}</span>
                </p>
                <div className="relative">
                    <VerificationInput
                        onComplete={handleCompleteCode}
                        onChange={() => setIsWrongCode(false)}
                        classNames={{
                            container: 'w-full gap-5',
                            character: 'rounded-lg bg-white border-t_gray_400',
                            characterSelected:
                                'border-t_purple_500 outline-t_purple_500_12 text-t_purple_500',
                        }}
                    />
                    {loadingVerify && (
                        <Loading classes={{ container: 'bg-t_gray_250' }} isAbsolute />
                    )}
                </div>

                {isWrongCode && <p className="text-red-500 mt-2">{t('verifyCode.wrongCode')}</p>}
                {seconds ? (
                    <p className="text-t_gray_500 mt-10">
                        {t('verifyCode.resendAfterTime', { seconds: seconds })}
                    </p>
                ) : (
                    <Button
                        text={'verifyCode.resend'}
                        loading={loading}
                        onClick={handleResendCode}
                        classes={{ button: 'mt-10 px-8 font-medium' }}
                    />
                )}
            </div>
        </>
    )
}
