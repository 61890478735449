export const loginValidation = [
    {
        key: 'email',
        valid: ['required', 'email'],
    },
    {
        key: 'password',
        valid: ['required', 'password'],
    },
]

export const signupValidation = [
    {
        key: 'email',
        valid: ['required', 'email'],
    },
    {
        key: 'term',
        valid: ['term'],
    },
]

export const setPasswordValidation = [
    {
        key: 'password',
        valid: ['required', 'password'],
    },
]

export const signupInformationValidation = [
    {
        key: 'firstname',
        valid: ['required'],
    },
    {
        key: 'lastname',
        valid: ['required'],
    },
    {
        key: 'phone',
        valid: ['required'],
    },
    {
        key: 'agencyName',
        valid: ['required'],
    },
]

export const signupGuideInformationValidation = [
    {
        key: 'firstname',
        valid: ['required'],
    },
    {
        key: 'lastname',
        valid: ['required'],
    },
    {
        key: 'phone',
        valid: ['required'],
    },
    {
        key: 'city',
        valid: ['required'],
    },
    // {
    //     key: 'favoriteTours',
    //     valid: ['required'],
    // },
    {
        key: 'language',
        valid: ['required'],
    },
]

export const guideInformationValidation = [
    {
        key: 'firstname',
        valid: ['required'],
    },
    {
        key: 'lastname',
        valid: ['required'],
    },
    {
        key: 'phone',
        valid: ['required'],
    },
    {
        key: 'city',
        valid: ['required'],
    },
    {
        key: 'favoriteTours',
        valid: ['required'],
    },
    {
        key: 'language',
        valid: ['required'],
    },
    {
        key: 'email',
        valid: ['required'],
    },
    {
        key: 'priority',
        valid: ['required'],
    },
]

export const bookingProviderValidation = [
    {
        key: 'domain',
        valid: ['required'],
    },
    {
        key: 'accessKey',
        valid: ['required'],
    },
    {
        key: 'secretKey',
        valid: ['required'],
    },
]

export const resetPassValidation = [
    {
        key: 'password',
        valid: ['required', 'password'],
    },
    {
        key: 'passwordRepeat',
        valid: ['repeatPassword'],
    },
]
