export const privacyPolicy = `
##### **Politique de Confidentialité de Tour Assist**

###### **Dernière mise à jour : 24 Aout 2024**

###### **1. Introduction**

‍Bienvenue sur Tour Assist.

La présente Politique de Confidentialité a pour objet de vous informer de la manière dont nous collectons, utilisons et protégeons vos informations personnelles lorsque vous utilisez notre webapp.  

###### **Responsable du traitement des données** :

**Nom de l’entreprise** : Tour Assist

**Adresse email de contact**: admin@bookadayin.com

‍Nous n'avons pas de Délégué à la Protection des Données (DPO). Pour toute question relative à cette politique de confidentialité ou au traitement de vos données personnelles, vous pouvez nous contacter à l'adresse email susmentionnée.

###### **2. Collecte des Données**

‍Nous collectons et traitons les données personnelles suivantes via notre webapp Tour Assist :

Nom

Prénom

Adresse email

Numéro de téléphone

Ville

###### **Méthodes de collecte** :

**Formulaire d'inscription** : Lorsque vous créez un compte ou vous inscrivez pour utiliser nos services.

**API via Bokun** : Lorsque vous effectuez des réservations ou interagissez avec des services liés à Bokun.

###### **3. Utilisation des Données**

‍Les données personnelles collectées sont utilisées aux fins suivantes :

###### **Gestion des réservations** : Pour organiser et gérer vos réservations de visites guidées via notre plateforme.

**Envoi de notifications** : Pour vous envoyer des notifications concernant vos visites, telles que des confirmations, modifications ou rappels.

**Accès à l'espace utilisateur** : Pour vous permettre d'accéder à votre espace personnel où vous pouvez gérer vos réservations et préférences.

**Base légale du traitement** :Le traitement de vos données personnelles est fondé sur le consentement que vous nous donnez en utilisant nos services.


###### **4. Partage des Données**

‍Vos données personnelles ne sont pas partagées avec des tiers. Elles sont traitées exclusivement par Tour Assist et ne sont pas transférées en dehors de l'Union Européenne.

###### **5. Sécurité des Données**

‍Nous mettons en œuvre des mesures de sécurité strictes pour protéger vos données personnelles contre tout accès non autorisé, utilisation abusive ou divulgation. Notamment :

###### **Accès restreint aux données** : Seul le personnel autorisé, ayant besoin de ces informations pour accomplir ses tâches, peut accéder aux données personnelles.

###### **6. Conservation des Données**

‍Nous conservons vos données personnelles aussi longtemps que vous maintenez un compte actif sur Tour Assist. Si vous supprimez votre compte, toutes vos données personnelles seront effacées immédiatement de nos systèmes.

###### **7. Droits des Utilisateurs**

Vous avez le droit de demander la suppression de votre compte ainsi que des données personnelles associées. Pour exercer ce droit, veuillez nous contacter par email à **admin@bookadayin.com**. Nous traiterons votre demande dans les plus brefs délais.

###### **8. Cookies et Technologies Similaires**

Tour Assist n'utilise pas de cookies ni d'autres technologies de suivi. Aucune information n'est collectée via des cookies pour analyser les performances, personnaliser l'expérience utilisateur ou à toute autre fin.


###### **9. Modifications de la Politique**

‍Nous nous réservons le droit de modifier cette Politique de Confidentialité à tout moment. En cas de modification, nous vous en informerons par email à l'adresse que vous avez fournie lors de votre inscription. Il est recommandé de consulter régulièrement cette politique pour rester informé des éventuels changements.
`
