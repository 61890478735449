/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, Loading, NotificationItem, BookingForm, Button, Modal } from '@Views'
import { useState, useMemo, useEffect, useRef } from 'react'
import { api } from '@Api'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom'
import { toastError, toastSuccess } from '@Libs/toast'
import { setParams } from '@Store/userSlice'

export default function StatisticsPage() {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state) => state.persist.user)

    const params = useSelector((state) => state.user.params)
    const screenHeight = useMemo(() => document.documentElement.clientHeight, [])
    const [loading, setLoading] = useState(false)
    const [dataList, setDataList] = useState([])
    const [dataListUnread, setDataListUnread] = useState([])
    const elementRef = useRef(null)
    const [notificationType, setNotificationType] = useState({ name: 'all' })
    const [isShowFormModal, setIsShowFormModal] = useState(false)
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [currentNotifi, setCurrentNotifi] = useState({})
    const [actionType, setActionType] = useState('')

    useEffect(() => {
        handleGetData()
    }, [params])

    useEffect(() => {
        changeTab(notificationType)
    }, [notificationType])

    const changeTab = (tab) => {
        navigate(
            {
                search: `?${createSearchParams({
                    tab: tab.name,
                })}`,
            },
            { replace: true }
        )
    }

    const handleGetData = async () => {
        setLoading(true)
        await getListAll()
        await getListUnread()
        setLoading(false)
    }

    const getListAll = async () => {
        const res = await api.getNotify({
            ...params,
            limit: 5,
            guide: user._id,
            recipientType: 'guide',
        })
        setDataList(res.notifys)
    }

    const getListUnread = async () => {
        const res = await api.getNotify({
            ...params,
            limit: 5,
            seen: false,
            guide: user._id,
            recipientType: 'guide',
        })
        setDataListUnread(res.notifys)
    }

    const handleActionClick = ({ type, data }) => {
        setIsShowFormModal(false)
        setIsShowConfirmModal(true)
        setCurrentItem(data)
        setActionType(type)
    }

    const handleAction = async ({ dataItem, type }) => {
        setCurrentItem(dataItem.bookingInfo || {})
        setCurrentNotifi(dataItem || {})
        if (type === 'seeDetail') {
            setIsShowFormModal(true)
        }
        if (type === 'decline') {
            setActionType(type)
            setIsShowConfirmModal(true)
        }
    }

    const handleActionConfirm = async () => {
        setConfirmLoading(true)
        if (actionType === 'decline') {
            await api.updateBooking(currentItem._id, { status: 'UNSTAFFED', guide: '' })
        }
        if (actionType === 'accept') {
            await api.updateBooking(currentItem._id, { status: 'STAFFED' })
            toastSuccess(
                <div>
                    <div className="font-bold text-base">
                        {t('calendarNotification.acceptedTheTour')}
                    </div>
                    <div className="text-sm">{t('calendarNotification.hasBeenNotified')}</div>
                </div>
            )
        }
        if (actionType === 'cancel') {
            await api.updateBooking(currentItem._id, { status: 'CANCELLED' })
            toastError(
                <div>
                    <div className="font-bold text-base">
                        {t('calendarNotification.cancelledATour')}
                    </div>
                    <div className="text-sm">{t('calendarNotification.hasBeenNotified')}</div>
                </div>
            )
        }
        setConfirmLoading(false)
        setIsShowConfirmModal(false)
        setLoading(true)
        // await api.updateNotify(currentNotifi._id, { seen: true })
        await getListAll()
        setLoading(false)
    }

    return (
        <>
            <div className={`bg-white w-full rounded-2xl`}>
                <p className="p-6 text-xl font-semibold">{t('notification.notifications')}</p>
                <div className="flex space-x-6 px-6">
                    <div
                        className={classNames(
                            'text-base font-semibold h-8 cursor-pointer w-20 flex justify-center',
                            searchParams.get('tab') === 'all' && 'border-b-2 border-t_blue_500'
                        )}
                        onClick={() => {
                            setNotificationType({ name: 'all' })
                            dispatch(setParams({ offset: 0 }))
                        }}
                    >
                        {t('notification.all')}
                        <div className="bg-[#F2F4F7] px-2 rounded-full h-6 ml-2 text-xs flex items-center">
                            {dataList?.totalDocs ?? 0}
                        </div>
                    </div>
                    <div
                        className={classNames(
                            'text-base font-semibold h-8 cursor-pointer w-28 flex justify-center',
                            searchParams.get('tab') === 'unread' &&
                                '!text-t_blue_500 border-b-2 border-t_blue_500'
                        )}
                        onClick={() => {
                            setNotificationType({ name: 'unread' })
                            dispatch(setParams({ offset: 0 }))
                        }}
                    >
                        {t('notification.unread')}
                        <div className="bg-[#F2F4F7] px-2 rounded-full h-6 ml-2 text-xs flex items-center">
                            {dataListUnread?.totalDocs ?? 0}
                        </div>
                    </div>
                </div>
                {loading && (
                    <div
                        style={{ height: screenHeight - 160 }}
                        className="flex justify-center items-center"
                    >
                        <Loading className="w-10 h-10 animate-spin text-center text-t_cyan_500 mt-10" />
                    </div>
                )}
                {!loading &&
                    ((searchParams.get('tab') === 'all' && !dataList.docs?.length) ||
                        (searchParams.get('tab') === 'unread' && !dataListUnread.docs?.length)) && (
                        <div className="text-red-500 mt-10 text-center">
                            {t('validation.errorNotFound')}
                        </div>
                    )}
                <div ref={elementRef} className="sm:overflow-auto sm:h-[calc(100vh-160px)] pb-10">
                    {!loading && (
                        <>
                            <div className="mt-12">
                                {searchParams.get('tab') === 'all' &&
                                    dataList.docs?.map((item, idx) => (
                                        <div key={idx} className="">
                                            <NotificationItem data={item} onAction={handleAction} />
                                        </div>
                                    ))}
                            </div>

                            <div className="mt-12">
                                {searchParams.get('tab') === 'unread' &&
                                    dataListUnread.docs?.map((item, idx) => (
                                        <div key={idx} className="">
                                            <NotificationItem data={item} onAction={handleAction} />
                                        </div>
                                    ))}
                            </div>

                            <div className="px-4">
                                {searchParams.get('tab') === 'all' && dataList.totalPages > 1 && (
                                    <Pagination
                                        itemPerPage={5}
                                        totalPages={dataList?.totalPages ?? 0}
                                        currentPage={dataList?.page ? dataList.page - 1 : 0}
                                    />
                                )}
                                {searchParams.get('tab') === 'unread' &&
                                    dataListUnread.totalPages > 1 && (
                                        <Pagination
                                            itemPerPage={5}
                                            totalPages={dataListUnread?.totalPages ?? 0}
                                            currentPage={
                                                dataListUnread?.page ? dataListUnread.page - 1 : 0
                                            }
                                        />
                                    )}
                            </div>
                        </>
                    )}
                </div>

                {isShowFormModal && (
                    <Modal
                        title={'Tour management'}
                        classes={{ content: '', children: '' }}
                        onCancel={() => setIsShowFormModal(false)}
                    >
                        <BookingForm
                            item={currentItem}
                            onActionClick={handleActionClick}
                            onCancel={() => setIsShowFormModal(false)}
                        />
                    </Modal>
                )}

                {isShowConfirmModal && (
                    <Modal onCancel={() => setIsShowConfirmModal(false)}>
                        <>
                            <p className="text-center pt-4 text-3xl font-bold whitespace-pre-line">
                                {t('modal.areYouSure')}
                            </p>
                            {actionType === `cancel` && (
                                <p className="text-center pt-2 text-base font-normal whitespace-pre-line">
                                    {t('modal.youAreAboutToCancel')}
                                </p>
                            )}
                            <div className="flex space-x-2 sm:space-x-6 justify-center my-3 pt-6">
                                <Button
                                    text={t('button.seeTheDetailsAgain')}
                                    classes={{
                                        button: '!px-2 sm:px-9 py-2 bg-white !text-t_purple_500',
                                    }}
                                    onClick={() => {
                                        setIsShowConfirmModal(false)
                                        setIsShowFormModal(true)
                                    }}
                                />
                                <Button
                                    text={t('button.yesIAm')}
                                    classes={{
                                        button: 'px-2 sm:px-7 py-2',
                                    }}
                                    loading={confirmLoading}
                                    onClick={handleActionConfirm}
                                />
                            </div>
                        </>
                    </Modal>
                )}
            </div>
        </>
    )
}
