import { myAxios, myAuthAxios } from '@Api/apiConfig'

export const apiCalls = {
    // Auth
    login: (data) => myAxios.post('/user/login', data),
    sendResetPasswordEmail: (data) => myAxios.post('/user/sendResetPasswordEmail', data),
    resetPassword: (data) => myAxios.put('/user/resetPassword', data),
    checkResetPasswordToken: (params) => myAxios.get('/user/checkResetPasswordToken', { params }),

    // Signup
    createCode: (data) => myAxios.post('/user/createCode', data),
    verifyCode: (data) => myAxios.post('/user/verifyCode', data),
    createGuideUser: (data) => myAxios.post('/user/createGuide', data),
    createAgencyUser: (data) => myAxios.post('/user/createAgencyUser', data),
    uploadAgencyLogo: (data) => myAxios.post('/user/uploadAgencyLogo', data),
    checkInviteToken: (params) => myAxios.get('/user/checkInviteToken', { params }),

    // google / google calendar
    authenticationGoogle: (data) => myAxios.post('/google/authentication', data),
    authenticationGoogleRegister: (data) => myAxios.post('/google/authenticationRegister', data),
    getRefreshTokenGoogle: (data) => myAxios.post('/google/getRefreshToken', data),
    getListGoogleCalendar: (params) => myAuthAxios.get('/google/getListCalendar', { params }),
    getGuildCalendarOfAgency: (params) =>
        myAuthAxios.get('/google/getGuildCalendarOfAgency', { params }),

    //bokun
    checkBokunConnection: (data) => myAxios.post('/bokun/checkBokun', data),
    getBookingBokun: (params) => myAxios.get('/bokun', { params }),
    getActivitiesBokun: (params) => myAxios.get('/bokun/activities', { params }),
    getActivitieInfo: (params) => myAxios.get('/bokun/activitieInfo', { params }),

    // agency
    checkAgencyName: (data) => myAxios.post('/agency/checkName', data),
    uploadMultiple: (data) => myAxios.put(`/agency/uploadMultiple`, data),

    // User
    createGuideByAgency: (data) => myAuthAxios.post('/user/createGuideByAgency', data),
    inviteGuideByAgency: (data) => myAuthAxios.post('/user/inviteGuideByAgency', data),
    updateUser: (id, data) => myAuthAxios.put(`/user/${id}`, data),
    getUser: (params) => myAuthAxios.get('/user', { params }),
    uploadFile: (data) => myAuthAxios.post('/user/uploadFile', data),
    deleteUser: (id) => myAuthAxios.delete(`/user/${id}`),
    resetPasswordGuide: (data) => myAxios.put('/user/resetPassword', data),

    //booking
    createBooking: (data) => myAuthAxios.post('/booking', data),
    getBooking: (params) => myAuthAxios.get('/booking', { params }),
    getTimeOverlap: (params) => myAuthAxios.get('/booking/getTimeOverlap', { params }),
    updateBooking: (id, data) => myAuthAxios.put(`/booking/${id}`, data),
    updateMultiples: (data) => myAuthAxios.put(`/booking/updateMultiples`, data),

    //notify
    getNotify: (params) => myAuthAxios.get('/notify', { params }),
    updateNotify: (id, data) => myAuthAxios.put(`/notify/${id}`, data),
}
