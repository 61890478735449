import { validEmail, validPassword } from './hepler'

export const validateForm = (formValidation, formData) => {
    const errors = {}

    formValidation.forEach((e) => {
        const value = formData[e.key]
        const errorsList = []
        if (e.valid && Array.isArray(e.valid)) {
            if (e.valid.includes('required')) {
                if (
                    value === '' ||
                    typeof value === 'undefined' ||
                    value === null ||
                    (Array.isArray(value) && !value.length > 0)
                ) {
                    errorsList.push({
                        key: e.key,
                        text: e.text ?? 'validation.empty',
                    })
                }
            }
            if (e.valid.includes('invalid')) {
                if (e.condition(value)) {
                    errorsList.push({
                        key: e.key,
                        text: e.messageInvalid || 'validation.invalid',
                    })
                }
            }
            if (e.valid.includes('email')) {
                if (value && !validEmail(value.trim())) {
                    errorsList.push({
                        key: e.key,
                        text: 'validation.invalid',
                    })
                }
            }
            if (e.valid.includes('phone')) {
                if (value && e.phonePrefix === '+33' && value.length !== 9) {
                    errorsList.push({
                        key: e.key,
                        text: 'validation.phoneInvalid',
                    })
                }
            }
            if (e.valid.includes('password')) {
                if (value && !validPassword(value.trim())) {
                    errorsList.push({
                        key: e.key,
                        text: 'validation.passwordInvalid',
                    })
                }
            }
            if (e.valid.includes('repeatPassword')) {
                if (formData['password'] !== formData['passwordRepeat']) {
                    errorsList.push({
                        key: e.key,
                        text: 'validation.passwordNotSame',
                    })
                }
            }
            if (e.valid.includes('term')) {
                if (!value) {
                    errorsList.push({
                        key: e.key,
                        text: 'validation.termRequired',
                    })
                }
            }
            if (errorsList.length) errors[e.key] = errorsList[0].text
        }
    })

    return errors
}
