/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { api } from '@Api'
import { Calendar, BookingForm, Loading, Modal } from '@Views'
import { useSelector } from 'react-redux'
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import { getAppropriateGuide } from '@Libs/hepler'
import { useTranslation } from 'react-i18next'
import { toastSuccess, toastError } from '@Libs/toast'

export default function CalendarPage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [dataList, setDataList] = useState([])
    const [guideList, setGuideList] = useState([])
    const [allGuideList, setAllGuideList] = useState([])
    const [loading, setLoading] = useState(false)
    const [isSaveloading, setIsSaveloading] = useState(false)
    const user = useSelector((state) => state.persist.user)
    const [bookingParam, setBookingParam] = useState({})
    const [isFilling, setIsFilling] = useState(false)
    const [searchParams] = useSearchParams()
    const [isShowFormModal, setIsShowFormModal] = useState(false)
    const [visitList, setVisitList] = useState([])
    const [cityList, setCityList] = useState([])
    const [googleCalendar, setGoogleCalendar] = useState([])

    const [calendarType, setCalendarType] = useState({ name: 'guide-view' })

    useEffect(() => {
        changeTab(calendarType)
    }, [calendarType])

    useEffect(() => {
        handleGetGuides()
    }, [])

    const changeTab = (tab) => {
        navigate(
            {
                search: `?${createSearchParams({
                    tab: tab.name,
                })}`,
            },
            { replace: true }
        )
    }

    const handleGetData = async (param) => {
        try {
            setLoading(true)
            let guides = []
            setBookingParam({ ...param })
            const res = await api.getBooking({ ...param, agency: user.agency?._id })
            const bookings = res?.bookings?.docs || []
            bookings.forEach((element) => {
                if (
                    element.guideInfo &&
                    !(guides || []).map((guide) => guide._id).includes(element.guideInfo._id)
                ) {
                    guides = [...guides, element.guideInfo]
                }
            })
            const resCalendar = await api.getGuildCalendarOfAgency({
                timeMin: param.startDate,
                agencyId: user.agency?._id,
            })
            let googleGuide = []
            ;(resCalendar?.calendarUsers || []).forEach((el) => {
                googleGuide = [...googleGuide, ...el]
            })
            googleGuide = googleGuide.map((el) => {
                const millisBetween = new Date(el.end.dateTime) - new Date(el.start.dateTime)
                return {
                    date: el.start.dateTime,
                    duration: millisBetween / (1000 * 60),
                    guide: el.guide,
                }
            })
            setGoogleCalendar(googleGuide)
            setGuideList(guides)
            setDataList(bookings)
            await handleGetOptions(param.startDate, param)
        } finally {
            setLoading(false)
            setIsSaveloading(false)
            setIsFilling(false)
        }
    }

    const handleGetOptions = async (startDate, param) => {
        let visitTypeList = []
        let cityList = []
        const res = await api.getBooking({
            startDate: startDate,
            agency: user.agency?._id,
        })
        const bookings = res?.bookings?.docs || []
        bookings.forEach((element) => {
            if (!visitTypeList.includes(element.visitType)) {
                visitTypeList.push(element.visitType)
            }
            if (!cityList.includes(element.city) && element.city) {
                cityList.push(element.city)
            }
        })
        if (!visitTypeList.includes(param.visitType) && param.visitType) {
            visitTypeList.push(param.visitType)
        }
        if (!cityList.includes(param.city) && param.city) {
            cityList.push(param.city)
        }
        setVisitList(
            visitTypeList.map((vs) => {
                return { label: vs, value: vs }
            })
        )
        setCityList(
            cityList.map((city) => {
                return { label: city, value: city }
            })
        )
    }

    const handleGetGuides = async () => {
        try {
            const result = await api.getUser({ agency: user.agency?._id, role: 'guide' })
            const users = result.users?.docs || []
            setAllGuideList(users)
        } finally {
        }
    }

    const autoFill = async () => {
        const unstaffedBooking = dataList.filter(
            (booking) => !booking.guide && booking.status !== 'CANCELLED'
        )
        let staffedBooking = dataList.filter(
            (booking) => !!booking.guide && booking.status !== 'CANCELLED'
        )
        for (let index = 0; index < unstaffedBooking.length; index++) {
            const booking = unstaffedBooking[index]
            const timeOverlap =
                (await getTimeOverlap(booking, [...staffedBooking, ...googleCalendar])) || []

            // get availability
            let unavailability = []
            timeOverlap.forEach((bk) => {
                unavailability.push(bk.guide)
            })
            let guideList = []
            guideList = allGuideList.map((guide) => {
                const priority = Number(guide.priority || 5)
                let priorityPoint = 16 * (6 - priority)
                if (!unavailability.includes(guide._id)) {
                    priorityPoint += 20
                }
                return { ...guide, priorityPoint: priorityPoint }
            })

            // get appropriate guide
            let appropriateGuide = await getAppropriateGuide(booking, guideList)

            if (appropriateGuide.length > 0) {
                staffedBooking.push({
                    ...booking,
                    guide: appropriateGuide[0]._id,
                    status: 'PENDING',
                    isChanging: true,
                })
            }
        }
        let bookingList = dataList
        const newBookingList = bookingList.map((booking) => {
            const newBooking = staffedBooking.find((bk) => bk._id === booking._id) || booking
            return { ...newBooking }
        })
        let guides = []
        newBookingList.forEach((element) => {
            if (
                element.guide &&
                !(guides || []).map((guide) => guide._id).includes(element.guide)
            ) {
                const guideInfo = allGuideList.find((guide) => guide._id === element.guide) || {}
                if (guideInfo._id) {
                    guides = [...guides, guideInfo]
                }
            }
        })
        setGuideList(guides)
        setDataList(newBookingList)
        setIsFilling(true)
    }

    const getTimeOverlap = async (booking, staffedBooking) => {
        const startDate = new Date(booking.date)
        const endDate = new Date(
            new Date(booking.date).setMinutes(
                new Date(booking.date).getMinutes() + booking.duration
            )
        )
        const timeOverlap = staffedBooking.filter(
            (bk) =>
                new Date(bk.date) < endDate &&
                new Date(
                    new Date(bk.date).setMinutes(new Date(bk.date).getMinutes() + bk.duration)
                ) > startDate
        )
        return timeOverlap || []
    }

    const saveModifications = async () => {
        try {
            setIsSaveloading(true)
            const bookings = dataList.filter((bk) => bk.isChanging)
            await api.updateMultiples({ bookings: bookings, agency: user.agency?._id })
            toastSuccess(
                <div>
                    <div className="font-bold text-base">
                        {t('calendarNotification.planningUpdated')}
                    </div>
                    <div className="text-sm">{t('calendarNotification.allGuidesNotified')}</div>
                </div>
            )
            setIsFilling(false)
            handleGetData(bookingParam)
        } catch (error) {
            toastError(t('calendarNotification.updateHasFailed'))
        }
    }

    const handleActionConfirm = async ({ currentItem }) => {
        let bookingList = dataList
        const newBookingList = bookingList.map((booking) => {
            const newBooking =
                booking._id === currentItem._id ? { ...currentItem, isChanging: true } : booking
            return { ...newBooking }
        })
        let guides = []
        newBookingList.forEach((element) => {
            if (
                element.guide &&
                !(guides || []).map((guide) => guide._id).includes(element.guide)
            ) {
                const guideInfo = allGuideList.find((guide) => guide._id === element.guide) || {}
                if (guideInfo._id) {
                    guides = [...guides, guideInfo]
                }
            }
        })
        setGuideList(guides)
        setDataList(newBookingList)
        setIsFilling(true)
    }

    return (
        <div className="w-full relative">
            <div className="absolute right-8 flex space-x-4 top-4 cursor-pointer">
                {!isFilling && (
                    <div
                        className={classNames(
                            'text-xs px-2 h-8 bg-white flex justify-center items-center rounded-md font-bold'
                        )}
                        onClick={() => setIsShowFormModal(true)}
                    >
                        {t('calendar.addTour')}
                    </div>
                )}
                <div
                    className={classNames(
                        'text-xs px-2 h-8 bg-white flex justify-center items-center rounded-md font-bold'
                    )}
                    onClick={() => autoFill()}
                >
                    {t('calendar.autoFill')}
                </div>
                {isFilling && (
                    <div className="flex space-x-4">
                        <div
                            className={classNames(
                                'text-xs text-white px-4 h-8 bg-t_blue_500 flex justify-center items-center rounded-md font-bold space-x-2'
                            )}
                            onClick={() => saveModifications()}
                        >
                            <p>{t('calendar.saveModifications')}</p>
                            {isSaveloading && <Loading />}
                        </div>
                        <div
                            className={classNames(
                                'text-xs text-white px-4 h-8 bg-t_red_400 flex justify-center items-center rounded-md font-bold'
                            )}
                            onClick={() => handleGetData(bookingParam)}
                        >
                            {t('calendar.cancelModifications')}
                        </div>
                    </div>
                )}
                <div
                    className={classNames(
                        'text-base font-semibold text-t_gray_260 h-8 cursor-pointer',
                        searchParams.get('tab') === 'guide-view' &&
                            '!text-t_blue_500 border-b-2 border-t_blue_500'
                    )}
                    onClick={() => setCalendarType({ name: 'guide-view' })}
                >
                    {t('calendar.guideView')}
                </div>
                <div
                    className={classNames(
                        'text-base font-semibold text-t_gray_260 h-8 cursor-pointer',
                        searchParams.get('tab') === 'calendar-view' &&
                            '!text-t_blue_500 border-b-2 border-t_blue_500'
                    )}
                    onClick={() => setCalendarType({ name: 'calendar-view' })}
                >
                    {t('calendar.calendarView')}
                </div>
            </div>
            <Calendar
                listData={dataList}
                onReload={(param) => handleGetData(param)}
                modal={{ form: BookingForm, title: 'Tour management' }}
                guideList={guideList}
                isGuideList={searchParams.get('tab') === 'guide-view'}
                allGuideList={allGuideList}
                loading={loading}
                visitList={visitList}
                cityList={cityList}
                onActionConfirm={handleActionConfirm}
            />
            {isShowFormModal && (
                <Modal
                    title={'Tour management'}
                    classes={{ content: '', children: '' }}
                    onCancel={() => setIsShowFormModal(false)}
                >
                    <BookingForm
                        item={{}}
                        onCancel={() => setIsShowFormModal(false)}
                        onSuccess={() => {
                            setIsShowFormModal(false)
                            handleGetData(bookingParam)
                        }}
                        typeActionFormModal={'creact'}
                    />
                </Modal>
            )}
        </div>
    )
}
