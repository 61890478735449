import { default as form } from './form.json'
import { default as error } from './error.json'
import { default as common } from './common.json'
import { default as button } from './button.json'
import { privacyPolicy } from './privacyPolicy'
import { pages } from './pages'

export const i18nEn = {
    ...form,
    ...error,
    ...common,
    ...button,
    ...pages,
    privacyPolicy,
}
