import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useTranslation } from 'react-i18next'

const PrivacyPolicyPage = () => {
    const { t } = useTranslation()
    return (
        <div className="bg-white">
            <div className="[&_h6]:mt-5 [&_a]:!text-[#4382FF] py-6 mx-6 lg:mx-auto max-w-[940px]">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{t('privacyPolicy')}</ReactMarkdown>
            </div>
        </div>
    )
}

export default PrivacyPolicyPage
