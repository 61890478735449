import { Outlet } from 'react-router-dom'

export default function LoginLayout() {
    return (
        <div className="w-full h-screen flex flex-col items-centen justify-center">
            <div className="mx-auto w-[33rem] shadow-t_s0362 py-12 px-14 rounded-2xl shadow-t_sd0480 bg-white relative max-h-[90%]">
                <Outlet />
            </div>
        </div>
    )
}
