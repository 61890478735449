export const guideList = [
    {
        text: '',
        type: 'checkBox',
        checkBoxName: 'checkAll',
        colClass: 'w-10 flex justify-center',
    },
    {
        text: 'guide.name',
        colClass: 'min-w-[200px] 2xl:min-w-[250px]',
    },
    {
        text: 'guide.status',
        colClass: 'w-[85px]',
        isSort: true,
        sortName: 'isActived',
    },
    {
        text: 'guide.emailAddress',
        colClass: 'min-w-[200px] 2xl:min-w-[250px]',
    },
    {
        text: 'guide.favoriteTour',
        colClass: 'min-w-[220px] w-[25%]',
    },
    {
        text: 'guide.language',
        colClass: 'min-w-[220px] w-[25%]',
    },
    {
        text: '',
        colClass: '',
    },
]
