import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BackButton } from './components/BackButton'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { bokunImg, fareHarborImg, xolaImg } from '@Assets/images'
import { BokunConnection } from './components/BokunConnection'

export default function BookingProviderPage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const signupData = useSelector((state) => state.persist.signupData)
    const [providerOptions] = useState([
        { type: 'bokun', icon: bokunImg },
        { type: 'xola', icon: xolaImg },
        { type: 'fareHarbor', icon: fareHarborImg },
    ])
    const [providerSelected, setProviderSelected] = useState('')

    useEffect(() => {
        if (!signupData.firstname) navigate('/signup/information')
    }, [signupData, navigate])

    const handleBack = () => {
        navigate('/signup/information')
    }

    return (
        <>
            <BackButton onClick={handleBack} />
            <div className="mt-12 mb-7">
                <div className="text-2xl mt-5 font-semibold">{t('bookingProvider.title')}</div>
                <div className="flex justify-center gap-7 mt-7">
                    {providerOptions.map((item, index) => (
                        <button
                            key={index}
                            className="w-24 h-24 shadow-t_sd04500 rounded-lg flex items-center justify-center"
                            onClick={() => setProviderSelected(item.type)}
                        >
                            <img src={item.icon} alt="booking provider" />
                        </button>
                    ))}
                </div>
            </div>
            {providerSelected === 'bokun' && (
                <BokunConnection onCancel={() => setProviderSelected('')} />
            )}
        </>
    )
}
