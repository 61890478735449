import classNames from 'classnames'

const Loading = ({ classes = {}, isAbsolute = false }) => (
    <div
        className={classNames(
            'flex justify-center items-center',
            {
                'w-full h-full bg-t_gray_400 bg-opacity-50 absolute top-0 left-0 rounded-lg z-20':
                    isAbsolute,
            },
            classes.container
        )}
    >
        <div
            className={classNames(
                'animate-spin rounded-full h-5 w-5 border-2 !border-b-transparent border-gray-500',
                classes.loading
            )}
        ></div>
    </div>
)

export default Loading
