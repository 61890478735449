export const statusOptions = () => {
    return [
        {
            label: 'Unstaffed',
            value: 'UNSTAFFED',
        },
        {
            label: 'Pending',
            value: 'PENDING',
        },
        {
            label: 'Staffed',
            value: 'STAFFED',
        },
        {
            label: 'Canceled',
            value: 'CANCELLED',
        },
    ]
}

export const languageOptions = [
    {
        label: 'English',
        value: 'en',
    },
    {
        label: 'French',
        value: 'fr',
    },
    {
        label: 'Spanish',
        value: 'es',
    },
    {
        label: 'Italian',
        value: 'it',
    },
    {
        label: 'Chinese',
        value: 'zh',
    },
    {
        label: 'Russian',
        value: 'ru',
    },
]
