import axios from 'axios'
import { toastError } from '@Libs/toast'

const myAxios = axios.create({ baseURL: process.env.REACT_APP_API_URL })
myAxios.interceptors.request.use(
    (config) => {
        config.headers['Accept'] = 'application/json, multipart/form-data'
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
myAxios.interceptors.response.use(
    (response) => {
        if (response.status === 200 || response.status === 201) {
            const data = response?.data?.data ?? response?.data
            return Promise.resolve(data)
        } else {
            return Promise.reject(response)
        }
    },
    (error) => {
        if (error.response?.status) {
            toastError(error.response.data.message)
            return Promise.reject(error.response)
        }
    }
)

const myAuthAxios = axios.create({ baseURL: process.env.REACT_APP_API_URL })
myAuthAxios.interceptors.request.use(
    (config) => {
        if (localStorage.getItem('jwt') && localStorage.getItem('jwt') !== undefined) {
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
            config.headers['Accept'] = 'application/json, multipart/form-data'
        } else {
            return Promise.reject('missing token')
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

myAuthAxios.interceptors.response.use(
    (response) => {
        if (response.status === 200 || response.status === 201) {
            const data = response?.data?.data ?? response?.data
            return Promise.resolve(data)
        } else {
            return Promise.reject(response)
        }
    },
    (error) => {
        if (error.response?.status) {
            toastError(error.response.data.message)
            return Promise.reject(error.response)
        }
    }
)

export { myAxios, myAuthAxios }
