/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { api } from '@Api'
import { Calendar, BookingForm } from '@Views'
import { useSelector } from 'react-redux'
import { toastError, toastSuccess } from '@Libs/toast'
import { useTranslation } from 'react-i18next'

export default function CalendarPage() {
    const { t } = useTranslation()
    const [dataList, setDataList] = useState([])
    const [guideList, setGuideList] = useState([])
    const [loading, setLoading] = useState(false)
    const user = useSelector((state) => state.persist.user)

    const handleGetData = async (param) => {
        try {
            setLoading(true)
            let guides = []
            const res = await api.getBooking({
                ...param,
                guide: user._id,
                agencies: (user.agencies || []).map((el) => el._id),
            })
            const bookings = res?.bookings?.docs || []
            bookings.forEach((element) => {
                if (
                    element.guideInfo &&
                    !(guides || []).map((guide) => guide._id).includes(element.guideInfo._id)
                ) {
                    guides = [...guides, element.guideInfo]
                }
            })
            setGuideList([{ isGetAll: true }])
            setDataList(bookings)
        } finally {
            setLoading(false)
        }
    }

    const handleActionConfirm = async ({ currentItem, actionType }) => {
        if (actionType === 'decline') {
            await api.updateBooking(currentItem._id, { status: 'UNSTAFFED', guide: '' })
        }
        if (actionType === 'accept') {
            await api.updateBooking(currentItem._id, { status: 'STAFFED' })
            toastSuccess(
                <div>
                    <div className="font-bold text-base">
                        {t('calendarNotification.acceptedTheTour')}
                    </div>
                    <div className="text-sm">{t('calendarNotification.hasBeenNotified')}</div>
                </div>
            )
        }
        if (actionType === 'cancel') {
            await api.updateBooking(currentItem._id, { status: 'CANCELLED' })
            toastError(
                <div>
                    <div className="font-bold text-base">
                        {t('calendarNotification.cancelledATour')}
                    </div>
                    <div className="text-sm">{t('calendarNotification.hasBeenNotified')}</div>
                </div>
            )
        }
        handleGetData()
    }

    return (
        <div className="w-full bg-white h-[calc(100vh-100px)] sm:h-fit sm:bg-inherit px-5 overflow-y-auto">
            <Calendar
                listData={dataList}
                onReload={(param) => handleGetData(param)}
                modal={{ form: BookingForm, title: 'Tour management' }}
                guideList={guideList}
                isGuideList={false}
                loading={loading}
                onActionConfirm={handleActionConfirm}
            />
        </div>
    )
}
