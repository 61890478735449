export const validEmail = (email) => {
    const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return reg.test(email)
}

export const validPassword = (pass) => {
    let re = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}":;'<>?,./]).{8,}$/
    return re.test(pass)
}

export const formatNumber = (val) => {
    return val.replace(/[\D]/g, '')
}
export const formatDecimal = (val) => {
    const regex = /^\d*\.?\d*$/
    if (!regex.test(val)) {
        return val.substring(0, val.length - 1)
    }
    return val
}

export const getS3Link = (link) => {
    return `${process.env.REACT_APP_S3_URL}${link}`
}

export const removeNullValues = (obj) => {
    return Object.entries(obj).reduce((newObj, [key, value]) => {
        if (value !== null) {
            newObj[key] = value
        }
        return newObj
    }, {})
}

export const showUnixToDate = (dateUnix /*1660701684622"*/, fullYear = false, getHour = false) => {
    if (dateUnix) {
        let date = new Date(dateUnix)
        let yyyy = date.getFullYear()
        let mm = (date.getMonth() + 1).toString().padStart(2, '0')
        let dd = date.getDate().toString().padStart(2, '0')
        let hh = `${date.getHours().toString().padStart(2, '0')}:${date
            .getMinutes()
            .toString()
            .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
        let yy = yyyy.toString().substring(2)
        return `${dd}/${mm}/${fullYear ? yyyy : yy}${getHour ? ' - ' + hh : ''}`
    }
    return ''
}

export const removeItemsByKeys = (obj, keys) => {
    let newObj = { ...obj }
    keys.forEach((key) => {
        delete newObj[key]
    })
    return newObj
}

export const cloneObjWithKeys = (original, keys) => {
    return keys.reduce((newObj, key) => {
        if (original.hasOwnProperty(key)) {
            newObj[key] = original[key]
        }
        return newObj
    }, {})
}

export const getAppropriateGuide = async (booking, availability) => {
    if (availability.length > 0) {
        let appropriateGuide = availability.filter(
            (guide) =>
                (guide.language || []).includes((booking.language || 'en').toLowerCase()) &&
                (guide.favoriteTours || []).includes(booking.visitType)
        )
        appropriateGuide.sort((a, b) => Number(b.priorityPoint) - Number(a.priorityPoint))
        return appropriateGuide || []
    }
    return []
}

export const parseSelectOptionData = ({ data = [], label, value }) => {
    return data.map((e) => {
        return { label: e[label], value: e[value] }
    })
}
