import { memo, useState } from 'react'
import { Trash2, Edit2 } from 'react-feather'
import { languageOptions } from '@Constants'

import { userImg } from '@Assets/images'
import { getS3Link } from '@Libs/hepler'
import classNames from 'classnames'

const GuideItem = ({ item, onActionClick, onItemEdit, favorites }) => {
    const [isShowHelp, setShowHelp] = useState(null)
    const colorCss = [
        'border-[#E9D7FE] text-[#6941C6] bg-[#F9F5FF]',
        'border-[#B2DDFF] text-[#175CD3] bg-[#EFF8FF]',
        'border-[#C7D7FE] text-[#3538CD] bg-[#EEF4FF]',
        'border-[#FDDCAB] text-[#C4320A] bg-[#FFF6ED]',
        'border-[#FCCEEE] text-[#C11574] bg-[#FDF2FA]',
    ]

    const getLanguage = (lang) => {
        const language = languageOptions.find((la) => lang.toLowerCase().includes(la.value)) || {}
        return language.label
    }

    const color = (idx = 0) => {
        const num = idx % 5
        return colorCss[num]
    }

    const showOptionItem = (name, op, classText = '') => {
        return (
            <>
                {name === 'language' && (
                    <div
                        className={classNames(
                            classText || 'flex w-14 py-1  border rounded-full text-xs px-1',
                            color(languageOptions.map((la) => la.value).indexOf(op))
                        )}
                    >
                        <span className={classNames(!classText && 'truncate')}>
                            {getLanguage(op)}
                        </span>
                    </div>
                )}
                {name !== 'language' && (
                    <div
                        className={classNames(
                            classText || 'flex w-14 py-1  border rounded-full text-xs px-1',
                            color(favorites.indexOf(op))
                        )}
                        data-tooltip-id={!classText && 'my-tooltip'}
                        data-tooltip-content={op || ''}
                    >
                        <span className={classNames(!classText ? 'truncate' : 'w-56')}>{op}</span>
                    </div>
                )}
            </>
        )
    }

    const showOptions = (options = [], name) => {
        return (
            <div className="flex items-center space-x-2">
                {options.length > 0 && (
                    <div className="flex items-center space-x-1">
                        {options.map(
                            (op, idx) =>
                                idx < 3 && (
                                    <div key={`${name}-${idx}-${item._id}`}>
                                        {showOptionItem(name, op)}
                                    </div>
                                )
                        )}
                        {options.length > 3 && (
                            <span className="flex items-center border rounded-lg relative">
                                <div
                                    onMouseEnter={() => setShowHelp(name)}
                                    onMouseLeave={() => setShowHelp(null)}
                                    className="cursor-pointer relative rounded-r-lg flex items-center justify-center text-xs px-1 py-0.5"
                                >
                                    <p>+{options.length - 3}</p>
                                    {isShowHelp === name && (
                                        <div className="z-50 rounded-lg absolute top-5 -right-2 border">
                                            {options.map(
                                                (op, idx) =>
                                                    idx >= 3 && (
                                                        <div key={`${op}-${idx}-${name}`}>
                                                            {showOptionItem(
                                                                name,
                                                                op,
                                                                'flex py-1 border text-xs px-1'
                                                            )}
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    )}
                                </div>
                            </span>
                        )}
                    </div>
                )}
            </div>
        )
    }
    return (
        <>
            <div className="col min-w-[200px] 2xl:min-w-[250px] flex items-center space-x-2">
                <img
                    src={item.avatar ? getS3Link(item.avatar) : userImg}
                    className="w-8 h-8 rounded-full"
                    alt={'avatar'}
                />
                <p>
                    {item.firstname} {item.lastname}
                </p>
            </div>
            <div className="col min-w-[85px]">
                <div
                    className="border rounded-lg flex justify-center items-center h-6 text-t_gray_800 text-xs"
                    onClick={(e) => {
                        e.stopPropagation()
                        onActionClick({ type: 'changeStatus', data: item })
                    }}
                >
                    <div
                        className={classNames(
                            'w-2 h-2 bg-t_green_200 rounded-full mr-1',
                            !item.isActived && '!bg-t_red_500'
                        )}
                    ></div>
                    {item.isActived ? 'Active' : 'Inactive'}
                </div>
            </div>
            <div className="col min-w-[200px] 2xl:min-w-[250px] text-t_gray_550 font-normal">
                {item.email}
            </div>
            <div className="col min-w-[220px] w-[25%]">
                {showOptions(item.favoriteTours, 'favoriteTour')}
            </div>
            <div className="col min-w-[220px] w-[25%]">
                {showOptions(item.language, 'language')}
            </div>
            <div className="col w-28" onClick={(e) => e.stopPropagation()}>
                <div className="flex items-center space-x-6 justify-end">
                    <Trash2
                        name={'delete'}
                        className="w-4 h-4 text-t_gray_550 cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation()
                            onActionClick({ type: 'delete', data: item })
                        }}
                    />
                    <Edit2
                        name={'edit2'}
                        className="w-4 h-4 text-t_gray_550 cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation()
                            onItemEdit()
                        }}
                    />
                </div>
            </div>
        </>
    )
}
export default memo(GuideItem)
