import {
    FormButton,
    TextArea,
    FormInput,
    Icon,
    SelectOption,
    FormDateTimePicker,
    FileUpload,
    Button,
} from '@Views'
import { useState, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { formatInTimeZone } from 'date-fns-tz'
import { api } from '@Api'
import { getS3Link } from '@Libs/hepler'
import { useSelector } from 'react-redux'
import MeetingPoint from './MeetingPoint'
import { getAppropriateGuide } from '@Libs/hepler'
import { X, Plus, Minus } from 'react-feather'
import classNames from 'classnames'
import { languageOptions, statusOptions } from '@Constants'
import { toastError } from '@Libs/toast'
import DOMPurify from 'dompurify'

const BookingForm = ({
    onCancel,
    onSuccess,
    onActionClick,
    item,
    typeActionFormModal = 'edit',
    isSaveNow = false,
}) => {
    const { t } = useTranslation()
    const user = useSelector((state) => state.persist.user)
    const [formData, setFormData] = useState({})
    const [loading, setLoading] = useState(false)
    const [guideOptions, setGuideOptions] = useState([])
    const [allGuideOptions, setAllGuideOptions] = useState([])
    const [bookingList, setBookingList] = useState([])
    const [isValidate, setIsValidate] = useState(true)
    const [groupedGuides, setGroupedGuides] = useState([])
    const [dataFileDescribe, setDataFileDescribe] = useState([])
    const [rateOptions, setRateOptions] = useState([])

    useEffect(() => {
        setFormData({
            guest: item.guest || {},
            guide: item.guide || '',
            note: item.note || '',
            option: item.option || '',
            visitType: item.visitType || '',
            tickets: item.tickets || [],
            duration: item.duration || 0,
            date: item.date ? new Date(formatInTimeZone(item.date, 'UTC', 'yyyy-MM-dd HH:mm')) : '',
            language: item.language || 'en',
            addOn: item.addOn || '',
            meetingPoint: item.meetingPoint || {},
        })
        setDataFileDescribe(item.tickets || [])
    }, [item])

    useEffect(() => {
        handleGetData()
        handleGetActivities()
    }, [])

    useEffect(() => {
        if (formData.visitType && formData.date && allGuideOptions.length > 0) {
            handleGetGuideOptions()
        }
    }, [formData.visitType, formData.date, formData.language, allGuideOptions])

    useEffect(() => {
        if (formData.visitType && bookingList.length > 0) {
            // onChangeData('', 'option')
            handleGetRates()
        }
    }, [formData.visitType])

    const handleGetRates = async () => {
        const booking = bookingList.find((el) => el.title === formData.visitType) || {}
        const result = await api.getActivitieInfo({
            agencyId: user.agency?._id,
            activityId: booking.id,
        })
        const rates = result?.activityInfo?.rates || []
        setRateOptions(
            rates.map((rate) => {
                return { label: rate.title, value: rate.title }
            })
        )
        const city = result?.activityInfo?.googlePlace?.city || ''
        onChangeData(city, 'city')
    }

    const handleGetData = async () => {
        try {
            const result = await api.getUser({ agency: user.agency?._id, role: 'guide' })
            const users = result.users?.docs || []
            const guide = users.map((user) => {
                return {
                    ...user,
                    label: `${user.firstname} ${user.lastname}`,
                    value: user._id,
                }
            })
            setAllGuideOptions(guide)
        } finally {
            setLoading(false)
        }
    }

    const handleGetActivities = async () => {
        try {
            const result = await api.getActivitiesBokun({ agencyId: user.agency?._id })
            const activity = result.activity?.items || []
            setBookingList(
                activity.map((bk) => {
                    return { ...bk, label: bk.title, value: bk.title }
                })
            )
        } finally {
        }
    }

    const handleGetGuideOptions = async () => {
        const localTime = new Date(formData.date)
        const hours = localTime.getHours()
        const minutes = localTime.getMinutes()
        const utcTime =
            typeActionFormModal === 'edit'
                ? localTime
                : new Date(
                      Date.UTC(
                          localTime.getFullYear(),
                          localTime.getMonth(),
                          localTime.getDate(),
                          hours,
                          minutes,
                          0
                      )
                  )
        const result = await api.getTimeOverlap({
            date: utcTime,
            duration: formData.duration,
            agency: user.agency?._id,
        })
        const resCalendar = await api.getGuildCalendarOfAgency({
            timeMin: utcTime,
            timeMax: new Date(
                new Date(utcTime).setMinutes(new Date(utcTime).getMinutes() + formData.duration)
            ),
            agencyId: user.agency?._id,
        })
        let googleGuide = []
        ;(resCalendar?.calendarUsers || []).forEach((el) => {
            googleGuide = [...googleGuide, ...el]
        })
        googleGuide = googleGuide.map((el) => {
            const millisBetween = new Date(el.end.dateTime) - new Date(el.start.dateTime)
            return {
                date: el.start.dateTime,
                duration: millisBetween / (1000 * 60),
                guide: el.guide,
            }
        })
        const timeOverlap = [...(result?.bookings?.docs || []), ...googleGuide]

        // get availability
        let unavailability = []
        timeOverlap.forEach((bk) => {
            unavailability.push(bk.guide)
        })
        let guideList = []
        guideList = allGuideOptions.map((guide) => {
            const priority = Number(guide.priority || 5)
            const isAvailability = !unavailability.includes(guide._id)
            let priorityPoint = 16 * (6 - priority)
            if (isAvailability) {
                priorityPoint += 20
            }
            return {
                ...guide,
                priorityPoint: priorityPoint,
                backgroundColor: !isAvailability ? '#FFAD32' : '',
            }
        })

        // get appropriate guide
        let appropriateGuide = await getAppropriateGuide(formData, guideList)
        setGroupedGuides(appropriateGuide)
        setGuideOptions(appropriateGuide)
    }

    const onChangeData = (value, key) => {
        let newData = { [key]: value }
        if (key === 'visitType') {
            const booking = bookingList.find((el) => el.value === value) || {}
            const fields = booking.fields || {}
            const duration = (fields.durationHours || 0) * 60 + (fields.durationMinutes || 0)
            newData = { ...newData, duration }
        }
        if (key) {
            setFormData({ ...formData, ...newData })
        }
    }

    const onChangeGuest = (value, key) => {
        if (key) {
            setFormData({ ...formData, guest: { ...formData.guest, [key]: value } })
        }
    }

    const findDifferences = (obj1, obj2) => {
        const fieldCheck = ['language', 'date', 'meetingPoint']
        let diff = []
        const fieldChanged = item?.fieldChanged ?? []
        for (const key in obj1) {
            if (
                obj1.hasOwnProperty(key) &&
                !fieldChanged.includes(key) &&
                fieldCheck.includes(key)
            ) {
                if (obj1[key] !== obj2[key] && key === 'language') {
                    diff = [...diff, key]
                }
                if (
                    key === 'date' &&
                    new Date(obj1[key]).toISOString() !== new Date(obj2[key]).toISOString()
                ) {
                    diff = [...diff, key]
                }
                if (
                    key === 'meetingPoint' &&
                    JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])
                ) {
                    diff = [...diff, key]
                }
            }
        }
        return diff
    }

    const handelSave = async () => {
        try {
            setLoading(true)
            let bookingData = { ...formData, status: item.status }
            const localTime = new Date(bookingData.date)
            const hours = localTime.getHours()
            const minutes = localTime.getMinutes()
            const utcTime = new Date(
                Date.UTC(
                    localTime.getFullYear(),
                    localTime.getMonth(),
                    localTime.getDate(),
                    hours,
                    minutes,
                    0
                )
            )
            if (
                bookingData.guide &&
                (['PENDING', 'UNSTAFFED'].includes(item.status) ||
                    !item.status ||
                    (bookingData.guide && bookingData.guide !== item.guide))
            ) {
                bookingData = { ...bookingData, status: 'PENDING' }
            }
            if (!bookingData.guide && !['CANCELLED'].includes(item.status)) {
                bookingData = { ...bookingData, status: 'UNSTAFFED' }
            }
            if (typeActionFormModal === 'edit') {
                const tickets = await handleSaveTickets()
                const dataUpdate = {
                    ...bookingData,
                    tickets: tickets,
                    date: utcTime,
                }
                const diff = findDifferences(dataUpdate, item)
                if (isSaveNow) {
                    await api.updateBooking(item._id, {
                        ...dataUpdate,
                        fieldChanged: [...(item.fieldChanged || []), ...diff],
                    })
                    onSuccess()
                } else
                    onActionClick({
                        type: 'edit',
                        data: {
                            ...dataUpdate,
                            _id: item._id,
                            fieldChanged: [...(item.fieldChanged || []), ...diff],
                        },
                    })
            }
            if (typeActionFormModal === 'creact') {
                let validated = false
                if (
                    !bookingData.option ||
                    !bookingData.date ||
                    !bookingData.visitType ||
                    !bookingData.guest.name ||
                    !bookingData.guest.email ||
                    !bookingData.guest.phone
                ) {
                    validated = false
                } else validated = true

                setIsValidate(validated)
                if (validated) {
                    const tickets = await handleSaveTickets()
                    await api.createBooking({
                        ...bookingData,
                        agency: user.agency?._id,
                        bookingOrigine: 'local',
                        tickets: tickets,
                        date: utcTime,
                    })
                    onSuccess()
                }
            }
        } finally {
            setLoading(false)
        }
    }

    const handleSaveTickets = async () => {
        try {
            const filesList = [...dataFileDescribe].filter((ticket) => typeof ticket !== 'string')
            let newTickets = []
            if (filesList.length > 0) {
                let formData = new FormData()
                filesList.forEach((file) => {
                    formData.append('files', file)
                })
                formData.append('fileName', `booking_ticket`)
                const resFiles = await api.uploadMultiple(formData)
                newTickets = resFiles.uploadFiles.map((f) => f.url)
            }
            return [...dataFileDescribe, ...newTickets].filter(
                (ticket) => typeof ticket === 'string'
            )
        } catch (error) {
            toastError(error)
            return []
        }
    }

    const getLanguage = (lang) => {
        const language = languageOptions.find((la) => lang.toLowerCase().includes(la.value)) || {}
        return language?.label || lang
    }

    const editNumber = (key) => {
        return (
            <div className="grid grid-cols-3 border border-t_gray_450 w-28 h-8 rounded-lg">
                <div
                    className="flex justify-center items-center font-semibold"
                    onClick={() => {
                        const num = (formData.guest || {})[key] || 0
                        if (num > 0) {
                            onChangeGuest(((formData.guest || {})[key] || 0) - 1, key)
                        }
                    }}
                >
                    <Minus className="w-4 h-4" />
                </div>
                <div className="flex justify-center items-center border-x border-t_gray_450">
                    {(formData.guest || {})[key] || 0}
                </div>
                <div
                    className="flex justify-center items-center font-semibold"
                    onClick={() => onChangeGuest(((formData.guest || {})[key] || 0) + 1, key)}
                >
                    <Plus className="w-4 h-4" />
                </div>
            </div>
        )
    }

    const bookingInfo = () => {
        return (
            <>
                <div>
                    <p className=" text-xs font-bold">
                        {t('calendar.duration')} :{' '}
                        {(formData.duration - (formData.duration % 60)) / 60}h
                        {formData.duration % 60 ? `${formData.duration % 60} m` : ''}
                    </p>
                    {user.role === 'guide' && (
                        <p className="text-sm font-bold">
                            {formData.date &&
                                formatInTimeZone(formData.date, 'UTC', 'd MMMM yyyy - HH:mm')}
                        </p>
                    )}
                    {user.role !== 'guide' && (
                        <FormDateTimePicker
                            startDate={formData.date}
                            onChange={(date) => onChangeData(date, 'date')}
                            format="DD-MM-YYYY HH:mm"
                            classes={{ input: '!h-10 !w-full' }}
                            error={!isValidate && !formData.date ? t('validation.required') : ''}
                        />
                    )}
                </div>
                <div className="sm:flex sm:space-x-4">
                    <div className="space-y-0 sm:w-52">
                        <p className="text-xs">{t('calendar.visite')}</p>
                        <p className="font-bold">{formData.visitType}</p>
                    </div>
                    <div className="space-y-0 w-32">
                        <p className="text-xs">{t('calendar.options')}</p>
                        <p className="font-bold">{formData.option}</p>
                    </div>
                </div>
                <div className="flex space-x-6 sm:space-x-0">
                    <div className="space-y-0 sm:w-24">
                        <p className="text-xs">{t('calendar.pax')}</p>
                        <p className="font-bold">
                            {formData.guest?.adultNb || 0} {t('calendar.adults')}
                        </p>
                        <p className="font-bold">
                            {formData.guest?.childrenNb || 0} {t('calendar.children')}
                        </p>
                    </div>
                    <div className="space-y-0 sm:w-36 px-2">
                        <p className="text-xs">{t('calendar.language')}</p>
                        {user.role === 'guide' && (
                            <p className="font-bold">{getLanguage(formData.language || 'en')}</p>
                        )}
                        {user.role !== 'guide' && (
                            <SelectOption
                                classes={{ container: '' }}
                                options={languageOptions}
                                value={formData.language}
                                onChange={(value) => onChangeData(value, 'language')}
                            />
                        )}
                    </div>
                    <div className="space-y-0 sm:w-32">
                        <p className="text-xs">{t('calendar.addOn')}</p>
                        <p className="font-bold">{t('calendar.none')}</p>
                    </div>
                </div>
                <div>
                    <p className="text-xs">{t('calendar.guest')}</p>
                    <p className="text-2xl font-bold">{formData.guest?.name}</p>
                    <p className="text-[10px]">{formData.guest?.email}</p>
                    <p className="text-[10px]">{formData.guest?.phone}</p>
                </div>
                <div>
                    <p className=" text-xs font-bold">{t('calendar.customerNote')}</p>
                    <p
                        className="whitespace-pre-line"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(formData.guest?.note),
                        }}
                    />
                </div>
            </>
        )
    }

    const guestInput = (key) => {
        return (
            <FormInput
                name={key}
                value={formData?.guest?.[key] || ''}
                type="text"
                className="w-full outline-none border-b border-gray-300 px-2 sticky top-0 pt-2"
                placeholder={t(`placeholder.${key}`)}
                handleChange={(name, val) => {
                    onChangeGuest(val, name)
                }}
                error={{
                    message: !isValidate && !formData?.guest?.[key] ? t('validation.required') : '',
                    field: '',
                }}
            />
        )
    }

    const statusBooking = (status) => {
        let itemCss = 'bg-[#FF6C6C1A] text-t_pink_200'
        if (status === 'CANCELLED') {
            itemCss = 'bg-t_gray_250 border-t_black_600 text-t_black_600'
        } else if (status === 'PENDING') {
            itemCss = 'bg-t_yellow_100 border-t_yellow_300 text-t_yellow_300'
        } else if (status === 'STAFFED') {
            itemCss = 'bg-t_green_100 border-t_green_300 text-t_green_300'
        }
        return (
            <div
                className={`${itemCss} px-4 rounded-lg flex justify-center items-center font-medium`}
            >
                {statusOptions().find((el) => el.value === (status || 'UNSTAFFED')).label}
            </div>
        )
    }

    const bookingInfoCreact = () => {
        return (
            <>
                <div className=" space-y-2">
                    <div>
                        <p className="text-xs">{t('calendar.date')}</p>
                        <FormDateTimePicker
                            startDate={formData.date}
                            onChange={(date) => onChangeData(date, 'date')}
                            format="DD-MM-YYYY HH:mm"
                            classes={{ input: '!h-10 !w-full' }}
                            error={!isValidate && !formData.date ? t('validation.required') : ''}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <p className="text-xs">{t('calendar.visitType')}</p>
                            <SelectOption
                                classes={{ container: '' }}
                                options={bookingList}
                                value={formData.visitType}
                                onChange={(value) => onChangeData(value, 'visitType')}
                                error={{
                                    message:
                                        !isValidate && !formData.visitType
                                            ? t('validation.required')
                                            : '',
                                    field: '',
                                }}
                            />
                        </div>
                        <div>
                            <p className="text-xs">{t('calendar.options')}</p>
                            <SelectOption
                                classes={{ container: '' }}
                                options={rateOptions}
                                value={formData.option}
                                onChange={(value) => onChangeData(value, 'option')}
                                error={{
                                    message:
                                        !isValidate && !formData.option
                                            ? t('validation.required')
                                            : '',
                                    field: '',
                                    field: '',
                                }}
                            />
                        </div>
                        <div>
                            <p className="text-xs">{t('calendar.language')}</p>
                            <SelectOption
                                classes={{ container: '' }}
                                options={languageOptions}
                                value={formData.language}
                                onChange={(value) => onChangeData(value, 'language')}
                            />
                        </div>
                        <div>
                            <p className="text-xs">{t('calendar.addOn')}</p>
                            <SelectOption
                                classes={{ container: '' }}
                                options={[{ label: 'None', value: '' }]}
                                placeholder={''}
                                value={formData.addOn}
                                onChange={(value) => onChangeData(value, 'addOn')}
                            />
                        </div>
                    </div>
                    <div>
                        <p className="text-xs">{t('calendar.pax')}</p>
                        <div className=" space-y-2">
                            <div className="flex items-center space-x-2">
                                {editNumber('adultNb')}{' '}
                                <p className="font-bold text-sm">{t('calendar.adult')}</p>
                            </div>
                            <div className="flex items-center space-x-2">
                                {editNumber('childrenNb')}
                                <p className="font-bold text-sm">{t('calendar.children')}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="text-xs">{t('calendar.guestName')}</p>
                        {guestInput('name')}
                    </div>
                    <div>
                        <p className="text-xs">{t('calendar.guestEmail')}</p>
                        {guestInput('email')}
                    </div>
                    <div>
                        <p className="text-xs">{t('calendar.guestPhone')}</p>
                        {guestInput('phone')}
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="space-y-7">
                <div className="grid sm:grid-cols-2 gap-2 sm:w-[920px] max-w-full">
                    <div className="border-dashed border-2 p-4 space-y-4">
                        <p className="text-2xl font-bold">{t('calendar.bookingDetails')}</p>
                        {typeActionFormModal === 'edit' && bookingInfo()}
                        {typeActionFormModal === 'creact' && bookingInfoCreact()}
                    </div>
                    <div className="border-dashed border-2 p-4 space-y-4">
                        <div className="flex space-x-2">
                            <p className="text-2xl font-bold">{t('calendar.tourPreparation')}</p>
                            {statusBooking(item.status)}
                        </div>
                        {user.role !== 'guide' && (
                            <div>
                                <p className="text-xs">{t('calendar.guide')}</p>
                                <SelectOption
                                    classes={{ control: '!border-t_gray_150' }}
                                    options={groupedGuides}
                                    placeholder={'placeholder.assignAGuide'}
                                    value={formData.guide}
                                    onChange={(value) => onChangeData(value, 'guide')}
                                    isClearable={true}
                                    isDisabled={item.status === 'CANCELLED'}
                                />
                                <div className="space-y-1 pt-2">
                                    {item.status !== 'CANCELLED' &&
                                        guideOptions.map(
                                            (user, idx) =>
                                                idx < 3 && (
                                                    <div
                                                        key={`user-${idx}`}
                                                        className="flex items-center space-x-2"
                                                    >
                                                        <img
                                                            src={
                                                                user.avatar
                                                                    ? getS3Link(user.avatar)
                                                                    : 'https://lh3.googleusercontent.com/proxy/ttFZIprg1NiaBXg-Aft4aWibNaZA7ERkqtwc5Pi63Wy3qF850fcXTxf9FBCss3N0zLZA0GfSfzUKv2WPPvLF65PJMLfYyEcCweFVCUU'
                                                            }
                                                            className="w-5 h-5 rounded-full"
                                                            alt={'avatar'}
                                                        />
                                                        <p className="text-xs font-semibold">{`${user.firstname} ${user.lastname}`}</p>
                                                        <Icon
                                                            name={'group'}
                                                            classes={'w-4 h-4 text-t_green_300 '}
                                                            onClick={(e) => {
                                                                onChangeData(user._id, 'guide')
                                                            }}
                                                        />
                                                    </div>
                                                )
                                        )}
                                </div>
                            </div>
                        )}
                        <div>
                            <p className="text-xs">{t('calendar.tickets')}</p>
                            <div className="w-full py-2 border border-t_gray_150 rounded-lg flex flex-wrap items-center px-4 space-x-2 min-h-20">
                                {[...dataFileDescribe].map((ticket, idx) => (
                                    <div key={`ticket${idx}`}>
                                        <div className="text-t_gray_600 relative">
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={
                                                    typeof ticket === 'string'
                                                        ? process.env.REACT_APP_S3_URL + ticket
                                                        : URL.createObjectURL(ticket)
                                                }
                                                className="text-sx"
                                            >
                                                <Icon
                                                    name={'file'}
                                                    classes={classNames(
                                                        'w-12 h-12 text-t_gray_600 '
                                                    )}
                                                    onClick={() => {
                                                        window.open(getS3Link(ticket))
                                                    }}
                                                />
                                                {t('calendar.ticket')}
                                            </a>
                                            <div className="w-5 absolute -top-1 -right-0.5">
                                                <X
                                                    className={classNames(
                                                        'ml-2 mt-1 w-4 !h-4 bg-t_red_500  text-white rounded-full'
                                                    )}
                                                    onClick={() => {
                                                        let tickets = [...dataFileDescribe]
                                                        tickets = tickets.toSpliced(idx, 1)
                                                        setDataFileDescribe(tickets)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {user.role !== 'guide' && (
                                    <FileUpload
                                        content={
                                            !dataFileDescribe.length && t('calendar.addDocuments')
                                        }
                                        classes={{
                                            span: 'text-center',
                                            content: 'font-normal',
                                            button: 'w-fit !border-0',
                                            icon: '!w-12 !h-12 !text-t_gray_600',
                                        }}
                                        isMultiple={true}
                                        accept={'.pdf, .doc'}
                                        onUploadData={(e) => {
                                            setDataFileDescribe([
                                                ...dataFileDescribe,
                                                ...e.target.files,
                                            ])
                                            e.target.value = ''
                                        }}
                                        isShowIcon={dataFileDescribe.length > 0}
                                    />
                                )}
                            </div>
                        </div>
                        <div>
                            <p className="text-xs">{t('calendar.internalNotes')}</p>
                            <TextArea
                                classes={{ input: '!border-t_gray_150' }}
                                name="note"
                                value={formData.note}
                                rows={'5'}
                                handleChange={(name, val) => {
                                    onChangeData(val, name)
                                }}
                                isDisabled={user.role === 'guide'}
                            />
                        </div>
                        <MeetingPoint
                            address={formData.meetingPoint}
                            onChange={(value) => onChangeData(value, 'meetingPoint')}
                            isDisabled={user.role === 'guide'}
                        />
                    </div>
                </div>
                <div className="mb-3">
                    {user.role !== 'guide' && (
                        <FormButton
                            onSave={handelSave}
                            onCancel={onCancel}
                            loading={loading}
                            text={{}}
                        />
                    )}
                    {user.role === 'guide' && item.status === 'PENDING' && (
                        <div className="flex space-x-4 justify-end">
                            <Button
                                text={t('button.decline')}
                                classes={{
                                    button: '!px-2 py-1 !text-t_purple_500 bg-white border',
                                }}
                                onClick={() => {
                                    onActionClick({ type: 'decline', data: item })
                                }}
                            />
                            <Button
                                text={t('button.acceptTheTour')}
                                classes={{
                                    button: '!px-2 py-1 bg-t_purple_500 border',
                                }}
                                onClick={() => {
                                    onActionClick({ type: 'accept', data: item })
                                }}
                            />
                        </div>
                    )}
                    {user.role === 'guide' && item.status === 'STAFFED' && (
                        <div className="flex space-x-4 justify-end">
                            <Button
                                text={t('button.cancelTheTour')}
                                classes={{
                                    button: '!px-2 py-1 !text-t_red_500 !bg-[#FF3B3B1A] !border-t_red_500',
                                }}
                                onClick={() => {
                                    onActionClick({ type: 'cancel', data: item })
                                }}
                            />
                            <Button
                                text={t('button.close')}
                                classes={{
                                    button: '!px-2 py-1 bg-t_purple_500 border',
                                }}
                                onClick={() => {
                                    onCancel()
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
export default memo(BookingForm)
