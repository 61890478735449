import { memo } from 'react'
import * as icons from '@Assets/icons'

const Icon = ({ name, classes, onClick }) => {
    return (
        <svg
            className={classes}
            fill="currentColor"
            preserve-aspect-ratio="xMidYMid meet"
            aria-hidden="true"
            focusable="false"
            onClick={onClick}
        >
            <use href={`${icons[name]}#icon-${name}`} />
        </svg>
    )
}

export default memo(Icon)
