import { memo, useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { ChevronDown } from 'react-feather'

const FormInput = ({
    label,
    type = 'text',
    name,
    value = '',
    placeholder,
    isDisabled = false,
    isRequired = false,
    classes = {},
    error = {},
    handleChange,
    handleKeyDown,
    maxLength,
    autoComplete = '',
    // select prop
    options = null,
    valueSelected = null,
    isShowValueSelected = false,
    onSelect,
}) => {
    const { t } = useTranslation()
    const isShowLabel = label?.length > 0
    const inputRef = useRef(null)
    const popupRef = useRef(null)
    const [isFocus, setIsFocus] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [searchPattern, setSearchPattern] = useState('')

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowOptions(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [showOptions, popupRef])

    const renderOptions = () => (
        <>
            <div className="absolute left-0 top-0 h-full">
                <p
                    onClick={() => setShowOptions(true)}
                    className={classNames(
                        'border-r border-t_gray_400 w-[4.5rem] h-full flex justify-center items-center cursor-pointer',
                        isFocus && 'text-t_purple_500 border-t_purple_500',
                        classes.itemSelected
                    )}
                >
                    {isShowValueSelected
                        ? valueSelected
                        : options.find((item) => item.value === valueSelected)?.label}
                    <ChevronDown className="w-3 ml-1" />
                </p>
            </div>
            {showOptions && (
                <div
                    ref={popupRef}
                    className={classNames(
                        'absolute top-12 z-30 bg-white shadow-lg border border-t_gray_300 overflow-auto h-80 p-2 pt-0 rounded-md w-full',
                        classes.options
                    )}
                >
                    <input
                        name="search"
                        type="text"
                        className="w-full outline-none border-b border-gray-300 px-2 sticky top-0 pt-2"
                        placeholder={t('placeholder.search')}
                        value={searchPattern}
                        onChange={(e) => setSearchPattern(e.target.value)}
                    />
                    <div className="mt-2">
                        {options
                            .filter((item) =>
                                searchPattern
                                    ? item.label.toLowerCase().includes(searchPattern.toLowerCase())
                                    : true
                            )
                            .map((item, index) => (
                                <div
                                    key={index}
                                    className={classNames(
                                        'flex justify-between cursor-pointer hover:bg-gray-100 px-1',
                                        valueSelected === item.value &&
                                            '!bg-t_purple_500 text-white'
                                    )}
                                    onClick={() => {
                                        onSelect(item.value)
                                        setShowOptions(false)
                                    }}
                                >
                                    {item.label}
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </>
    )

    return (
        <>
            <label className={classNames('relative flex flex-col', classes.label)}>
                {isShowLabel && (
                    <span className={classNames(isDisabled && 'text-gray-400 mb-1')}>
                        {t(label)}
                    </span>
                )}
                <div
                    className={classNames(
                        'flex items-center border-2 relative',
                        isFocus
                            ? 'border-t_purple_500_12 rounded-lg'
                            : 'border-transparent rounded-md'
                    )}
                >
                    {renderOptions()}
                    <input
                        className={classNames(
                            'w-full h-10 outline-none border border-t_gray_400 rounded-md pl-20',
                            classes.input,
                            isFocus ? 'border-t_purple_500 text-t_purple_500' : 'border-t_gray_400',
                            { '!border-red-500': error.message || error.showWithoutMessage },
                            { '!text-gray-400': isDisabled }
                        )}
                        type={type}
                        name={name}
                        placeholder={t(placeholder)}
                        disabled={isDisabled}
                        value={value}
                        required={isRequired}
                        onChange={(e) => {
                            handleChange(name, e.target.value)
                        }}
                        onFocus={() => setIsFocus(true)}
                        onBlur={() => setIsFocus(false)}
                        onKeyDown={handleKeyDown}
                        maxLength={maxLength}
                        autoComplete={autoComplete}
                        ref={inputRef}
                    />
                </div>
            </label>

            {error.message && (
                <p className={classNames('text-red-500 mt-0.5 leading-5', classes.error)}>
                    {t(error.message, { field: t(error.field) })}
                </p>
            )}
        </>
    )
}

export default memo(FormInput)
