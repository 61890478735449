import { toast } from 'react-toastify'

export const toastSuccess = (message = '') =>
    toast.success(message, {
        theme: 'colored',
    })

export const toastError = (message = '') =>
    toast.error(message, {
        theme: 'colored',
    })

export const toastWarning = (message = '') =>
    toast.warning(message, {
        theme: 'colored',
    })

export const toastInfo = (message = '') =>
    toast.info(message, {
        theme: 'colored',
    })
