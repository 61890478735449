import { memo, useState, useRef } from 'react'
import classNames from 'classnames'
import { Icon as IconComponent } from '@Views'
import { useTranslation } from 'react-i18next'

const FormInput = ({
    label,
    type = 'text',
    name,
    value = '',
    placeholder,
    isDisabled = false,
    isRequired = false,
    classes = {},
    icon,
    error = {},
    handleChange,
    handleKeyDown,
    handleIconClick,
    maxLength,
    autoComplete = '',
}) => {
    const { t } = useTranslation()
    const Icon = icon ?? '' // set icon as tag html
    const isFeatherIcon = !icon?.name
    const isShowLabel = label?.length > 0
    const inputRef = useRef(null)
    const [isFocus, setIsFocus] = useState(false)

    return (
        <>
            <label className={classNames('relative flex flex-col gap-1', classes.label)}>
                {isShowLabel && (
                    <span className={classNames(isDisabled && 'text-gray-400')}>{t(label)}</span>
                )}
                <div className="relative">
                    <div
                        className={classNames(
                            'flex items-center border-2',
                            isFocus
                                ? 'border-t_purple_500_12 rounded-lg'
                                : 'border-transparent rounded-md'
                        )}
                    >
                        <input
                            className={classNames(
                                'w-full h-10 outline-none border border-t_gray_400 rounded-md px-2',
                                classes.input,
                                isFocus
                                    ? 'border-t_purple_500 text-t_purple_500'
                                    : 'border-t_gray_400',
                                { '!border-red-500': error.message || error.showWithoutMessage },
                                { '!text-gray-400': isDisabled },
                                { 'pr-6': icon }
                            )}
                            type={type}
                            name={name}
                            placeholder={t(placeholder)}
                            disabled={isDisabled}
                            value={value}
                            required={isRequired}
                            onChange={(e) => {
                                handleChange(name, e.target.value)
                            }}
                            onFocus={() => setIsFocus(true)}
                            onBlur={() => setIsFocus(false)}
                            onKeyDown={handleKeyDown}
                            maxLength={maxLength}
                            autoComplete={autoComplete}
                            ref={inputRef}
                        />
                    </div>

                    {isFeatherIcon && Icon && (
                        <Icon
                            className={classNames(
                                'absolute right-2 top-1/2 -translate-y-1/2 w-4 text-gray-700',
                                classes.icon
                            )}
                            onClick={handleIconClick}
                        />
                    )}

                    {!isFeatherIcon && (
                        <IconComponent
                            name={icon?.name}
                            classes={classNames(
                                'absolute right-2 top-1/2 -translate-y-1/2 w-4 text-gray-700',
                                classes.icon
                            )}
                            onClick={handleIconClick}
                        />
                    )}
                </div>
            </label>

            {error.message && (
                <p className={classNames('text-red-500 mt-0.5 leading-5', classes.error)}>
                    {t(error.message, { field: t(error.field) })}
                </p>
            )}
        </>
    )
}

export default memo(FormInput)
