/* eslint-disable react-hooks/exhaustive-deps */
import { FormInput, Button } from '@Views'
import { useTranslation } from 'react-i18next'
import { useLayoutEffect, useState } from 'react'
import { validateForm } from '@Libs/validation'
import { resetPassValidation } from '@Constants'
import { api } from '@Api'
import { useLocation, useNavigate } from 'react-router-dom'
import { Eye, EyeOff } from 'react-feather'
import { setUser, setToken } from '@Store/persisted/userSlice'
import { useDispatch } from 'react-redux'
import { toastSuccess } from '@Libs/toast'

export default function ResetPasswordPage() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        password: '',
        passwordRepeat: '',
    })
    const [isShowPassword, setIsShowPassword] = useState(false)
    const token = new URLSearchParams(useLocation().search).get('token')
    const isActived = new URLSearchParams(useLocation().search).get('isActived') || ''

    useLayoutEffect(() => {
        const fetchApi = async () => {
            try {
                await api.checkResetPasswordToken({
                    token: token,
                })
            } catch (err) {
                navigate('/login')
            }
        }
        fetchApi()
    }, [])

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSubmit = async () => {
        try {
            const errorObj = validateForm(resetPassValidation, formData)
            setErrors(errorObj)

            if (!Object.keys(errorObj).length) {
                setLoading(true)
                const response = await api.resetPasswordGuide(
                    Object.assign(
                        {
                            password: formData.password,
                            resetPasswordToken: token,
                        },
                        !!isActived ? { isActived: true } : {}
                    )
                )
                toastSuccess(t('resetPassword.message'))
                dispatch(setToken(response.token))
                dispatch(setUser(response.user))
                localStorage.setItem('jwt', response.token)
                navigate('/')
            }
        } finally {
            setLoading(false)
        }
    }

    const handleIconClick = () => setIsShowPassword(!isShowPassword)

    return (
        <div>
            <p className=" text-center text-2xl font-medium">{t('resetPassword.title')}</p>
            <FormInput
                classes={{ label: 'mt-5' }}
                label={t('label.password')}
                name="password"
                type={isShowPassword ? 'text' : 'password'}
                value={formData.password}
                placeholder={t('label.password')}
                error={{ message: errors?.password, field: 'placeholder.password' }}
                icon={isShowPassword ? EyeOff : Eye}
                handleChange={handleChange}
                handleIconClick={handleIconClick}
            />

            <FormInput
                classes={{ label: 'mt-3' }}
                label={t('label.passwordRepeat')}
                name="passwordRepeat"
                type={isShowPassword ? 'text' : 'password'}
                value={formData.passwordRepeat}
                placeholder={t('label.passwordRepeat')}
                error={{ message: errors?.passwordRepeat, field: 'placeholder.password' }}
                handleChange={handleChange}
            />

            <div className="flex justify-center">
                <Button
                    text={t('button.resetPassword')}
                    loading={loading}
                    onClick={handleSubmit}
                    classes={{ button: 'mt-4 px-8' }}
                />
            </div>
        </div>
    )
}
