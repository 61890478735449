import classNames from 'classnames'
import { memo } from 'react'

const TextArea = ({
    label,
    classes = {},
    error,
    name,
    value = '',
    handleChange,
    cols,
    rows,
    placeholder,
    isDisabled = false,
}) => {
    const isShowLabel = label?.length > 0
    return (
        <div>
            <label className={classNames('relative flex flex-col space-y-1', classes.label)}>
                {isShowLabel && (
                    <span className={classNames(isDisabled && 'text-gray-400')}>{label}</span>
                )}

                <div className="flex items-center">
                    <textarea
                        className={classNames(
                            'w-full outline-none border border-gray-400 rounded-md px-2',
                            classes.input,
                            isDisabled && '!text-gray-400',
                            { '!border-red-500 ': error }
                        )}
                        disabled={isDisabled}
                        name={name}
                        rows={rows}
                        cols={cols}
                        value={value}
                        onChange={(e) => handleChange(name, e.target.value)}
                        placeholder={placeholder}
                    />
                </div>
            </label>
            {error && (
                <p className={classNames('text-red-500 mt-1.5 leading-5', classes.error)}>
                    {error}
                </p>
            )}
        </div>
    )
}

export default memo(TextArea)
