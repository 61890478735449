import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function CheckRole(props) {
    const user = useSelector((state) => state.persist.user)

    if (props.role === user.role) {
        return <Outlet />
    } else {
        if (user.role === 'guide') {
            return <Navigate to="/guide/tour" />
        } else if (user.role === 'agency') {
            return <Navigate to="/agence/calendar" />
        }
    }
}
