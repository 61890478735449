import { createRoutesFromElements, createBrowserRouter, Route } from 'react-router-dom'
import {
    AuthLayout,
    MainLayout,
    ErrorPage,
    SigninPage,
    SignupPage,
    VerifyCodePage,
    SetPasswordPage,
    InformationPage,
    BookingProviderPage,
    CalendarPage,
    InviteGuidePage,
    CompletePage,
    GuideInformationPage,
    SyncGoogleCalendarPage,
    GuideCalendarPage,
    GuidePage,
    ResetPasswordPage,
    GuideNotifications,
    AgencyNotifications,
    GuideSyncGoogleCalendarPage,
    PrivacyPolicyPage,
} from '@Views'
import Root from './Root'
import AuthGuard from './AuthGuard'
import CheckRole from './CheckRole'

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route errorElement={<ErrorPage />}>
            <Route path="/" element={<Root />} />
            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />

            <Route element={<AuthLayout />}>
                <Route path="signin" element={<SigninPage />} />
                <Route path="active-confirm" element={<ResetPasswordPage />} />
                <Route path="signup">
                    <Route path="" element={<SignupPage />} />
                    <Route path="verify-code" element={<VerifyCodePage />} />
                    <Route path="set-password" element={<SetPasswordPage />} />
                    <Route path="information" element={<InformationPage />} />
                    <Route path="booking-provider" element={<BookingProviderPage />} />
                    <Route path="invite-guide" element={<InviteGuidePage />} />
                    <Route path="complete" element={<CompletePage />} />
                </Route>
                <Route path="guide/signup">
                    <Route path="" element={<SignupPage />} />
                    <Route path="verify-code" element={<VerifyCodePage />} />
                    <Route path="set-password" element={<SetPasswordPage />} />
                    <Route path="information" element={<GuideInformationPage />} />
                    <Route path="sync-google-calendar" element={<SyncGoogleCalendarPage />} />
                </Route>
            </Route>

            <Route element={<AuthGuard />}>
                {/* agence */}
                <Route path="agence" element={<CheckRole role="agency" />}>
                    <Route element={<MainLayout />}>
                        <Route path="calendar" element={<CalendarPage />} />
                        <Route path="guides" element={<GuidePage />} />
                        <Route path="notifications" element={<AgencyNotifications />} />
                        <Route path="hire-your-guide" />
                    </Route>
                </Route>
                {/* guide */}
                <Route path="guide" element={<CheckRole role="guide" />}>
                    <Route element={<MainLayout />}>
                        <Route path="tour" element={<GuideCalendarPage />} />
                        <Route path="notifications" element={<GuideNotifications />} />
                        <Route
                            path="sync-google-calendar"
                            element={<GuideSyncGoogleCalendarPage />}
                        />
                    </Route>
                </Route>
            </Route>
        </Route>
    )
)
export default router
