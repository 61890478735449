import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { BackButton } from './components/BackButton'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormInput, Line, SelectInput, SelectOption, Loading } from '@Views'
import { setSignupData } from '@Store/persisted/userSlice'
import { validateForm } from '@Libs/validation'
import { signupGuideInformationValidation } from '@Constants'
import { phonePrefix } from '@Constants/phonePrefix'
import { languageOptions } from '@Constants'
// import { parseSelectOptionData } from '@Libs/hepler'
// import { api } from '@Api'

export default function GuideInformationPage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const agencyToken = new URLSearchParams(useLocation().search).get('token')
    const signupData = useSelector((state) => state.persist.signupData)
    const isGoogleAccount = signupData?.source === 'google'
    const nextUrl = `/guide/signup/sync-google-calendar?token=${agencyToken}`
    const backUrl = isGoogleAccount
        ? `/guide/signup?token=${agencyToken}`
        : `/guide/signup/set-password?token=${agencyToken}`
    const [formData, setFormData] = useState({
        firstname: signupData.firstname ?? '',
        lastname: signupData.lastname ?? '',
        phone: signupData.phone ?? '',
        phonePrefix: signupData.phonePrefix ?? '+33',
        city: signupData.city ?? '',
        favoriteTours: signupData.favoriteTours ?? '',
        language: signupData.language ?? '',
    })
    const [errors, setErrors] = useState({})
    const [phoneOptions, setPhoneOptions] = useState([])
    // const [loading, setLoading] = useState(false)
    // const [favoriteTourOptions, setFavoriteTourOptions] = useState([])

    useEffect(() => {
        if (signupData.agencyToken && signupData.agencyToken !== agencyToken) {
            navigate(`/guide/signup/set-password?token=${signupData.agencyToken}`)
        }
    }, [agencyToken, signupData, navigate])

    useEffect(() => {
        if ((isGoogleAccount && !signupData.email) || (!isGoogleAccount && !signupData.password))
            navigate(backUrl)
    }, [signupData, navigate, backUrl])

    useEffect(() => {
        // getAgencyTours()
        setPhoneOptions(() =>
            phonePrefix.map((item) => ({
                value: item.value,
                label: `${item.label} (${item.value})`,
            }))
        )
    }, [])

    // const getAgencyTours = async () => {
    //     setLoading(true)
    //     const result = await api.getActivitiesBokun({ agencyId: signupData.agencyId })
    //     setLoading(false)
    //     setFavoriteTourOptions(
    //         parseSelectOptionData({
    //             data: result.activity?.items ?? [],
    //             label: 'title',
    //             value: 'title',
    //         })
    //     )
    // }

    const handleBack = () => {
        navigate(backUrl)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSubmit = async () => {
        const errorObj = validateForm(signupGuideInformationValidation, formData)
        setErrors(errorObj)
        if (!Object.keys(errorObj).length) {
            // setLoading(true)
            const { ...data } = formData
            dispatch(setSignupData({ ...data }))
            navigate(nextUrl)
        }
    }

    return (
        <>
            <BackButton onClick={handleBack} />
            <div className="mt-14 mb-6">
                <div className="text-2xl my-5 font-semibold">{t('information.title')}</div>
                <div className="flex justify-between gap-3 mb-3">
                    <div>
                        <FormInput
                            name="firstname"
                            value={formData.firstname}
                            placeholder={'placeholder.firstname'}
                            error={{ message: errors?.firstname, field: 'placeholder.firstname' }}
                            handleChange={handleChange}
                            handleKeyDown={handleKeyDown}
                        />
                    </div>
                    <div>
                        <FormInput
                            name="lastname"
                            value={formData.lastname}
                            placeholder={'placeholder.lastname'}
                            error={{ message: errors?.lastname, field: 'placeholder.lastname' }}
                            handleChange={handleChange}
                            handleKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                <FormInput
                    name="city"
                    value={formData.city}
                    placeholder={'placeholder.city'}
                    error={{ message: errors?.city, field: 'placeholder.city' }}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                />
                <SelectInput
                    classes={{ label: 'mt-3' }}
                    name="phone"
                    value={formData.phone}
                    options={phoneOptions}
                    valueSelected={formData.phonePrefix}
                    isShowValueSelected
                    onSelect={(value) => handleChange('phonePrefix', value)}
                    placeholder={'placeholder.phone'}
                    error={{ message: errors?.phone, field: 'placeholder.phone' }}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                />
                <Line classes="my-5" />
                {/* <div className="relative">
                    {loading && <Loading isAbsolute />}
                    <SelectOption
                        classes={{ container: 'mt-3' }}
                        options={favoriteTourOptions}
                        placeholder={'placeholder.favoriteTours'}
                        isMulti
                        value={formData.favoriteTours}
                        onChange={(value) => handleChange('favoriteTours', value)}
                        error={{
                            message: errors?.favoriteTours,
                            field: 'placeholder.favoriteTours',
                        }}
                    />
                </div> */}
                <SelectOption
                    classes={{ container: 'mt-3' }}
                    options={languageOptions}
                    placeholder={'placeholder.language'}
                    isMulti
                    value={formData.language}
                    onChange={(value) => handleChange('language', value)}
                    error={{ message: errors?.language, field: 'placeholder.language' }}
                />

                <Button
                    text={'button.continue'}
                    onClick={handleSubmit}
                    classes={{ button: 'mt-5 px-8 w-full font-medium' }}
                />
            </div>
        </>
    )
}
