import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { BackButton } from './components/BackButton'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { GoogleButton } from '../components/GoogleButton'
import { toastSuccess } from '@Libs/toast'
import { api } from '@Api'
import { initSignupData } from '@Store/persisted/userSlice'
import { cloneObjWithKeys } from '@Libs/hepler'
import { Loading } from '@Views'
import classNames from 'classnames'

export default function SyncGoogleCalendarPage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const agencyToken = new URLSearchParams(useLocation().search).get('token')
    const backUrl = `/guide/signup/information?token=${agencyToken}`
    const signupData = useSelector((state) => state.persist.signupData)
    const [loading, setLoading] = useState(false)
    const [loadingGoogle, setLoadingGoogle] = useState(false)

    useEffect(() => {
        if (signupData.agencyToken && signupData.agencyToken !== agencyToken) {
            navigate(`/guide/signup/information?token=${signupData.agencyToken}`)
        }
    }, [agencyToken, signupData, navigate])

    useEffect(() => {
        if (!signupData.firstname) navigate(backUrl)
    }, [signupData, backUrl, navigate])

    const handleBack = () => {
        navigate(backUrl)
    }

    const handleGoogleSubmit = async (googleData) => {
        const googleTokenData = await api.getRefreshTokenGoogle({
            code: googleData.code,
            redirectUrl: window.location.origin,
        })
        handleSubmit({
            googleToken: {
                accessToken: googleTokenData.tokens?.access_token,
                refreshToken: googleTokenData.tokens?.refresh_token,
            },
        })
    }

    const handleSubmit = async (additionData = {}) => {
        try {
            const isGoogleLoading = !!additionData?.googleToken
            isGoogleLoading ? setLoadingGoogle(true) : setLoading(true)
            const dataToCreate = cloneObjWithKeys(signupData, [
                'agencyToken',
                'city',
                'email',
                // 'favoriteTours',
                'password',
                'firstname',
                'lastname',
                'phone',
                'language',
                'verifyToken',
                'source',
            ])
            await api.createGuideUser({
                ...dataToCreate,
                ...additionData,
                loginLink: `${window.location.origin}/signin`,
                phone: `${signupData.phonePrefix} ${signupData.phone}`,
                role: 'guide',
                isActived: true,
            })
            toastSuccess(t('complete.createSuccess'))
            navigate('/signin')
            setTimeout(() => dispatch(initSignupData({})), 100)
        } finally {
            setLoading(false)
            setLoadingGoogle(false)
        }
    }

    return (
        <>
            <BackButton onClick={handleBack} />
            <div className="mt-12 mb-7">
                <div className="text-2xl mt-5 font-semibold">{t('syncGoogle.title')}</div>
                <p className="text-t_gray_500 mt-2 mb-5">{t('syncGoogle.description')}</p>
            </div>
            <GoogleButton
                onSuccess={handleGoogleSubmit}
                loading={loadingGoogle}
                scope="https://www.googleapis.com/auth/calendar.readonly"
                flow="code"
            />
            <div className="flex justify-center mt-5">
                <button
                    className={classNames(
                        'text-black underline flex items-center gap-3',
                        loading && 'pointer-events-none opacity-50'
                    )}
                    onClick={() => handleSubmit()}
                >
                    {t('syncGoogle.willdoItLater')}
                    {loading && <Loading />}
                </button>
            </div>
        </>
    )
}
