import { memo } from 'react'
import classNames from 'classnames'
import { Check } from 'react-feather'
import { useTranslation } from 'react-i18next'

const CheckBox = ({ label, isChecked = false, onChange, classes = {}, error }) => {
    const { t } = useTranslation()
    return (
        <>
            <label
                className={classNames('flex items-center gap-2 cursor-pointer py-2', classes.label)}
            >
                <p
                    className={classNames(
                        'w-4 h-4 border border-t_gray_400 flex items-center justify-center rounded-sm',
                        classes.checkbox,
                        isChecked && '!bg-t_purple_500 border-t_purple_500'
                    )}
                >
                    {isChecked && (
                        <Check className={classNames('w-4 h-4 text-white', classes.checkbox)} />
                    )}
                </p>
                {label && <span>{t(label)}</span>}
                <input
                    type="checkbox"
                    className="opacity-0 h-0 w-0"
                    checked={isChecked}
                    onChange={(value) => onChange(value.target.checked)}
                />
            </label>
            {error && (
                <p className={classNames('text-red-500 mt-0.5 leading-5', classes.error)}>
                    {t(error)}
                </p>
            )}
        </>
    )
}

export default memo(CheckBox)
