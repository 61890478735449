import { default as login } from './login.json'
import { default as signup } from './signup.json'
import { default as calendar } from './calendar.json'

import { default as guide } from './guide.json'
import { default as notification } from './notification.json'

export const pages = {
    ...login,
    ...signup,
    ...calendar,
    ...guide,
    ...notification,
}
