import { useRouteError } from 'react-router-dom'
import { Button } from '@Views'
import { useNavigate } from 'react-router-dom'

export default function ErrorPage() {
    const error = useRouteError()
    const navigate = useNavigate()

    return (
        <div id="error-page" className="h-screen flex justify-center items-center">
            <div className="flex flex-col items-center space-y-4">
                <p className="text-9xl font-bold text-red-500">Oops!</p>
                <p className="text-xl font-bold">{error.statusText || error.message}</p>
                <Button text={'button.backToHome'} onClick={() => navigate('/')} />
            </div>
        </div>
    )
}
