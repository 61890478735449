import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Root() {
    const token = useSelector((state) => state.persist.token)
    const user = useSelector((state) => state.persist.user)

    if (!token) {
        return <Navigate to="signin" />
    } else if (user?.role === 'agency') {
        return <Navigate to="/agence/calendar" />
    } else {
        return <Navigate to="/guide/tour" />
    }
}
