import { memo } from 'react'
import classNames from 'classnames'
import { RefreshCw } from 'react-feather'
import { useTranslation } from 'react-i18next'

const Button = ({
    text,
    classes = {},
    isDisabled = false,
    icon = {},
    loading = false,
    onClick,
}) => {
    const { t } = useTranslation()
    const Icon = !loading && icon.icon ? icon.icon : null
    const PrevIcon = icon.prevIcon
    return (
        <button
            className={classNames(
                'flex items-center justify-center border border-t_purple_500 bg-t_purple_500 text-white font-medium px-2 py-1.5 rounded-md whitespace-nowrap',
                classes.button,
                { 'opacity-50 cursor-not-allowed': loading },
                { 'cursor-not-allowed bg-gray-300 border-gray-300': isDisabled }
            )}
            disabled={isDisabled}
            onClick={onClick}
        >
            {PrevIcon && (
                <PrevIcon
                    className={classNames('mr-2 w-4 h-4', classes.prevIcon, {
                        'text-gray-300': isDisabled,
                    })}
                />
            )}
            {t(text)}
            {Icon && <Icon className={classNames('ml-2 h-4 w-4 ', classes.icon)} />}
            {loading && <RefreshCw className="animate-spin ml-2 w-4 h-4" />}
        </button>
    )
}

export default memo(Button)
