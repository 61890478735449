import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@Views'
import classNames from 'classnames'

const FormButton = ({ text, onSave, onCancel, loading, classes = {} }) => {
    const { t } = useTranslation()
    return (
        <div className={classNames('flex gap-4 justify-end items-center', classes.content)}>
            <Button
                className={'text-t_purple_500'}
                text={text.close || t('button.close')}
                classes={{
                    button: '!min-w-20 !bg-t_blue_200 !border-t_blue_200 !text-t_purple_500',
                    ...classes.closeBtn,
                }}
                onClick={onCancel}
            />
            <Button
                text={text.save || t('button.save')}
                classes={{
                    button: '!min-w-32',
                    ...classes.saveBtn,
                }}
                loading={loading}
                onClick={onSave}
            />
        </div>
    )
}

export default memo(FormButton)
