import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormInput } from '@Views'
import { validEmail } from '@Libs/hepler'
import { api } from '@Api'
import { RefreshCw } from 'react-feather'
import { toastSuccess } from '@Libs/toast'

export default function InviteForm({ onSuccess }) {
    const { t } = useTranslation()
    const signupData = useSelector((state) => state.persist.signupData)
    const [email, setEmail] = useState('')
    const [inviteEmails, setInviteEmails] = useState(signupData.inviteEmails ?? [])
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleInvite()
        }
    }

    const handleInvite = async () => {
        if (checkValidEmail()) {
            const listEmail = email.split(',')?.map((item) => item.trim())
            const inviteList = [...new Set(inviteEmails.concat(listEmail))]
            setInviteEmails([...inviteList])
            if (inviteList.length > 0) {
                setLoading(true)
                await api.inviteGuideByAgency({ inviteEmails: inviteList })
                setLoading(false)
                toastSuccess(
                    <div>
                        <div className="font-bold text-base">{t('guide.guideInvited')}</div>
                        <div className="text-sm">{t('guide.emailSent')}</div>
                    </div>
                )
                onSuccess()
            }
        }
    }

    const checkValidEmail = () => {
        const listEmail = email.split(',')
        setError(null)
        for (let item of listEmail) {
            if (!validEmail(item)) {
                setError(t('inviteGuides.invalidEmail', { email: item }))
                return false
            }
        }
        return true
    }

    return (
        <>
            <div className="mb-7 mx-6">
                <div className="text-2xl font-semibold">{t('inviteGuides.title')}</div>
                <div className="relative">
                    <FormInput
                        classes={{ label: 'mt-3', input: 'pr-24' }}
                        name="email"
                        value={email}
                        placeholder={'inviteGuides.email'}
                        error={{ message: error }}
                        handleChange={(_, value) => setEmail(value)}
                        handleKeyDown={handleKeyDown}
                    />
                    <button
                        onClick={handleInvite}
                        className="flex items-center px-3 py-1 border border-t_purple_500 rounded-md text-t_purple_500 absolute top-1.5 right-2 hover:text-white hover:bg-t_purple_500"
                    >
                        {t('inviteGuides.invite')}
                        {loading && <RefreshCw className="animate-spin ml-2 w-4 h-4" />}
                    </button>
                </div>
            </div>
        </>
    )
}
