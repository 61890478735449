/* eslint-disable jsx-a11y/alt-text */
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useState, memo, useRef, useMemo } from 'react'
import { FormButton } from '@Views'

const ImageCrop = ({ src, onCancel, onSave, aspectImage }) => {
    const imageRef = useRef(null)
    const [crop, setCrop] = useState()

    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: 'px',
                    width: 450,
                },
                aspect,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        )
    }

    function onImageLoad(e) {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, aspectImage || 1))
    }
    const handleSave = () => {
        onSave(imageRef.current, crop)
    }
    const screenHeight = useMemo(() => document.documentElement.clientHeight, [])
    return (
        <>
            <ReactCrop
                crop={crop}
                onChange={(c) =>
                    setCrop({ ...c, width: aspectImage ? aspectImage * c.height : c.width })
                }
            >
                <img
                    style={{ maxHeight: screenHeight - 205 }}
                    className="object-contain"
                    src={src}
                    ref={imageRef}
                    onLoad={onImageLoad}
                />
            </ReactCrop>
            <FormButton
                text={{ save: 'button.crop', close: 'button.cancel' }}
                classes={{ content: 'mx-4 my-3' }}
                onCancel={onCancel}
                onSave={handleSave}
            />
        </>
    )
}
export default memo(ImageCrop)
