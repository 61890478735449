import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@Views'

const ConfirmModal = ({ onConfirm, onClose, loading, text }) => {
    const { t } = useTranslation()
    return (
        <>
            <p className="text-center pt-5 pb-6 text-xl font-semibold text-t_blue_500 whitespace-pre-line">
                {t(text ?? 'modal.confirmModal')}
            </p>
            <div className="flex space-x-6 justify-center my-3">
                <Button
                    text={t('button.confirm')}
                    classes={{
                        button: '!px-7 py-2',
                    }}
                    loading={loading}
                    onClick={onConfirm}
                />
                <Button
                    text={t('button.close')}
                    classes={{
                        button: '!bg-t_red_500 !border-t_red_500 !px-9 py-2',
                    }}
                    onClick={onClose}
                />
            </div>
        </>
    )
}

export default memo(ConfirmModal)
