import { memo, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Loading, Icon } from '@Views'

const SelectOption = ({
    name,
    label,
    menuPlacement = 'bottom',
    isMulti = false,
    isCreatable = false,
    isLoading = false,
    value,
    options = [],
    placeholder,
    classes = {},
    error = {},
    onChange,
    onCreateOption,
    isClearable = false,
    defaultValue = '',
    isDisabled = false,
    maxMenuHeight = 200,
    closeMenuOnSelect = true,
    isGrouped = false,
}) => {
    const { t } = useTranslation()
    const SelectComponent = isCreatable ? CreatableSelect : Select
    const [isFocused, setIsFocused] = useState(false)

    options.forEach((item) => (item.label = t(item.label)))
    const selectedClass = classes.selected ?? '!bg-t_purple_500'

    let valueOption = null
    if (isMulti) {
        valueOption = options.filter((e) => value?.includes(e.value))
    } else if (isGrouped) {
        let optionlist = []
        options.forEach((el) => {
            optionlist = optionlist.concat(el.options)
        })
        const selectedIndex = optionlist.findIndex((e) => e.value === value)
        valueOption = optionlist[selectedIndex] ?? ''
    } else {
        const selectedIndex = options.findIndex((e) => e.value === value)
        valueOption = options[selectedIndex] ?? ''
    }
    const realValue = !isMulti ? (valueOption !== '' ? valueOption : defaultValue) : ''
    const realOptions = isMulti ? options.filter((e) => !value?.includes(e.value)) : options

    const handleChange = (e) => {
        closeMenuOnSelect && setIsFocused(false)
        if (isMulti) {
            onChange([...value, e?.value ?? ''])
        } else {
            onChange(e?.value ?? '')
        }
    }

    const handleRemoveItem = (item) => {
        onChange(value.filter((v) => v !== item))
    }

    const colourStyles = () => {
        return {
            option: (styles, { data, isFocused }) => {
                let newStyles = { ...styles }
                newStyles = {
                    ...newStyles,
                    backgroundColor: isFocused
                        ? data.backgroundColor || '#5D34F21F'
                        : data.backgroundColor || '',
                }
                return {
                    ...newStyles,
                }
            },
        }
    }

    return (
        <div className={classNames('flex flex-col', label && 'space-y-1', classes.container)}>
            {label && (
                <label
                    className={classNames(
                        'text-t_purple_500 font-medium shadow-none',
                        isDisabled && '!text-gray-400',
                        classes.label
                    )}
                >
                    {t(label)}
                </label>
            )}
            <div
                className={classNames(
                    'relative border-2',
                    isFocused
                        ? 'border-t_purple_500_12 rounded-lg'
                        : 'border-transparent rounded-md'
                )}
            >
                {isLoading === name && (
                    <Loading isAbsolute classes={{ loading: '!border-t_purple_500' }} />
                )}
                <SelectComponent
                    value={realValue}
                    placeholder={t(placeholder)}
                    menuPlacement={menuPlacement}
                    isClearable={isClearable}
                    blurInputOnSelect={true}
                    classNames={{
                        control: () =>
                            classNames(
                                '!shadow-none !border-1 !rounded-md h-10',
                                classes.control,
                                error.message
                                    ? '!border-red-500'
                                    : isFocused
                                    ? '!border-t_purple_500'
                                    : '!border-t_gray_400'
                            ),
                        singleValue: () =>
                            classNames(
                                classes.singleValue,
                                isDisabled ? '!text-gray-400' : classes.colorTextNotDisabled
                            ),
                        option: (state) =>
                            classNames(state.isSelected ? selectedClass : '', classes.option),
                        input: () => classNames('!text-t_purple_500', classes.input),
                    }}
                    options={realOptions}
                    formatCreateLabel={(value) => `${t('select.create')} "${value}"`}
                    noOptionsMessage={() => t('select.noOptionsMessage')}
                    onChange={(e) => handleChange(e)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onCreateOption={onCreateOption}
                    isDisabled={isDisabled}
                    maxMenuHeight={maxMenuHeight}
                    closeMenuOnSelect={closeMenuOnSelect}
                    styles={colourStyles()}
                />
            </div>
            {isMulti && (
                <div className="flex flex-wrap gap-2">
                    {valueOption?.map((item, index) => (
                        <div
                            key={index}
                            className="flex items-center gap-2 py-1 px-3 mt-2 bg-t_pale_200 text-t_pale_600 w-fit rounded-md"
                        >
                            {item.label}
                            <Icon
                                name={'cross'}
                                classes={
                                    'min-w-[0.625rem] w-2.5 h-2.5 text-t_pale_500 cursor-pointer'
                                }
                                onClick={() => handleRemoveItem(item.value)}
                            />
                        </div>
                    ))}
                </div>
            )}

            {error.message && (
                <p className={classNames('text-red-500 mt-0.5 leading-5', classes.error)}>
                    {t(error.message, { field: t(error.field) })}
                </p>
            )}
        </div>
    )
}

export default memo(SelectOption)
