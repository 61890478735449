import { memo } from 'react'
import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import { Calendar } from 'react-feather'
import classNames from 'classnames'

const FormDateTimePicker = ({
    label,
    onChange,
    startDate,
    error,
    isDisabled,
    classes = {},
    isShowIconX,
    ...prop
}) => {
    function CustomInput({ onFocus, value, onChange }) {
        return (
            <div className={classNames('relative', classes.input)}>
                <input
                    onFocus={onFocus}
                    value={value}
                    onChange={onChange}
                    disabled={isDisabled}
                    className={classNames(
                        'w-full h-12 outline-none border border-t_gray_400 bg-t_gray rounded-md px-2',
                        classes.input,
                        [error ? '!border-red-500' : '!border-t_gray_50'],
                        isDisabled && 'text-gray-400'
                    )}
                />
                <Calendar
                    className={classNames(
                        'w-4 h-4 absolute right-2 bottom-1/2 translate-y-1/2',
                        isDisabled && 'text-gray-400'
                    )}
                />
            </div>
        )
    }
    return (
        <div className="flex flex-col space-y-1">
            <p
                className={classNames(
                    'text-t_blue_500 font-medium',
                    isDisabled && '!text-gray-400'
                )}
            >
                {label}
            </p>
            <DatePicker
                weekStartDayIndex={1}
                value={startDate}
                onChange={(date) => {
                    const dateFormat = new Date(date)
                    if (startDate === dateFormat) {
                        onChange('')
                    } else {
                        onChange(dateFormat)
                    }
                }}
                plugins={[<TimePicker hideSeconds position="bottom" />]}
                render={<CustomInput />}
                {...prop}
            />
            {error && <p className="text-red-500 mt-0.5 leading-5 font-thin">{error}</p>}
        </div>
    )
}
export default memo(FormDateTimePicker)
