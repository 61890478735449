import { memo } from 'react'
import ReactPaginate from 'react-paginate'
import { useDispatch } from 'react-redux'
import { setParams } from '@Store/userSlice'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { useTranslation } from 'react-i18next'

function Pagination({ itemPerPage = 10, totalPages = 0, currentPage }) {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handlePageClick = (event) => {
        if (event.selected !== currentPage) {
            dispatch(setParams({ offset: event.selected * itemPerPage, limit: itemPerPage }))
        }
    }

    return (
        <div className="relative">
            <ReactPaginate
                nextLabel={
                    <div className="flex items-center space-x-2">
                        <div>{t('button.next')}</div>
                        <ArrowRight />
                    </div>
                }
                previousLabel={
                    <div className="flex items-center space-x-2 ">
                        <ArrowLeft />
                        <div>{t('button.previous')}</div>
                    </div>
                }
                previousClassName="border rounded-lg absolute left-0 px-3 py-1.5 text-sm"
                nextClassName="border rounded-lg absolute right-0 px-3 py-1.5 text-sm"
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={totalPages}
                forcePage={currentPage}
                pageClassName={'rounded-full'}
                pageLinkClassName={
                    'items-center w-[1.25rem] max-h-[1.25rem] flex justify-center text-sm font-medium'
                }
                breakLabel="..."
                breakClassName={'flex items-end'}
                breakLinkClassName={'text-3xl leading-[1.25rem] text-t_gray_550 '}
                containerClassName="pagination"
                activeClassName={'bg-t_purple_500 !text-white'}
                renderOnZeroPageCount={null}
                className={'flex space-x-3 justify-center mt-6 h-8 items-center'}
            />
        </div>
    )
}

export default memo(Pagination)
