/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useState, useMemo, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ChevronDown, ChevronUp, Loader, Plus } from 'react-feather'
import { useDispatch } from 'react-redux'
import { setParams } from '@Store/userSlice'
import classNames from 'classnames'
import { Pagination, Modal, ConfirmModal, Button, InviteForm, CheckBox } from '@Views'
import emitter from '@Libs/emitter'

const List = ({
    listName,
    heads,
    data = {},
    component = {},
    modal = {},
    button = {},
    loading,
    itemPerPage = 10,
    onActionConfirm,
    onReload,
    classes = {},
    isDisableFieldList,
    totalText = '',
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const items = useMemo(() => data?.docs || data || [], [data])
    const totalDocs = useMemo(() => data?.totalDocs || 0, [data])
    const totalPages = data?.totalPages ?? 0
    const currentPage = data?.page ? data.page - 1 : 0
    const ItemComponent = component?.item
    const FormComponent = component?.form
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)
    const [isShowFormModal, setIsShowFormModal] = useState(null)
    const [currentItem, setCurrentItem] = useState({})
    const [actionType, setActionType] = useState({})
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [sortOrder, setSortOrder] = useState(-1)
    const [sortNameInit, setSortNameInit] = useState('')
    const [positionList, setPositionList] = useState(0)
    const [isShowInviteModal, setIsShowInviteModal] = useState(false)
    const [checkBoxData, setCheckBoxData] = useState([])
    const [favorites, setFavorites] = useState([])

    const screenHeight = useMemo(() => document.documentElement.clientHeight, [])
    const elementRef = useRef(null)

    useEffect(() => {
        const element = elementRef.current
        const rect = element?.getBoundingClientRect()
        const top = rect?.top
        if (top) {
            setPositionList(top)
        }
        if (listName === 'guide-list') {
            const guides = [...items]
            let favoriteTours = []
            guides.forEach((element) => {
                const favorite = (element.favoriteTours || []).filter(
                    (favorite) => !favoriteTours.includes(favorite)
                )
                favoriteTours = [...favoriteTours, ...favorite]
            })
            setFavorites([...favoriteTours])
        }
    }, [loading, items])

    const modalTitle = useMemo(
        () => (['add'].includes(isShowFormModal) ? modal.form?.addTitle : modal.form?.editTitle),
        [isShowFormModal]
    )

    const handleSort = (sortData) => {
        let currentSortOrder = 0
        if (sortData !== sortNameInit) {
            currentSortOrder = 1
        } else {
            currentSortOrder = sortOrder * -1
        }
        setSortNameInit(sortData)
        setSortOrder(currentSortOrder)
        dispatch(setParams({ sortData, sortOrder: currentSortOrder }))
    }

    const handleActionClick = ({ type, data }) => {
        setIsShowConfirmModal(true)
        setCurrentItem(data)
        setActionType(type)
    }

    const handleConfirm = async () => {
        try {
            setConfirmLoading(true)
            await onActionConfirm({ currentItem, actionType })
        } finally {
            setIsShowConfirmModal(false)
            setConfirmLoading(false)
        }
    }

    const handleFormSuccess = () => {
        setIsShowFormModal(null)
        setIsShowInviteModal(false)
        onReload()
    }

    const handelItemClick = (item) => {
        // handle open detail modal
        setCurrentItem(item)
        setIsShowFormModal('edit')
    }

    useEffect(() => {
        const handler = (data) => {
            setCurrentItem(data)
        }
        emitter.on('seeMessage', handler)
        return () => {
            emitter.off('seeMessage', handler)
        }
    }, [])

    const handleChangeCheckBox = (name, value) => {
        setCheckBoxData({
            ...checkBoxData,
            [name]: value,
        })
    }

    const renderHead = () => {
        return (
            <>
                {heads?.map((head, index) => (
                    <div
                        key={`head_${index}`}
                        className={classNames(
                            'col py-3 bg-t_gray_70 flex items-center',
                            head.colClass
                        )}
                    >
                        <div
                            className={classNames(
                                'flex items-center text-xs text-t_gray_550 font-medium',
                                head.isSort && 'cursor-pointer'
                            )}
                            onClick={() => {
                                head.isSort && handleSort(head.sortName)
                            }}
                        >
                            {t(head.text)}
                            {head.type === 'checkBox' && (
                                <CheckBox
                                    classes={{
                                        label: 'w-fit',
                                        checkbox: 'w-4 h-4',
                                    }}
                                    isChecked={checkBoxData[head.checkAll]}
                                    onChange={(value) => handleChangeCheckBox(head.checkAll, value)}
                                />
                            )}
                            {head.isSort &&
                                (sortOrder === -1 || sortNameInit !== head.sortName) && (
                                    <ChevronDown className="w-5 h-4 ml-1 text-t_blue_500" />
                                )}
                            {head.isSort && sortNameInit === head.sortName && sortOrder === 1 && (
                                <ChevronUp className="w-5 ml-1 text-t_blue_500" />
                            )}
                        </div>
                    </div>
                ))}
            </>
        )
    }

    return (
        <div className="bg-white pt-4 rounded-xl">
            {/* create button */}
            <div
                className={classNames('flex items-center mx-6', 'justify-between', classes.button)}
            >
                {totalText && (
                    <div className="text-lg font-semibold flex space-x-4 items-center">
                        {totalText}
                        <div className="border border-[#E9D7FE] rounded-full text-[#6941C6] bg-[#F9F5FF] font-medium text-sm flex items-center ml-4 h-6 px-2">
                            {totalDocs} users
                        </div>
                    </div>
                )}
                <div className="flex space-x-4">
                    {button.create && (
                        <Button
                            text={t(button.create.title)}
                            icon={{ prevIcon: Plus }}
                            classes={{
                                prevIcon: classNames('w-3 !h-3 !mr-0.5 text-white rounded-full'),
                                button: '!px-2 py-1',
                            }}
                            onClick={() => setIsShowFormModal('add')}
                        />
                    )}
                    <Button
                        text={t(`Invite guide`)}
                        classes={{
                            prevIcon: classNames('w-7 !h-7 text-white rounded-full'),
                            button: '!px-2 py-1',
                        }}
                        onClick={() => setIsShowInviteModal(true)}
                    />
                </div>
            </div>
            <div className={classNames('list-table mt-6', listName)}>
                {/* loading and no result */}
                <div className="flex justify-center items-center">
                    {loading && (
                        <Loader className="w-10 h-10 animate-spin text-center text-t_cyan_500 mt-10" />
                    )}
                    {!loading && !items.length && (
                        <div className=" text-red-500 mt-10">{t('validation.errorNotFound')}</div>
                    )}
                </div>
                {/* table */}
                {!loading && items.length > 0 && (
                    <>
                        <div
                            className={classNames(
                                'w-full px-6 flex border-y',
                                typeof classes.boder === 'function' && 'pl-5'
                            )}
                        >
                            {renderHead()}
                        </div>
                        <div
                            ref={elementRef}
                            className="overflow-auto pb-5 px-6 "
                            style={{ height: screenHeight - positionList - 20 }}
                        >
                            <div className="w-full flex flex-col">
                                {items.map((item, idx) => (
                                    <div
                                        className={classNames(
                                            'row text-sm flex items-center font-medium relative border-b',
                                            classes.container
                                        )}
                                        key={`${item._id}-${idx}`}
                                        // onClick={() => handelItemClick(item)}
                                    >
                                        <div className="col w-10 flex justify-center">
                                            <CheckBox
                                                classes={{
                                                    label: 'w-fit',
                                                    checkbox: 'w-4 h-4',
                                                }}
                                                isChecked={item.choose}
                                                // onChange={(value) => handleChange('term', value)}
                                            />
                                        </div>
                                        <ItemComponent
                                            item={item}
                                            onActionClick={handleActionClick}
                                            isDisable={isDisableFieldList}
                                            onReload={onReload}
                                            isLastItem={
                                                items.length > 1 ? items.length === idx + 1 : false
                                            }
                                            onItemEdit={() => handelItemClick(item)}
                                            favorites={favorites}
                                        />
                                    </div>
                                ))}
                            </div>

                            {totalPages > 1 && (
                                <Pagination
                                    itemPerPage={itemPerPage}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
            {isShowFormModal && (
                <Modal
                    title={t(modalTitle)}
                    classes={{ content: modal?.form?.class, children: modal?.form?.children }}
                    onCancel={() => setIsShowFormModal(null)}
                >
                    <FormComponent
                        item={
                            isShowFormModal === 'add' && !button.back?.isShowBack ? {} : currentItem
                        }
                        onCancel={() => setIsShowFormModal(null)}
                        onSuccess={handleFormSuccess}
                        onActionClick={handleActionClick}
                        typeActionFormModal={isShowFormModal}
                    />
                </Modal>
            )}
            {isShowInviteModal && (
                <Modal
                    title={t(modalTitle)}
                    classes={{ content: 'w-[460px]' }}
                    onCancel={() => setIsShowInviteModal(false)}
                >
                    <InviteForm onSuccess={handleFormSuccess} />
                </Modal>
            )}
            {/* confirm modal */}
            {isShowConfirmModal && (
                <Modal onCancel={() => setIsShowConfirmModal(false)}>
                    <ConfirmModal
                        loading={confirmLoading}
                        onClose={() => setIsShowConfirmModal(false)}
                        onConfirm={handleConfirm}
                        text={modal?.form?.textConfirm}
                    />
                </Modal>
            )}
        </div>
    )
}

export default memo(List)
