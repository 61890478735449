import { memo, useState, useRef } from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'
import AsyncSelect from 'react-select/async'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Loading, Icon } from '@Views'

const SelectOption = ({
    name,
    label,
    menuPlacement = 'bottom',
    isCreatable = false,
    isLoading = false,
    getDataOptions,
    value,
    placeholder,
    classes = {},
    error = {},
    onChange,
    onCreateOption,
    isClearable = false,
    isDisabled = false,
    maxMenuHeight = 200,
    closeMenuOnSelect = true,
}) => {
    const { t } = useTranslation()
    const SelectComponent = isCreatable ? AsyncCreatableSelect : AsyncSelect
    const [isFocused, setIsFocused] = useState(false)
    const timer = useRef(null)
    const selectedClass = classes.selected ?? '!bg-t_purple_500'

    const promiseOptions = (inputValue) => {
        clearTimeout(timer.current)
        return new Promise((resolve) => {
            timer.current = setTimeout(() => {
                resolve(getDataOptions(inputValue))
            }, 300)
        })
    }

    const handleChange = (e) => {
        closeMenuOnSelect && setIsFocused(false)
        onChange(e ?? '')
    }

    return (
        <div className={classNames('flex flex-col', label && 'space-y-1', classes.container)}>
            {label && (
                <label
                    className={classNames(
                        'text-t_purple_500 font-medium shadow-none',
                        isDisabled && '!text-gray-400',
                        classes.label
                    )}
                >
                    {t(label)}
                </label>
            )}
            <div
                className={classNames(
                    'relative border-2',
                    isFocused
                        ? 'border-t_purple_500_12 rounded-lg'
                        : 'border-transparent rounded-md'
                )}
            >
                {isLoading === name && (
                    <Loading isAbsolute classes={{ loading: '!border-t_purple_500' }} />
                )}
                <SelectComponent
                    loadOptions={promiseOptions}
                    value={value}
                    placeholder={t(placeholder)}
                    menuPlacement={menuPlacement}
                    isClearable={isClearable}
                    blurInputOnSelect={true}
                    classNames={{
                        control: () =>
                            classNames(
                                '!shadow-none !border-1 !rounded-md h-10',
                                classes.control,
                                error.message
                                    ? '!border-red-500'
                                    : isFocused
                                    ? '!border-t_purple_500'
                                    : '!border-t_gray_400'
                            ),
                        singleValue: () =>
                            classNames(
                                classes.singleValue,
                                isDisabled ? '!text-gray-400' : classes.colorTextNotDisabled
                            ),
                        option: (state) =>
                            classNames(state.isSelected ? selectedClass : '', classes.option),
                        input: () => classNames('!text-t_purple_500', classes.input),
                    }}
                    formatCreateLabel={(value) => `${t('select.create')} "${value}"`}
                    noOptionsMessage={() => t('select.noOptionsMessage')}
                    onChange={(e) => handleChange(e)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onCreateOption={onCreateOption}
                    isDisabled={isDisabled}
                    maxMenuHeight={maxMenuHeight}
                    closeMenuOnSelect={closeMenuOnSelect}
                />
            </div>

            {error.message && (
                <p className={classNames('text-red-500 mt-0.5 leading-5', classes.error)}>
                    {t(error.message, { field: t(error.field) })}
                </p>
            )}
        </div>
    )
}

export default memo(SelectOption)
