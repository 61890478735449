/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, Loading, NotificationItem, BookingForm, Modal, GuideForm } from '@Views'
import { useState, useMemo, useEffect, useRef } from 'react'
import { api } from '@Api'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default function StatisticsPage() {
    const { t } = useTranslation()
    const user = useSelector((state) => state.persist.user)

    const params = useSelector((state) => state.user.params)
    const screenHeight = useMemo(() => document.documentElement.clientHeight, [])
    const [loading, setLoading] = useState(false)
    const [dataList, setDataList] = useState([])
    const elementRef = useRef(null)
    const [isShowFormModal, setIsShowFormModal] = useState(false)
    const [currentItem, setCurrentItem] = useState({})

    const [isShowGuideModal, setIsShowGuideModal] = useState(false)
    const [currentGuide, setCurrentGuide] = useState({})

    useEffect(() => {
        handleGetData()
    }, [params])

    const handleGetData = async () => {
        setLoading(true)
        await getListNotify()
        setLoading(false)
    }

    const getListNotify = async () => {
        const res = await api.getNotify({
            ...params,
            limit: 5,
            agency: user.agency._id,
            recipientType: 'agency',
        })
        setDataList(res.notifys)
    }

    const handleAction = async ({ dataItem, type }) => {
        if (type === 'seeDetailGuide') {
            setCurrentGuide(dataItem.guideInfo || {})
            setIsShowGuideModal(true)
        } else {
            setCurrentItem(dataItem.bookingInfo || {})
            setIsShowFormModal(true)
        }
    }

    return (
        <>
            <div className={`bg-white w-full rounded-2xl`}>
                <p className="p-6 text-xl font-semibold">{t('notification.notifications')}</p>
                {loading && (
                    <div
                        style={{ height: screenHeight - 160 }}
                        className="flex justify-center items-center"
                    >
                        <Loading className="w-10 h-10 animate-spin text-center text-t_cyan_500 mt-10" />
                    </div>
                )}
                {!loading && !dataList.docs?.length && (
                    <div className="text-red-500 mt-10 text-center">
                        {t('validation.errorNotFound')}
                    </div>
                )}
                <div
                    ref={elementRef}
                    style={{ height: screenHeight - 160 }}
                    className="overflow-auto"
                >
                    {!loading && (
                        <>
                            <div className="mt-12">
                                {dataList.docs?.map((item, idx) => (
                                    <div key={idx} className="">
                                        <NotificationItem
                                            data={item}
                                            onAction={handleAction}
                                            onReload={() => handleGetData()}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div className="px-4">
                                {dataList.totalPages > 1 && (
                                    <Pagination
                                        itemPerPage={5}
                                        totalPages={dataList?.totalPages ?? 0}
                                        currentPage={dataList?.page ? dataList.page - 1 : 0}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>

                {isShowFormModal && (
                    <Modal
                        title={'Tour management'}
                        classes={{ content: '', children: '' }}
                        onCancel={() => setIsShowFormModal(false)}
                    >
                        <BookingForm
                            item={currentItem}
                            onCancel={() => setIsShowFormModal(false)}
                            onSuccess={() => {
                                setIsShowFormModal(false)
                                handleGetData()
                            }}
                            isSaveNow={true}
                        />
                    </Modal>
                )}
                {isShowGuideModal && (
                    <Modal
                        title={'Tour management'}
                        classes={{ content: '', children: '' }}
                        onCancel={() => setIsShowGuideModal(false)}
                    >
                        <GuideForm
                            item={currentGuide}
                            onCancel={() => setIsShowGuideModal(false)}
                            onSuccess={() => {
                                setIsShowGuideModal(false)
                                handleGetData()
                            }}
                            typeActionFormModal={'edit'}
                        />
                    </Modal>
                )}
            </div>
        </>
    )
}
