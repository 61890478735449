import React from 'react'
import ReactDOM from 'react-dom/client'
import '@Assets/css/main.css'
import reportWebVitals from './reportWebVitals'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import { GoogleOAuthProvider } from '@react-oauth/google'

import 'react-toastify/dist/ReactToastify.css'
import './i18n'

import router from '@Routes'
import { persistor, store } from '@Store'

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_ID
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Tooltip id="my-tooltip" className="z-10 max-w-lg" />
                    <ToastContainer />
                    <RouterProvider router={router} />
                </PersistGate>
            </Provider>
        </GoogleOAuthProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
