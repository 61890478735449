import { Outlet } from 'react-router-dom'
import { MainMenu } from '@Views'

export default function MainLayout() {
    return (
        <div className="w-screen sm:flex">
            <div className="sm:w-[18rem] bg-white sm:rounded-r-2xl relative">
                <MainMenu className="border-b-2 border-gray-500" />
            </div>
            <div className="w-full sm:max-w-t_screen_s18 max-h-t_screen_98px sm:max-h-screen overflow-y-auto">
                <div className="sm:px-7 sm:py-5">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
