import { createSlice } from '@reduxjs/toolkit'
import { removeNullValues } from '@Libs/hepler'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        params: {
            offset: 0,
            limit: 20,
        },
    },
    reducers: {
        setParams: (state, action) => {
            const payload = action.payload ?? {}
            if (!payload.offset) {
                Object.assign(payload, { offset: 0 })
            }
            state.params = Object.assign({}, removeNullValues({ ...state.params, ...payload }))
        },
        initParams: (state, action) => {
            state.params = Object.assign({ offset: 0, limit: 20 }, action.payload ?? {})
        },
    },
})

// Action creators are generated for each case reducer function
export const { initParams, setParams } = userSlice.actions

export default userSlice.reducer
