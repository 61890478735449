import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GoogleButton } from '../authentication/components/GoogleButton'
import { api } from '@Api'
import { useSelector } from 'react-redux'
import { toastError, toastSuccess } from '@Libs/toast'

export default function SyncGoogleCalendarPage() {
    const { t } = useTranslation()
    const [loadingGoogle, setLoadingGoogle] = useState(false)
    const user = useSelector((state) => state.persist.user)

    const handleGoogleSubmit = async (googleData) => {
        const googleTokenData = await api.getRefreshTokenGoogle({
            code: googleData.code,
            redirectUrl: window.location.origin,
        })
        handleSubmit({
            googleToken: {
                accessToken: googleTokenData.tokens?.access_token,
                refreshToken: googleTokenData.tokens?.refresh_token,
            },
        })
    }

    const handleSubmit = async (additionData = {}) => {
        try {
            const isGoogleLoading = !!additionData?.googleToken
            isGoogleLoading && setLoadingGoogle(true)
            await api.updateUser(user._id, {
                ...additionData,
            })
            toastSuccess(t('syncGoogle.editSuccess'))
        } catch (err) {
            toastError(err)
        } finally {
            setLoadingGoogle(false)
        }
    }

    return (
        <div className="flex justify-center items-center max-h-t_screen_98px h-svh">
            <div className="bg-white border rounded-2xl px-4 py-8">
                <div className="mt-12 mb-7">
                    <div className="text-2xl mt-5 font-semibold">{t('syncGoogle.title')}</div>
                    <p className="text-t_gray_500 mt-2 mb-5">{t('syncGoogle.description')}</p>
                </div>
                <GoogleButton
                    onSuccess={handleGoogleSubmit}
                    loading={loadingGoogle}
                    scope="https://www.googleapis.com/auth/calendar.readonly"
                    flow="code"
                />
            </div>
        </div>
    )
}
