import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BackButton } from './components/BackButton'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormInput, Icon } from '@Views'
import { validEmail } from '@Libs/hepler'
import { setSignupData } from '@Store/persisted/userSlice'

export default function InviteGuidePage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const signupData = useSelector((state) => state.persist.signupData)
    const [email, setEmail] = useState('')
    const [inviteEmails, setInviteEmails] = useState(signupData.inviteEmails ?? [])
    const [error, setError] = useState('')

    useEffect(() => {
        if (!signupData.provider?.domain) navigate('/signup/booking-provider')
    }, [signupData, navigate])

    const handleBack = () => {
        navigate('/signup/booking-provider')
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = async () => {
        dispatch(setSignupData({ inviteEmails }))
        navigate('/signup/complete')
    }

    const handleInvite = () => {
        if (checkValidEmail()) {
            const listEmail = email.split(',')?.map((item) => item.trim())
            setInviteEmails([...new Set(inviteEmails.concat(listEmail))])
            setEmail('')
        }
    }

    const checkValidEmail = () => {
        const listEmail = email.split(',')
        setError(null)
        for (let item of listEmail) {
            if (!validEmail(item)) {
                setError(t('inviteGuides.invalidEmail', { email: item }))
                return false
            }
        }
        return true
    }

    const handleRemoveEmail = (email) => {
        setInviteEmails(inviteEmails.filter((item) => item !== email))
    }

    return (
        <>
            <BackButton onClick={handleBack} />
            <div className="mt-12 mb-7">
                <div className="text-2xl mt-5 font-semibold">{t('inviteGuides.title')}</div>
                <div className="relative">
                    <FormInput
                        classes={{ label: 'mt-3', input: 'pr-20' }}
                        name="email"
                        value={email}
                        placeholder={'inviteGuides.email'}
                        error={{ message: error }}
                        handleChange={(_, value) => setEmail(value)}
                        handleKeyDown={handleKeyDown}
                    />
                    <button
                        onClick={handleInvite}
                        className="px-3 py-1 border border-t_purple_500 rounded-md text-t_purple_500 absolute top-1.5 right-2 hover:text-white hover:bg-t_purple_500"
                    >
                        {t('inviteGuides.invite')}
                    </button>
                </div>
                {inviteEmails.length > 0 && (
                    <div className="mt-5">
                        {t('inviteGuides.willInvite')}
                        <div className="flex flex-wrap gap-x-3 gap-y-2 mt-2">
                            {inviteEmails.map((item, index) => (
                                <div
                                    key={index}
                                    className="flex items-center gap-2 py-1 px-3 bg-t_pale_200 text-t_pale_600 w-fit rounded-md"
                                >
                                    {item}
                                    <Icon
                                        name={'cross'}
                                        classes={'w-2.5 h-2.5 text-t_pale_500 cursor-pointer'}
                                        onClick={() => handleRemoveEmail(item)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <Button
                    text={'button.continue'}
                    onClick={handleSubmit}
                    classes={{ button: 'mt-5 px-8 w-full font-medium' }}
                />
            </div>
        </>
    )
}
