import { useState, useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    FormInput,
    Line,
    SelectInput,
    SelectOption,
    Loading,
    FormButton,
    UploadAvatar,
} from '@Views'
import { validateForm } from '@Libs/validation'
import { guideInformationValidation } from '@Constants'
import { phonePrefix } from '@Constants/phonePrefix'
import { languageOptions } from '@Constants'
import { parseSelectOptionData, getS3Link } from '@Libs/hepler'
import { api } from '@Api'
import { toastSuccess, toastError } from '@Libs/toast'

const GuideForm = ({ item, onCancel, typeActionFormModal, onSuccess }) => {
    const { t } = useTranslation()
    const user = useSelector((state) => state.persist.user)
    const [formData, setFormData] = useState({})
    const [errors, setErrors] = useState({})
    const [phoneOptions, setPhoneOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [favoriteTourOptions, setFavoriteTourOptions] = useState([])
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        const phoneNum = (item.phone || '').split(' ') || []
        setFormData({
            firstname: item.firstname ?? '',
            lastname: item.lastname ?? '',
            phone: phoneNum[1] ? phoneNum[1] : '',
            phonePrefix: phoneNum[0] ? phoneNum[0] : '+33',
            city: item.city ?? '',
            favoriteTours: item.favoriteTours ?? '',
            language: item.language ?? '',
            email: item.email ?? '',
            priority: item.priority ?? '',
            avatar: item.avatar ?? '',
        })
    }, [item])

    useEffect(() => {
        getAgencyTours()
        setPhoneOptions(() =>
            phonePrefix.map((item) => ({
                value: item.value,
                label: `${item.label} (${item.value})`,
            }))
        )
    }, [])

    const getAgencyTours = async () => {
        setLoading(true)
        const result = await api.getActivitiesBokun({ agencyId: user.agency?._id })
        setLoading(false)
        setFavoriteTourOptions(
            parseSelectOptionData({
                data: result.activity?.items ?? [],
                label: 'title',
                value: 'title',
            })
        )
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handelSave()
        }
    }

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handelSave = async () => {
        const errorObj = validateForm(guideInformationValidation, formData)
        setErrors(errorObj)
        if (!Object.keys(errorObj).length) {
            setSaveLoading(true)
            try {
                let { avatar, phonePrefix, phone, ...data } = formData
                if (typeof avatar !== 'string' && !!avatar) {
                    const file = new FormData()
                    file.append('file', avatar)
                    file.append('fileName', `guide_avatar`)
                    const image = await api.uploadFile(file)
                    avatar = image.file
                }
                if (typeActionFormModal === 'edit') {
                    await api.updateUser(item._id, {
                        ...data,
                        avatar,
                        phone: `${phonePrefix} ${phone}`,
                        priority: (data.priority || '').toString(),
                    })
                } else {
                    await api.createGuideByAgency({
                        ...data,
                        avatar,
                        phone: `${phonePrefix} ${phone}`,
                        role: 'guide',
                        isActived: false,
                        agency: [user.agency?._id],
                        priority: (data.priority || '').toString(),
                    })
                    toastSuccess(
                        <div>
                            <div className="font-bold text-base">{t('guide.guideAdded')}</div>
                            <div className="text-sm">{t('guide.emailSent')}</div>
                        </div>
                    )
                }
                onSuccess()
            } catch (error) {
                toastError(error)
            } finally {
                setSaveLoading(false)
            }
        }
    }

    return (
        <div className=" flex justify-center">
            <div className="mb-6 w-[520px] space-y-3">
                <div className="text-2xl my-2 font-semibold">{t('information.title')}</div>
                <UploadAvatar
                    data={
                        formData.avatar && formData.avatar instanceof File
                            ? URL.createObjectURL(formData.avatar)
                            : formData.avatar
                            ? `${getS3Link(formData.avatar)}`
                            : null
                    }
                    onUploaded={(file) => handleChange('avatar', file)}
                />
                <div className="flex justify-between gap-3">
                    <div>
                        <FormInput
                            name="firstname"
                            value={formData.firstname}
                            placeholder={'placeholder.firstname'}
                            error={{ message: errors?.firstname, field: 'placeholder.firstname' }}
                            handleChange={handleChange}
                            handleKeyDown={handleKeyDown}
                        />
                    </div>
                    <div>
                        <FormInput
                            name="lastname"
                            value={formData.lastname}
                            placeholder={'placeholder.lastname'}
                            error={{ message: errors?.lastname, field: 'placeholder.lastname' }}
                            handleChange={handleChange}
                            handleKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                <FormInput
                    name="email"
                    value={formData.email}
                    placeholder={'placeholder.email'}
                    error={{ message: errors?.email, field: 'placeholder.email' }}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                />
                <SelectInput
                    classes={{ label: 'mt-3' }}
                    name="phone"
                    value={formData.phone}
                    options={phoneOptions}
                    valueSelected={formData.phonePrefix}
                    isShowValueSelected
                    onSelect={(value) => handleChange('phonePrefix', value)}
                    placeholder={'placeholder.phone'}
                    error={{ message: errors?.phone, field: 'placeholder.phone' }}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                />
                <Line classes="my-5" />
                <FormInput
                    name="priority"
                    value={formData.priority}
                    placeholder={'placeholder.priority'}
                    error={{ message: errors?.priority, field: 'placeholder.priority' }}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                />
                <FormInput
                    name="city"
                    value={formData.city}
                    placeholder={'placeholder.city'}
                    error={{ message: errors?.city, field: 'placeholder.city' }}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                />
                <div className="relative">
                    {loading && <Loading isAbsolute />}
                    <SelectOption
                        classes={{ container: 'mt-3' }}
                        options={favoriteTourOptions}
                        placeholder={'placeholder.favoriteTours'}
                        isMulti
                        value={formData.favoriteTours}
                        onChange={(value) => handleChange('favoriteTours', value)}
                        error={{
                            message: errors?.favoriteTours,
                            field: 'placeholder.favoriteTours',
                        }}
                    />
                </div>
                <SelectOption
                    classes={{ container: 'mt-3' }}
                    options={languageOptions}
                    placeholder={'placeholder.language'}
                    isMulti
                    value={formData.language}
                    onChange={(value) => handleChange('language', value)}
                    error={{ message: errors?.language, field: 'placeholder.language' }}
                />

                <div className="py-6 flex justify-center">
                    <FormButton
                        onSave={handelSave}
                        onCancel={() => {
                            onCancel()
                        }}
                        loading={saveLoading}
                        text={{}}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(GuideForm)
