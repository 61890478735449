import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { BackButton } from './components/BackButton'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormInput } from '@Views'
import { setSignupData } from '@Store/persisted/userSlice'
import { Eye, EyeOff } from 'react-feather'
import { validateForm } from '@Libs/validation'
import { setPasswordValidation } from '@Constants'

export default function SetPasswordPage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const agencyToken = new URLSearchParams(useLocation().search).get('token')
    const nextUrl = agencyToken
        ? `/guide/signup/information?token=${agencyToken}`
        : '/signup/information'
    const backUrl = agencyToken
        ? `/guide/signup/verify-code?token=${agencyToken}`
        : '/signup/verify-code'
    const signupData = useSelector((state) => state.persist.signupData)
    const [password, setPassword] = useState(signupData?.password ?? '')
    const [errors, setErrors] = useState({})
    const [isShowPassword, setIsShowPassword] = useState(false)

    useEffect(() => {
        if (signupData.agencyToken && signupData.agencyToken !== agencyToken) {
            navigate(`/guide/signup/verify-code?token=${signupData.agencyToken}`)
        }
    }, [agencyToken, signupData])

    useEffect(() => {
        if (!signupData.verifyToken) navigate(backUrl)
    }, [signupData, navigate])

    const handleBack = () => {
        navigate(backUrl)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleIconClick = () => setIsShowPassword(!isShowPassword)

    const handleSubmit = async () => {
        const errorObj = validateForm(setPasswordValidation, { password })
        setErrors(errorObj)
        if (!Object.keys(errorObj).length) {
            dispatch(setSignupData({ password }))
            navigate(nextUrl)
        }
    }

    return (
        <>
            <BackButton onClick={handleBack} />
            <div className="mt-14 mb-6">
                <div className="text-2xl mt-5 font-semibold">{t('setPassword.title')}</div>
                <p className="text-t_gray_500 mt-2 mb-5">{t('setPassword.description')}</p>
                <FormInput
                    classes={{ label: 'mt-3' }}
                    type={isShowPassword ? 'text' : 'password'}
                    name="password"
                    value={password}
                    placeholder={'placeholder.password'}
                    error={{ showWithoutMessage: errors?.password }}
                    icon={isShowPassword ? EyeOff : Eye}
                    handleChange={(_, value) => setPassword(value)}
                    handleIconClick={handleIconClick}
                    handleKeyDown={handleKeyDown}
                />
                <Button
                    text={'button.continue'}
                    onClick={handleSubmit}
                    classes={{ button: 'mt-5 px-8 w-full font-medium' }}
                />
            </div>
        </>
    )
}
