import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BackButton } from './components/BackButton'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormInput, Line, SelectInput, UploadAvatar } from '@Views'
import { setSignupData } from '@Store/persisted/userSlice'
import { validateForm } from '@Libs/validation'
import { signupInformationValidation } from '@Constants'
import { phonePrefix } from '@Constants/phonePrefix'
import { api } from '@Api'

export default function InformationPage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const signupData = useSelector((state) => state.persist.signupData)
    const isGoogleAccount = signupData?.source === 'google'
    const backUrl = isGoogleAccount ? `/signup` : '/signup/set-password'
    const [formData, setFormData] = useState({
        firstname: signupData.firstname ?? '',
        lastname: signupData.lastname ?? '',
        phone: signupData.phone ?? '',
        phonePrefix: signupData.phonePrefix ?? '+33',
        agencyName: signupData.agency?.name ?? '',
        agencyLogo: signupData.agency?.logo ?? null,
    })
    const [errors, setErrors] = useState({})
    const [phoneOptions, setPhoneOptions] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if ((isGoogleAccount && !signupData.email) || (!isGoogleAccount && !signupData.password))
            navigate(backUrl)
    }, [signupData, navigate])

    useEffect(() => {
        setPhoneOptions(() =>
            phonePrefix.map((item) => ({
                value: item.value,
                label: `${item.label} (${item.value})`,
            }))
        )
    }, [])

    const handleBack = () => {
        navigate(backUrl)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSubmit = async () => {
        const errorObj = validateForm(signupInformationValidation, formData)
        setErrors(errorObj)
        if (!Object.keys(errorObj).length) {
            try {
                setLoading(true)
                const { agencyName, agencyLogo, ...data } = formData
                await api.checkAgencyName({ name: agencyName })
                dispatch(setSignupData({ ...data, agency: { name: agencyName, logo: agencyLogo } }))
                navigate('/signup/booking-provider')
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <>
            <BackButton onClick={handleBack} />
            <div className="mt-14 mb-6">
                <div className="text-2xl my-5 font-semibold">{t('information.title')}</div>
                <div className="flex justify-between gap-3 mb-3">
                    <div>
                        <FormInput
                            name="firstname"
                            value={formData.firstname}
                            placeholder={'placeholder.firstname'}
                            error={{ message: errors?.firstname, field: 'placeholder.firstname' }}
                            handleChange={handleChange}
                            handleKeyDown={handleKeyDown}
                        />
                    </div>
                    <div>
                        <FormInput
                            name="lastname"
                            value={formData.lastname}
                            placeholder={'placeholder.lastname'}
                            error={{ message: errors?.lastname, field: 'placeholder.lastname' }}
                            handleChange={handleChange}
                            handleKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                <SelectInput
                    name="phone"
                    value={formData.phone}
                    options={phoneOptions}
                    valueSelected={formData.phonePrefix}
                    isShowValueSelected
                    onSelect={(value) => handleChange('phonePrefix', value)}
                    placeholder={'placeholder.phone'}
                    error={{ message: errors?.phone, field: 'placeholder.phone' }}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                />
                <Line classes="my-5" />
                {/* agency information */}
                <div className="flex items-center gap-3">
                    <UploadAvatar
                        data={
                            formData.agencyLogo && formData.agencyLogo instanceof File
                                ? URL.createObjectURL(formData.agencyLogo)
                                : null
                        }
                        onUploaded={(file) => handleChange('agencyLogo', file)}
                    />
                    <div className="w-full">
                        <FormInput
                            name="agencyName"
                            value={formData.agencyName}
                            placeholder={'placeholder.agencyName'}
                            error={{ message: errors?.agencyName, field: 'placeholder.agencyName' }}
                            handleChange={handleChange}
                            handleKeyDown={handleKeyDown}
                        />
                    </div>
                </div>

                <Button
                    text={'button.continue'}
                    loading={loading}
                    onClick={handleSubmit}
                    classes={{ button: 'mt-5 px-8 w-full font-medium' }}
                />
            </div>
        </>
    )
}
