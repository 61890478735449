/* eslint-disable jsx-a11y/alt-text */
import { memo } from 'react'
import { Upload } from 'react-feather'
import classNames from 'classnames'

const FileUpload = ({
    label,
    content,
    isDisabled = false,
    classes = {},
    isMultiple = true,
    accept,
    onUploadData,
    isShowIcon = true,
}) => {
    const isShowLabel = label?.length > 0

    return (
        <label
            className={classNames('relative flex flex-col space-y-1 cursor-pointer', classes.label)}
        >
            {isShowLabel && (
                <span className={classNames(isDisabled && 'text-gray-400', classes.span)}>
                    {label}
                </span>
            )}

            <div
                className={classNames(
                    'flex justify-center items-center border border-t_blue_500 h-12 outline-none rounded-md p-2',
                    classes.button
                )}
            >
                <p className={classes.content}>{content}</p>
                <input
                    className="w-0 h-0"
                    type="file"
                    accept={accept}
                    multiple={isMultiple}
                    onChange={(e) => {
                        onUploadData(e)
                    }}
                />

                {isShowIcon && (
                    <Upload
                        className={classNames('ml-2 w-[1.125rem] h-[1.125rem]', classes.icon)}
                    />
                )}
            </div>
        </label>
    )
}
export default memo(FileUpload)
