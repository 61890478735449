import { useTranslation } from 'react-i18next'
import { ChevronLeft } from 'react-feather'

export function BackButton({ onClick }) {
    const { t } = useTranslation()
    return (
        <button className="flex items-center gap-1 -mt-6 -ml-9" onClick={onClick}>
            <ChevronLeft className="w-4 -mb-0.5" />
            {t('signup.back')}
        </button>
    )
}
