import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { listMenuAgence, listMenuGuide } from '@Constants'
import classNames from 'classnames'
import { NavLink, useNavigate } from 'react-router-dom'
import { Icon } from '@Views'
import { LogOut } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { setUser, setToken } from '@Store/persisted/userSlice'
import { getS3Link } from '@Libs/hepler'
import { agencyLogoImg, userImg, logoTourAssistImg } from '@Assets/images'
import useClickOutside from 'react-cool-onclickoutside'
import { api } from '@Api'

export default function MainMenu() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.persist.user)
    const [menuData, setMenuData] = useState({})
    const [isShowMobileMenu, setIsShowMobileMenu] = useState(false)
    const [notifiNum, setNotifiNum] = useState(0)

    useEffect(() => {
        const data =
            user.role === 'guide'
                ? {
                      name: `${user.firstname} ${user.lastname}`.trim(),
                      role: t('menu.tourGuide'),
                      avatar: user.avatar ? getS3Link(user.avatar) : userImg,
                      menu: listMenuGuide,
                  }
                : {
                      name: user.agency?.name,
                      role: t('menu.agence'),
                      avatar: user.agency?.logo ? getS3Link(user.agency?.logo) : agencyLogoImg,
                      menu: listMenuAgence,
                  }
        setMenuData(data)
        getUnreadNotifications()
    }, [user])

    const handleLogout = () => {
        dispatch(setToken(null))
        dispatch(setUser(null))
        localStorage.removeItem('jwt')
        navigate('/signin')
    }

    const mobileMenu = useClickOutside(() => {
        setIsShowMobileMenu(false)
    })

    const getUnreadNotifications = async () => {
        let params = { seen: false }
        if (user.role === 'guide') {
            Object.assign(params, { guide: user._id, recipientType: 'guide' })
        } else {
            Object.assign(params, { agency: user.agency._id, recipientType: 'agency' })
        }
        const data = await api.getNotify({ ...params })
        setNotifiNum(data?.notifys?.totalDocs || 0)
        setTimeout(() => getUnreadNotifications(), 10000)
    }

    const renderMenu = () => {
        return menuData.menu?.map((item, idx) => (
            <NavLink
                key={idx}
                to={item.route}
                onClick={(e) => {
                    e.stopPropagation()
                    setIsShowMobileMenu(!isShowMobileMenu)
                }}
            >
                {({ isActive }) => (
                    <div
                        className={classNames(
                            'flex items-center py-3 space-x-4 font-medium',
                            isActive && 'text-t_blue_500',
                            item.class
                        )}
                    >
                        {item.icon && (
                            <Icon
                                name={item.icon}
                                classes={classNames('w-6 h-6 text-t_blue_500', item.iconClass)}
                            />
                        )}

                        <span className="w-fit flex items-center leading-6 text-sm font-semibold">
                            {t(item.label)}
                        </span>
                        {item.route.includes('notifications') && (
                            <div className="h-5 min-w-5 bg-red-600 rounded-full text-white text-sm px-2">
                                {notifiNum}
                            </div>
                        )}
                    </div>
                )}
            </NavLink>
        ))
    }

    return (
        <div
            className="sm:h-screen overflow-auto flex flex-col justify-between py-5 px-6"
            ref={mobileMenu}
        >
            <div>
                <div className="sm:py-4 border-b border-t_gray_200 flex justify-between items-center">
                    <NavLink key={'hireYourGuide'} to={'/agence/hire-your-guide'}>
                        {({ isActive }) => (
                            <div
                                className={classNames(
                                    'flex items-center py-3 space-x-4 font-medium relative',
                                    isActive && 'text-t_blue_500'
                                )}
                            >
                                <img src={logoTourAssistImg} className="h-9" alt={'logo'} />
                            </div>
                        )}
                    </NavLink>
                    <Icon
                        name={'menuOpen'}
                        classes={classNames('w-6 h-6')}
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsShowMobileMenu(!isShowMobileMenu)
                        }}
                    />
                </div>
                <div className="items-center py-7 border-b border-t_gray_200 space-x-4 hidden sm:flex">
                    <div className="p-[1px] border-2 rounded-full border-t_gray_200">
                        <img
                            src={menuData.avatar}
                            className="w-10 h-10 rounded-full"
                            alt={'avatar'}
                        />
                    </div>
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            if (user.role === 'guide') {
                                navigate('/guide/sync-google-calendar')
                            }
                        }}
                    >
                        <div className="text-xs font-bold">{menuData.name}</div>
                        <div className="font-bold text-t_gray_300 text-[11px]">{menuData.role}</div>
                    </div>
                </div>
                <div className="border-b border-t_gray_200 hidden sm:block">{renderMenu()}</div>
            </div>
            <div
                className="text-center font-medium cursor-pointer text-base items-center mt-5 hidden sm:flex"
                onClick={handleLogout}
            >
                <LogOut className="w-5 mr-2" />
                {t('menu.logout')}
            </div>

            {isShowMobileMenu && (
                <div className="sm:hidden absolute bg-white z-50 w-full border top-[97px] left-[0px] py-4 px-6">
                    <div className="items-center py-7 border-b border-t_gray_200 space-x-4 flex">
                        <div className="p-[1px] border-2 rounded-full border-t_gray_200">
                            <img
                                src={menuData.avatar}
                                className="w-10 h-10 rounded-full"
                                alt={'avatar'}
                            />
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation()
                                if (user.role === 'guide') {
                                    setIsShowMobileMenu(!isShowMobileMenu)
                                    navigate('/guide/sync-google-calendar')
                                }
                            }}
                        >
                            <div className="text-xs font-bold">{menuData.name}</div>
                            <div className="font-bold text-t_gray_300 text-[11px]">
                                {menuData.role}
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-t_gray_200">{renderMenu()}</div>
                    <div
                        className="text-center font-medium cursor-pointer text-base items-center mt-5 flex"
                        onClick={handleLogout}
                    >
                        <LogOut className="w-5 mr-2" />
                        {t('menu.logout')}
                    </div>
                </div>
            )}
        </div>
    )
}
