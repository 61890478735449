import { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setSignupData, initSignupData } from '@Store/persisted/userSlice'
import { FormInput, Button, CheckBox, Line } from '@Views'
import { GoogleButton } from '../components/GoogleButton'
import { api } from '@Api'
import { validateForm } from '@Libs/validation'
import { signupValidation } from '@Constants'
import { useTranslation } from 'react-i18next'
import { getS3Link } from '@Libs/hepler'
import { agencyLogoImg, logoTourAssistImg } from '@Assets/images'

export default function SignupPage() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const agencyToken = new URLSearchParams(useLocation().search).get('token')
    const nextUrl = agencyToken
        ? `/guide/signup/verify-code?token=${agencyToken}`
        : '/signup/verify-code'
    const nextGoogleUrl = agencyToken
        ? `/guide/signup/information?token=${agencyToken}`
        : '/signup/information'
    const signupData = useSelector((state) => state.persist.signupData)

    const [formData, setFormData] = useState({ email: signupData?.email ?? '' })
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingGoogle, setLoadingGoogle] = useState(false)
    const [agencyData, setAgencyData] = useState(null)

    useEffect(() => {
        if (agencyToken) {
            const checkInviteToken = async () => {
                const data = await api.checkInviteToken({ token: agencyToken })
                if (data.invalidToken) {
                    navigate('/signin')
                    setTimeout(() => dispatch(initSignupData({})), 100)
                }
                setAgencyData(data.agency)
                dispatch(setSignupData({ agencyId: data.agency?._id }))
            }
            checkInviteToken()
        }
    }, [agencyToken])

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = async () => {
        try {
            const errorObj = validateForm(signupValidation, formData)
            setErrors(errorObj)
            if (!Object.keys(errorObj).length) {
                setLoading(true)
                const data = await api.createCode({ email: formData.email })
                dispatch(
                    setSignupData({
                        email: formData.email,
                        verifyToken: data.token,
                        agencyToken,
                        source: 'local',
                    })
                )
                navigate(nextUrl)
            }
        } finally {
            setLoading(false)
        }
    }

    const handleGoogleSubmit = async (googleData) => {
        try {
            setLoadingGoogle(true)
            setErrors({})
            const data = await api.authenticationGoogleRegister({
                token: googleData.accessToken,
            })
            dispatch(
                setSignupData({
                    email: data.user?.email,
                    firstname: data.user?.given_name,
                    lastname: data.user?.family_name,
                    verifyToken: data.token,
                    agencyToken,
                    source: 'google',
                })
            )
            navigate(nextGoogleUrl)
        } finally {
            setLoadingGoogle(false)
        }
    }

    return (
        <>
            {agencyToken ? (
                <div className="flex items-center justify-center gap-2">
                    <img
                        src={agencyData?.logo ? getS3Link(agencyData?.logo) : agencyLogoImg}
                        className="w-5 h-5 rounded-full"
                        alt="agency logo"
                    />
                    <div className="text-t_black_500 text-lg">
                        {t('signup.agencyInviteTitle', { agencyName: agencyData?.name })}
                    </div>
                </div>
            ) : (
                <div className="flex items-center justify-center gap-2">
                    <img src={logoTourAssistImg} className="h-12" alt={'logo'} />
                </div>
            )}

            <div className="text-center mt-3 mb-5 text-lg font-semibold">{t('signup.title')}</div>
            <FormInput
                name="email"
                value={formData.email}
                placeholder={'placeholder.email'}
                error={{ message: errors?.email, field: 'placeholder.email' }}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
            />
            <>
                <div className="flex items-center gap-0.5 mt-3">
                    <CheckBox
                        classes={{
                            label: 'w-fit',
                            checkbox: 'w-3.5 h-3.5 min-w-[0.875rem]',
                        }}
                        isChecked={formData.term}
                        onChange={(value) => handleChange('term', value)}
                    />
                    <p
                        className="text-t_gray_500 text-xs"
                        dangerouslySetInnerHTML={{ __html: t('signup.agreeTerm') }}
                        onClick={() => handleChange('term', !formData.term)}
                    ></p>
                </div>
                {errors?.term && <p className="text-red-500 mt-0.5 leading-5">{t(errors?.term)}</p>}
            </>

            <div className="flex justify-center">
                <Button
                    text={'signup.createAccount'}
                    loading={loading}
                    onClick={handleSubmit}
                    classes={{ button: 'mt-4 px-8 w-full font-medium' }}
                />
            </div>
            <div className="text-center my-5">
                {t('signup.alreadyHaveAccount')}
                <Link to="/signin" className="text-t_purple_500 hover:underline ml-2">
                    {t('signup.signin')}
                </Link>
            </div>
            <div className="mb-4">
                <Line />
                <p className="bg-white px-2 -mt-3 text-center w-fit mx-auto text-t_gray_500">
                    {t('login.or')}
                </p>
            </div>
            <GoogleButton onSuccess={handleGoogleSubmit} loading={loadingGoogle} />
        </>
    )
}
