import { useTranslation } from 'react-i18next'
import { googleImg } from '@Assets/images'
import classNames from 'classnames'
import { RefreshCw } from 'react-feather'
import { useGoogleLogin } from '@react-oauth/google'

export function GoogleButton({ onSuccess, loading, scope = '', flow = 'implicit' }) {
    const { t } = useTranslation()

    const onSuccessAuth = (res) => {
        flow === 'code'
            ? onSuccess({ code: res.code })
            : onSuccess({ accessToken: res.access_token })
    }

    const onFailureAuth = (res) => {
        console.log('Login failed:', res)
    }

    const login = useGoogleLogin({
        onSuccess: onSuccessAuth,
        onError: onFailureAuth,
        flow: flow,
        scope: scope,
    })

    return (
        <button
            className={classNames(
                'font-medium relative w-full flex justify-center items-center gap-x-2 border border-t_gray_400 rounded-md h-11',
                { 'opacity-50 cursor-not-allowed': loading }
            )}
            onClick={() => login()}
        >
            <img
                src={googleImg}
                alt="google"
                className="absolute top-1/2 -translate-y-1/2 left-2"
            />
            {t('login.continueWithGoogle')}
            {loading && <RefreshCw className="animate-spin ml-2 w-4 h-4" />}
        </button>
    )
}
