import { useTranslation } from 'react-i18next'
import { memo, useState, useEffect } from 'react'
import classNames from 'classnames'
import { Icon, Modal, SelectOption, Loading, Button } from '@Views'
import { formatInTimeZone } from 'date-fns-tz'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { getS3Link } from '@Libs/hepler'
import { useSelector, useDispatch } from 'react-redux'
import { setParams } from '@Store/userSlice'
import { statusOptions } from '@Constants'

const Calendar = ({
    listData = '',
    onReload,
    modal = {},
    guideList = [],
    isGuideList,
    allGuideList = [],
    loading = false,
    visitList = [],
    cityList = [],
    onActionConfirm,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const filterParams = useSelector((state) => state.user.params)
    const user = useSelector((state) => state.persist.user)
    const [isShowFormModal, setIsShowFormModal] = useState(false)
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)
    const [actionType, setActionType] = useState('')
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [guides, setGuides] = useState(guideList)
    const FormComponent = modal?.form
    const [firstDay, setFirstDay] = useState('')
    const [columns, setColumns] = useState([
        {
            name: 'period',
        },
        {
            name: 'monday',
            title: t('calendar.monday'),
        },
        {
            name: 'tuesday',
            title: t('calendar.tuesday'),
        },
        {
            name: 'wednesday',
            title: t('calendar.wednesday'),
        },
        {
            name: 'thursday',
            title: t('calendar.thursday'),
        },
        {
            name: 'friday',
            title: t('calendar.friday'),
        },
        {
            name: 'saturday',
            title: t('calendar.saturday'),
        },
        {
            name: 'sunday',
            title: t('calendar.sunday'),
        },
    ])

    useEffect(() => {
        checkMonday()
    }, [])

    useEffect(() => {
        setDateColumns()
    }, [firstDay, listData])

    useEffect(() => {
        if (isGuideList) {
            setGuides(guideList)
        } else {
            setGuides([{ isGetAll: true }])
        }
    }, [isGuideList, guideList])

    const checkMonday = () => {
        const currentDate = firstDay ? new Date(firstDay) : new Date()
        const currentDay = currentDate.getDay()
        const monday = currentDate.setDate(currentDate.getDate() - (currentDay - 1))
        const dateStart = new Date(new Date(monday).setUTCHours(0, 0, 0))
        onReload({
            startDate: dateStart,
            visitType: filterParams.visitType || '',
            guide: filterParams.guide || '',
            status: filterParams.status || '',
        })
        setFirstDay(`${dateStart}`)
    }

    const setDateColumns = () => {
        const dateOfColumn = columns?.map((column, idx) => {
            let el = { ...column }
            if (column.name !== 'period') {
                const dateMonday = new Date(firstDay)
                const columnDate = dateMonday.setDate(dateMonday.getDate() + (idx - 1))
                const startTime = new Date(new Date(columnDate).setUTCHours(0, 0, 0))
                const endTime = new Date(new Date(columnDate).setUTCHours(23, 59, 59))
                el = {
                    ...el,
                    startTime: startTime,
                    endTime: endTime,
                    listBooking: listData.filter(
                        (booking) =>
                            new Date(booking.date).toISOString() >= startTime.toISOString() &&
                            new Date(booking.date).toISOString() <= endTime.toISOString()
                    ),
                }
            }
            return { ...el }
        })
        setColumns(dateOfColumn)
    }

    const getDateColumn = (column) => {
        const columnDate = new Date(column?.startTime)
        return new Date(columnDate).getDate().toString().padStart(2, '0')
    }

    const changeWeek = (isNext = true) => {
        const extraDay = isNext ? 7 : -7
        const monday = new Date(firstDay)
        const newMonday = monday.setDate(monday.getDate() + extraDay)
        const dateStart = new Date(new Date(newMonday).setUTCHours(0, 0, 0))
        onReload({
            startDate: dateStart,
            visitType: filterParams.visitType || '',
            guide: filterParams.guide || '',
            status: filterParams.status || '',
        })
        setFirstDay(`${dateStart}`)
    }

    const clickItem = (booking) => {
        setCurrentItem(booking)
        setIsShowFormModal(true)
    }

    const handleActionClick = async ({ type, data }) => {
        setIsShowFormModal(false)
        setCurrentItem(data)
        setActionType(type)
        if (type === 'edit') {
            await onActionConfirm({ currentItem: data, actionType: type })
        } else setIsShowConfirmModal(true)
    }

    const handleConfirm = async () => {
        try {
            setConfirmLoading(true)
            await onActionConfirm({ currentItem, actionType })
        } finally {
            setIsShowConfirmModal(false)
            setConfirmLoading(false)
        }
    }

    const bookingInfo = (booking) => {
        const duration = booking.duration || 0
        const startDate = new Date(booking.date)
        let endDate = new Date(
            new Date(booking.date).setMinutes(new Date(booking.date).getMinutes() + duration)
        )
        let itemCss = 'bg-[#ffecec] border-t_pink_200 text-t_pink_200'
        if (booking.status === 'CANCELLED') {
            itemCss = 'bg-t_gray_250 border-t_black_600 text-t_black_600'
        } else if (booking.status === 'PENDING') {
            itemCss = 'bg-t_yellow_100 border-t_yellow_300 text-t_yellow_300'
        } else if (booking.status === 'STAFFED') {
            itemCss = 'bg-t_green_100 border-t_green_300 text-t_green_300'
        }
        const agency = (user.agencies || []).find((el) => el._id === booking.agency) || {}
        return (
            <div className="flex space-x-1 cursor-pointer">
                {booking.isChanging && (
                    <div className="w-[10px] h-[10px] m-0 rounded-full bg-t_red_500"></div>
                )}
                <div
                    className={classNames(
                        'w-fit p-1 rounded-sm border-l-4 space-y-0.5',
                        itemCss,
                        !booking.isChanging && '!w-full'
                    )}
                    onClick={() => clickItem(booking)}
                >
                    {booking.date && (
                        <div className="text-xs font-semibold">{`${formatInTimeZone(
                            startDate,
                            'UTC',
                            'HH:mm'
                        )} - ${formatInTimeZone(endDate, 'UTC', 'HH:mm')}`}</div>
                    )}
                    <div className="text-xs font-semibold two-line-ellipsis">{`${booking.visitType}`}</div>
                    {user.role !== 'guide' && (
                        <div className="text-[10px] font-light text-t_gray_40">
                            {`${(booking.language || 'EN').toUpperCase()} - ${booking.option}`}
                        </div>
                    )}
                    {user.role === 'guide' && (
                        <div className="flex items-center space-x-2">
                            <img
                                src={
                                    agency.logo
                                        ? getS3Link(agency.logo)
                                        : 'https://lh3.googleusercontent.com/proxy/ttFZIprg1NiaBXg-Aft4aWibNaZA7ERkqtwc5Pi63Wy3qF850fcXTxf9FBCss3N0zLZA0GfSfzUKv2WPPvLF65PJMLfYyEcCweFVCUU'
                                }
                                className="w-5 h-5 rounded-full"
                                alt={'avatar'}
                            />
                            <p className="text-xs">{`${agency.name}`}</p>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const bookingColumn = (column, guide, isGetAll) => {
        let listBooking = [...(column?.listBooking || [])]

        if (!guide && !isGetAll) {
            listBooking = listBooking.filter(
                (el) =>
                    !el.guide || (el.guide && !guides.map((guide) => guide._id).includes(el.guide))
            )
        } else if (isGetAll) {
            listBooking = listBooking.filter((el) => el.guide)
        } else {
            listBooking = listBooking.filter((el) => el.guide === guide)
        }

        return (
            <>
                {listBooking.map((booking, idx) => (
                    <div key={`booking-${column.name}-${guide}-${idx}`}>{bookingInfo(booking)}</div>
                ))}
            </>
        )
    }

    const calendarDate = () => {
        if (firstDay) {
            const startDate = new Date(firstDay)
            const endDate = new Date(new Date(firstDay).setDate(new Date(firstDay).getDate() + 6))
            return `${formatInTimeZone(startDate, 'UTC', 'dd')} - ${formatInTimeZone(
                endDate,
                'UTC',
                'dd MMMM yyyy'
            )}`
        }
        return ``
    }

    const getPeriod = () => {
        if (firstDay) {
            const startDate = new Date(firstDay)
            const endDate = new Date(new Date(firstDay).setDate(new Date(firstDay).getDate() + 6))
            return `${new Date(startDate).getDate().toString().padStart(2, '0')}/${startDate
                .getMonth()
                .toString()
                .padStart(2, '0')} - 
            ${new Date(endDate).getDate().toString().padStart(2, '0')}/${endDate
                .getMonth()
                .toString()
                .padStart(2, '0')}`
        }
        return ``
    }

    const styleByDay = (column) => {
        if (column.startTime) {
            const today = new Date()
            const specificDate = new Date(new Date(column.startTime).setHours(0, 0, 0))

            const formattedToday = new Date(today.getFullYear(), today.getMonth(), today.getDate())
            const formattedSpecificDate = new Date(
                specificDate.getFullYear(),
                specificDate.getMonth(),
                specificDate.getDate()
            )

            if (formattedToday > formattedSpecificDate) {
                return '!opacity-50'
            } else if (formattedToday < formattedSpecificDate) {
                return ''
            } else {
                return '!bg-t_blue_30'
            }
        }
        return ''
    }

    const filterList = () => {
        if (user.role === 'guide') {
            const agencies = (user.agencies || []).map((el) => {
                return { label: `${el.name}`, value: el._id }
            })
            return (
                <div className="flex space-x-4">
                    <SelectOption
                        classes={{ container: 'w-44 sm:w-48' }}
                        options={[...agencies]}
                        value={filterParams.agency}
                        isClearable={true}
                        onChange={(value) => {
                            dispatch(setParams({ agency: value }))
                            onReload({
                                startDate: firstDay,
                                agency: value || '',
                            })
                        }}
                        placeholder={t('placeholder.agency')}
                    />
                </div>
            )
        }

        return (
            <div className="flex space-x-4">
                <SelectOption
                    classes={{ container: 'w-48' }}
                    options={[...cityList]}
                    value={filterParams.city}
                    isClearable={true}
                    onChange={(value) => {
                        dispatch(setParams({ city: value }))
                        onReload({
                            startDate: firstDay,
                            visitType: filterParams.visitType || '',
                            guide: filterParams.guide || '',
                            status: filterParams.status || '',
                            city: value || '',
                        })
                    }}
                    placeholder={'City'}
                />
                <SelectOption
                    classes={{ container: 'w-48' }}
                    options={[...visitList]}
                    value={filterParams.visitType}
                    isClearable={true}
                    onChange={(value) => {
                        dispatch(setParams({ visitType: value }))
                        onReload({
                            startDate: firstDay,
                            visitType: value || '',
                            guide: filterParams.guide || '',
                            status: filterParams.status || '',
                            city: filterParams.city || '',
                        })
                    }}
                    placeholder={'Visit'}
                />
                <SelectOption
                    classes={{ container: 'w-48' }}
                    options={[...allGuideList].map((el) => {
                        return { label: `${el.firstname} ${el.lastname}`, value: el._id }
                    })}
                    value={filterParams.guide}
                    isClearable={true}
                    onChange={(value) => {
                        dispatch(setParams({ guide: value }))
                        onReload({
                            startDate: firstDay,
                            visitType: filterParams.visitType || '',
                            guide: value || '',
                            status: filterParams.status || '',
                            city: filterParams.city || '',
                        })
                    }}
                    placeholder={'Guide'}
                />
                <SelectOption
                    classes={{ container: 'w-48' }}
                    options={statusOptions()}
                    value={filterParams.status}
                    isClearable={true}
                    onChange={(value) => {
                        dispatch(setParams({ status: value }))
                        onReload({
                            startDate: firstDay,
                            visitType: filterParams.visitType || '',
                            guide: filterParams.guide || '',
                            status: value || '',
                            city: filterParams.city || '',
                        })
                    }}
                    placeholder={'Status'}
                />
            </div>
        )
    }

    return (
        <>
            <div className="h-24 mb-8 p-4 w-full">
                <div className="flex flex-wrap space-y-4 sm:space-y-0 sm:space-x-4 sm:items-center">
                    <div className="text-3xl font-bold">{calendarDate()}</div>
                    <div className="flex items-center">
                        <div
                            className="w-7 h-7 bg-t_gray_26 border flex justify-center items-center"
                            onClick={() => changeWeek(false)}
                        >
                            <ChevronLeft />
                        </div>
                        <div className="w-16 h-7 text-xs bg-t_gray_26 border flex justify-center items-center">
                            {t('calendar.today')}
                        </div>
                        <div
                            className="w-7 h-7 bg-t_gray_26 border flex justify-center items-center"
                            onClick={() => changeWeek()}
                        >
                            <ChevronRight />
                        </div>
                    </div>
                    <div className="sm:hidden pl-2 pb-2">{filterList()}</div>
                </div>
                <div className="hidden sm:block mt-3">{filterList()}</div>
            </div>
            <div className={classNames('w-full rounded-lg bg-white relative')}>
                {loading && <Loading isAbsolute={true} />}
                <div className="grid grid-cols-1 sm:grid-cols-8 w-full sm:max-h-t_screen_s12 sm:overflow-y-auto">
                    {columns.map((column, idx) => (
                        <div
                            className={classNames(
                                'p-3 border-r border-t_gray_250 bg-white hidden sm:inline',
                                idx === 0 && 'rounded-tl-lg',
                                idx === columns.length - 1 && 'rounded-tr-lg border-r-white',
                                styleByDay(column)
                            )}
                            key={`header-${idx}`}
                        >
                            {column.name !== 'period' && (
                                <>
                                    <div className="text-xs font-normal">{column.title}</div>
                                    <div className="font-bold">{getDateColumn(column)}</div>
                                </>
                            )}
                            {column.name === 'period' && (
                                <div className="w-full h-full flex justify-center items-center">
                                    {getPeriod()}
                                </div>
                            )}
                        </div>
                    ))}
                    {user.role !== 'guide' &&
                        columns.map((column, idx) => (
                            <div
                                className={classNames(
                                    'p-1 border-r border-t border-t_gray_250 bg-white',
                                    idx === columns.length - 1 && 'border-r-white',
                                    styleByDay(column)
                                )}
                                key={`header-${idx}`}
                            >
                                {column.name !== 'period' && (
                                    <div className="space-y-1">{bookingColumn(column)}</div>
                                )}
                                {column.name === 'period' && (
                                    <div className="w-full h-full flex justify-center items-center space-x-2">
                                        <Icon
                                            name={'emptySet'}
                                            classes={classNames('w-6 h-6 text-t_gray_360 ')}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                        <div className="text-t_gray_600 text-xs font-semibold">
                                            {t('calendar.unstaffedTour')}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    {guides.map((guide, guideIdx) =>
                        columns.map((column, idx) => (
                            <div
                                key={`header-mobile-${idx}-${guideIdx}`}
                                className={classNames(
                                    'p-1 sm:border-r sm:border-t border-t_gray_250 bg-white',
                                    idx === columns.length - 1 && 'border-r-white'
                                    // styleByDay(column)
                                )}
                            >
                                <div className={classNames('pt-3 pb-1 px-2 bg-white sm:hidden')}>
                                    {column.name !== 'period' && (
                                        <div className="flex space-x-2 text-xs font-semibold">
                                            <div className="">{column.title}</div>
                                            <div className="">{getDateColumn(column)}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="w-full h-full">
                                    {column.name !== 'period' && (
                                        <div className="space-y-1">
                                            {bookingColumn(column, guide._id, !!guide.isGetAll)}
                                        </div>
                                    )}
                                    {column.name === 'period' && !guide.isGetAll && (
                                        <div className="w-full h-full items-center space-x-2 pl-6 hidden sm:flex">
                                            <img
                                                src={
                                                    guide.avatar
                                                        ? getS3Link(guide.avatar)
                                                        : 'https://lh3.googleusercontent.com/proxy/ttFZIprg1NiaBXg-Aft4aWibNaZA7ERkqtwc5Pi63Wy3qF850fcXTxf9FBCss3N0zLZA0GfSfzUKv2WPPvLF65PJMLfYyEcCweFVCUU'
                                                }
                                                className="w-5 h-5 rounded-full"
                                                alt={'avatar'}
                                            />
                                            <div className="text-t_gray_600 text-xs font-semibold">
                                                {`${guide.firstname} ${guide.lastname}`}
                                            </div>
                                        </div>
                                    )}
                                    {column.name === 'period' && guide.isGetAll && (
                                        <div className="w-full h-full items-center space-x-2 pl-6 hidden sm:flex">
                                            <div className="text-t_gray_600 text-xs font-semibold flex justify-center items-center">
                                                <Icon
                                                    name={'check'}
                                                    classes={classNames('w-6 h-6 text-t_gray_360 ')}
                                                />
                                                <p>{t('calendar.scheduledTour')}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>

            {isShowFormModal && (
                <Modal
                    title={modal.title}
                    classes={{ content: '', children: '' }}
                    onCancel={() => setIsShowFormModal(false)}
                >
                    <FormComponent
                        item={currentItem}
                        onActionClick={handleActionClick}
                        onCancel={() => setIsShowFormModal(false)}
                        onSuccess={() => {
                            setIsShowFormModal(false)
                            onReload({ startDate: new Date(firstDay) })
                        }}
                    />
                </Modal>
            )}

            {isShowConfirmModal && (
                <Modal onCancel={() => setIsShowConfirmModal(false)}>
                    <>
                        <p className="text-center pt-4 text-3xl font-bold whitespace-pre-line">
                            {t('modal.areYouSure')}
                        </p>
                        {actionType === `cancel` && (
                            <p className="text-center pt-2 text-base font-normal whitespace-pre-line">
                                {t('modal.youAreAboutToCancel')}
                            </p>
                        )}
                        <div className="flex space-x-2 sm:space-x-6 justify-center my-3 pt-6">
                            <Button
                                text={t('button.seeTheDetailsAgain')}
                                classes={{
                                    button: '!px-2 sm:px-9 py-2 bg-white !text-t_purple_500',
                                }}
                                onClick={() => {
                                    setIsShowConfirmModal(false)
                                    setIsShowFormModal(true)
                                }}
                            />
                            <Button
                                text={t('button.yesIAm')}
                                classes={{
                                    button: 'px-2 sm:px-7 py-2',
                                }}
                                loading={confirmLoading}
                                onClick={handleConfirm}
                            />
                        </div>
                    </>
                </Modal>
            )}
        </>
    )
}

export default memo(Calendar)
