import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

import userPersistReducer from './persisted/userSlice'
import userReducer from './userSlice'

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, userPersistReducer)

const store = configureStore({
    reducer: { persist: persistedReducer, user: userReducer },
    middleware: [thunk],
})

const persistor = persistStore(store)
export { store, persistor }
