import { memo, useEffect, useState } from 'react'
import { Button } from '@Views'
import { useTranslation } from 'react-i18next'
import { getS3Link } from '@Libs/hepler'
import { format } from 'date-fns'
import DOMPurify from 'dompurify'
import { api } from '@Api'
import { userImg } from '@Assets/images'

const NotificationItem = ({ data = {}, onAction }) => {
    const { t } = useTranslation()
    const [timeDistance, setTimeDistance] = useState(0)
    const [recipient, setRecipient] = useState({})
    const [guest, setGuest] = useState({})

    const [seen, setSeen] = useState(false)

    useEffect(() => {
        const millisBetween = new Date() - new Date(data.createdAt)
        setTimeDistance((millisBetween / (1000 * 60)).toFixed(0))
        setRecipient(
            ['new-booking', 'update-booking'].includes(data.action)
                ? data.adminInfo || data.guideInfo
                : data.guideInfo
        )
        setGuest(data?.bookingInfo?.guest ?? {})
        setSeen(data.seen || false)
    }, [data])

    const handleSeen = async () => {
        setSeen(true)
        await api.updateNotify(data._id, { seen: true })
    }

    return (
        <div
            className={`py-2 sm:py-0 sm:h-28 border-b ${!seen && 'bg-t_blue_50'}`}
            onClick={() => {
                handleSeen()
            }}
        >
            {data.action === 'assign-guide' && (
                <div className="h-full flex px-4 sm:px-8 justify-between space-x-2">
                    <div className="flex items-center space-x-4">
                        <img
                            src={data.agencyInfo?.logo ? getS3Link(data.agencyInfo?.logo) : userImg}
                            className="w-12 h-12 rounded-full"
                            alt={'avatar'}
                        />
                        <div className=" space-y-2">
                            <p className="">
                                <span className="font-semibold">{data.agencyInfo?.name}</span>{' '}
                                {t('notification.assignedNotification')}{' '}
                                <span className="font-semibold">{data.bookingInfo?.visitType}</span>{' '}
                                on{' '}
                                <span className="font-semibold">
                                    {format(data.bookingInfo?.date || new Date(), 'dd/MM/yyyy')}
                                </span>
                                .
                            </p>
                            {data.bookingInfo?.guide === data.guide && (
                                <div className="flex space-x-2">
                                    <Button
                                        text={t('button.seeDetails')}
                                        classes={{
                                            button: 'px-2 sm:px-7 py-1',
                                        }}
                                        onClick={() =>
                                            onAction({
                                                dataItem: data,
                                                type: 'seeDetail',
                                            })
                                        }
                                    />
                                    <Button
                                        text={t('button.decline')}
                                        classes={{
                                            button: '!px-2 sm:px-9 py-1 bg-white !text-t_purple_500',
                                        }}
                                        onClick={() =>
                                            onAction({ dataItem: data, type: 'decline' })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col items-end sm:items-center sm:justify-center w-20">
                        {timeDistance >= 1440 && (
                            <p>{(timeDistance - (timeDistance % 1440)) / 1440}d</p>
                        )}
                        {timeDistance < 1440 && (
                            <p>
                                {(timeDistance - (timeDistance % 60)) / 60}h
                                {timeDistance % 60 ? `${timeDistance % 60}m` : ''}
                            </p>
                        )}
                    </div>
                </div>
            )}

            {data.recipientType === 'agency' && (
                <div className="h-full flex px-8 justify-between">
                    <div className="flex items-center space-x-4">
                        <img
                            src={recipient?.avatar ? getS3Link(recipient?.avatar) : userImg}
                            className="w-12 h-12 rounded-full"
                            alt={'avatar'}
                        />
                        <div className=" space-y-2">
                            {data.action === 'accepted-booking' && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            t('notification.acceptedNotification', {
                                                userName: `<b>${recipient?.firstname || ''} ${
                                                    recipient?.lastname || ''
                                                }</b>`,
                                                tour: `<b>${data.bookingInfo?.visitType}</b>`,
                                                date: `<b>${format(
                                                    data.bookingInfo?.date || new Date(),
                                                    'dd/MM/yyyy'
                                                )}</b>`,
                                            })
                                        ),
                                    }}
                                />
                            )}
                            {data.action === 'rejected-booking' && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            t('notification.rejectedNotification', {
                                                userName: `<b>${recipient?.firstname || ''} ${
                                                    recipient?.lastname || ''
                                                }</b>`,
                                                tour: `<b>${data.bookingInfo?.visitType}</b>`,
                                                date: `<b>${format(
                                                    data.bookingInfo?.date || new Date(),
                                                    'dd/MM/yyyy'
                                                )}</b>`,
                                            })
                                        ),
                                    }}
                                />
                            )}
                            {data.action === 'update-booking' && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            t('notification.updatedNotification', {
                                                userName: `<b>${recipient?.firstname || ''} ${
                                                    recipient?.lastname || ''
                                                }</b>`,
                                                tour: `<b>${data.bookingInfo?.visitType}</b>`,
                                                date: `<b>${format(
                                                    data.bookingInfo?.date || new Date(),
                                                    'dd/MM/yyyy'
                                                )}</b>`,
                                                numPerson: `${guest.adultNb + guest.childrenNb}`,
                                            })
                                        ),
                                    }}
                                />
                            )}
                            {data.action === 'new-booking' && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            t('notification.bookedNotification', {
                                                userName: `<b>${recipient?.firstname || ''} ${
                                                    recipient?.lastname || ''
                                                }</b>`,
                                                tour: `<b>${data.bookingInfo?.visitType}</b>`,
                                                date: `<b>${format(
                                                    data.bookingInfo?.date || new Date(),
                                                    'dd/MM/yyyy'
                                                )}</b>`,
                                                numPerson: `${guest.adultNb + guest.childrenNb}`,
                                            })
                                        ),
                                    }}
                                />
                            )}

                            {!data.bookingInfo?.guide &&
                                ['new-booking', 'rejected-booking'].includes(data.action) && (
                                    <div className="flex space-x-2">
                                        <Button
                                            text={
                                                data.action === 'new-booking'
                                                    ? t('button.assignAGuide')
                                                    : t('button.assignAnotherGuide')
                                            }
                                            classes={{
                                                button: `px-2 sm:px-7 py-1 ${
                                                    data.action === 'rejected-booking' &&
                                                    'bg-t_red_500 border-t_red_500'
                                                }`,
                                            }}
                                            onClick={() => {
                                                // handleSeen()
                                                onAction({
                                                    dataItem: data,
                                                    type: 'seeDetail',
                                                })
                                            }}
                                        />
                                    </div>
                                )}
                            {data.action === 'update-booking' && (
                                <div className="flex space-x-2">
                                    <Button
                                        text={t('button.seeDetails')}
                                        classes={{
                                            button: 'px-2 sm:px-7 py-1 bg-white border-[#475569] !text-[#334155]',
                                        }}
                                        onClick={() => {
                                            // handleSeen()
                                            onAction({
                                                dataItem: data,
                                                type: 'seeDetail',
                                            })
                                        }}
                                    />
                                </div>
                            )}
                            {data.action === 'new-guide' && (
                                <>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                t('notification.newGuideNotification', {
                                                    userName: `<b>${recipient?.firstname || ''} ${
                                                        recipient?.lastname || ''
                                                    }</b>`,
                                                })
                                            ),
                                        }}
                                    />
                                    {data.guideInfo && (
                                        <Button
                                            text={t('button.seeDetails')}
                                            classes={{
                                                button: 'px-2 sm:px-7 py-1 bg-white border-[#475569] !text-[#334155]',
                                            }}
                                            onClick={() => {
                                                // handleSeen()
                                                onAction({
                                                    dataItem: data,
                                                    type: 'seeDetailGuide',
                                                })
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center relative w-20">
                        <p>
                            {(timeDistance - (timeDistance % 60)) / 60}h
                            {timeDistance % 60 ? `${timeDistance % 60}m` : ''}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}
export default memo(NotificationItem)
