import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, FormInput, Button } from '@Views'
import { setSignupData } from '@Store/persisted/userSlice'
import { validateForm } from '@Libs/validation'
import { bookingProviderValidation } from '@Constants'
import { api } from '@Api'
import { toastError } from '@Libs/toast'
import { useTranslation } from 'react-i18next'

export function BokunConnection({ onCancel }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const signupData = useSelector((state) => state.persist.signupData)
    const [formData, setFormData] = useState({ ...signupData.provider })
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSubmit = async () => {
        try {
            setLoading(true)
            const errorObj = validateForm(bookingProviderValidation, formData)
            setErrors(errorObj)
            if (!Object.keys(errorObj).length) {
                const data = await api.checkBokunConnection(formData)
                if (data.invalidKey) {
                    toastError(t('bookingProvider.bokunConnectionFailed'))
                } else {
                    dispatch(setSignupData({ provider: formData }))
                    navigate('/signup/invite-guide')
                }
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            title="bookingProvider.bokunConnection"
            onCancel={onCancel}
            classes={{ children: 'w-[33rem] px-14 pb-8' }}
        >
            <FormInput
                name="domain"
                classes={{ label: 'mt-2' }}
                value={formData.domain}
                placeholder={'placeholder.domain'}
                error={{ message: errors?.domain, field: 'placeholder.domain' }}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
            />
            <FormInput
                name="accessKey"
                classes={{ label: 'mt-2' }}
                value={formData.accessKey}
                placeholder={'placeholder.accessKey'}
                error={{ message: errors?.accessKey, field: 'placeholder.accessKey' }}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
            />
            <FormInput
                name="secretKey"
                classes={{ label: 'mt-2' }}
                value={formData.secretKey}
                placeholder={'placeholder.secretKey'}
                error={{ message: errors?.secretKey, field: 'placeholder.secretKey' }}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
            />
            <Button
                text={'button.continue'}
                loading={loading}
                onClick={handleSubmit}
                classes={{ button: 'mt-5 px-8 w-full font-medium' }}
            />
        </Modal>
    )
}
