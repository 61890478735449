export const listMenuAgence = [
    {
        label: 'menu.calendar',
        route: '/agence/calendar',
        icon: 'calendar',
        iconClass: '!h-8',
    },
    {
        label: 'menu.guides',
        route: '/agence/guides',
        icon: 'userFriends',
        iconClass: '!h-8',
    },
    {
        label: 'menu.notifications',
        route: '/agence/notifications',
        icon: 'bell',
        iconClass: '!h-8',
    },
]

export const listMenuGuide = [
    {
        label: 'menu.myTours',
        route: '/guide/tour',
        icon: 'tour',
        iconClass: '!h-8',
    },
    {
        label: 'menu.notifications',
        route: '/guide/notifications',
        icon: 'bell',
        iconClass: '!h-8',
    },
]
