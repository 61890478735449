import { Icon, SearchSelectOption } from '@Views'
import { useState, memo, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { googleMarkerImg } from '@Assets/images'
import L from 'leaflet'
import useClickOutside from 'react-cool-onclickoutside'

const SetViewOnClick = ({ animateRef, coords }) => {
    const map = useMap()
    map.setView(coords, map.getZoom(), {
        animate: animateRef.current || false,
    })
    return null
}

const MeetingPoint = ({ address, onChange, error, isDisabled = false }) => {
    const { t } = useTranslation()
    const [markerPoint, setMarkerPoint] = useState(null)
    const animateRef = useRef(false)
    const [openEditor, setOpenEditor] = useState(false)
    const customIcon = L.icon({
        iconUrl: googleMarkerImg,
        iconSize: [25, 41],
    })

    const getAddressOption = async (inputValue) => {
        let options = []
        await axios({
            method: 'get',
            url: `https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&limit=3&q=${inputValue}`,
            headers: { 'Accept-Language': navigator.language },
        }).then(function (response) {
            const result = response.data
            options = result.map((item) => ({
                label: item.display_name ?? '-',
                value: { lat: item.lat, lng: item.lon },
            }))
        })
        return options
    }

    const handleAddressChange = (position) => {
        onChange({
            ...position.value,
            name: position.label,
        })
    }

    useEffect(() => {
        if (address?.lat) {
            animateRef.current = true
            setMarkerPoint([address.lat, address.lng])
        }
        if (!address?.lat && address?.name) {
            getAddressCoordinates(address?.name)
        }
    }, [address])

    const getAddressCoordinates = async (name) => {
        let options = await getAddressOption(name)
        if (options.length) {
            handleAddressChange(options[0])
        }
    }

    const searchBox = useClickOutside(() => {
        setOpenEditor(false)
    })

    return (
        <div className="flex flex-col gap-y-1">
            {!isDisabled && (
                <div className="flex items-center gap-x-2 text-xs">
                    {t('calendar.meetingPoint')}:{' '}
                    <Icon
                        name={'edit2'}
                        classes={'w-4 h-4 text-t_gray_300 cursor-pointer'}
                        onClick={() => setOpenEditor(!openEditor)}
                    />
                </div>
            )}

            {openEditor ? (
                <div ref={searchBox}>
                    <SearchSelectOption
                        type="text"
                        classes={{ control: 'h-8' }}
                        placeholder={t('placeholder.meetingPoint')}
                        getDataOptions={getAddressOption}
                        menuPlacement="top"
                        value={
                            address?.name && {
                                label: address?.name ?? '',
                            }
                        }
                        onChange={(value) => handleAddressChange(value)}
                    />
                </div>
            ) : (
                <p
                    className="text-t_blue_500 underline text-xs"
                    onClick={() => setOpenEditor(!isDisabled ? !openEditor : openEditor)}
                >
                    {address?.name ?? ''}
                </p>
            )}

            {error && <p className="text-t_red_400 text-xs">{error}</p>}

            <div className="w-full h-44 mt-1">
                <MapContainer
                    center={[48.86, 2.35]}
                    zoom={13}
                    scrollWheelZoom={false}
                    style={{ width: '100%', height: '100%' }}
                >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {markerPoint && (
                        <Marker position={markerPoint} icon={customIcon}>
                            <SetViewOnClick coords={markerPoint} animateRef={animateRef} />
                        </Marker>
                    )}
                </MapContainer>
            </div>
        </div>
    )
}
export default memo(MeetingPoint)
