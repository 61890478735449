import { memo } from 'react'
import classNames from 'classnames'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'

const Modal = ({ title, children, classes = {}, onClick, onCancel }) => {
    const { t } = useTranslation()

    return (
        <div className={classNames('fixed top-0 left-0 z-20')}>
            <div
                className={
                    'fixed w-full h-full bg-black bg-opacity-25 flex items-center justify-center'
                }
                onClick={onClick}
            >
                <div
                    className={classNames(
                        'bg-white rounded-2xl shadow-sm relative',
                        classes.content
                    )}
                >
                    <div
                        className={classNames(
                            'flex justify-between items-center rounded-t-2xl py-4 px-5'
                        )}
                    >
                        <p className={classNames('text-lg font-semibold w-full', classes.title)}>
                            {t(title)}
                        </p>
                        <X className="rounded-md  w-6 cursor-pointer" onClick={onCancel} />
                    </div>

                    <div
                        className={classNames(
                            'p-5 pt-0 max-h-t_screen_s8 overflow-auto h-full',
                            classes.children
                        )}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Modal)
