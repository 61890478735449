import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUser, setToken } from '@Store/persisted/userSlice'
import { FormInput, Button, Line } from '@Views'
import { GoogleButton } from './components/GoogleButton'
import { api } from '@Api'
import { validateForm } from '@Libs/validation'
import { loginValidation } from '@Constants'
import { useTranslation } from 'react-i18next'
import { Eye, EyeOff } from 'react-feather'
import { logoTourAssistImg } from '@Assets/images'

export default function LoginPage() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [formData, setFormData] = useState({})
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingGoogle, setLoadingGoogle] = useState(false)
    const [isShowPassword, setIsShowPassword] = useState(false)

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleIconClick = () => setIsShowPassword(!isShowPassword)

    const handleSubmit = async () => {
        try {
            const errorObj = validateForm(loginValidation, formData)
            setErrors(errorObj)
            if (!Object.keys(errorObj).length) {
                setLoading(true)
                const user = await api.login(formData)
                dispatch(setToken(user.token))
                dispatch(setUser(user.user))
                localStorage.setItem('jwt', user.token)
                navigate('/')
            }
        } finally {
            setLoading(false)
        }
    }

    const handleGoogleSubmit = async (googleData) => {
        try {
            setErrors({})
            setLoadingGoogle(true)
            const user = await api.authenticationGoogle({ token: googleData.accessToken })
            dispatch(setToken(user.token))
            dispatch(setUser(user.user))
            localStorage.setItem('jwt', user.token)
            navigate('/')
        } finally {
            setLoadingGoogle(false)
        }
    }

    return (
        <>
            <div className="flex items-center justify-center gap-2">
                <img src={logoTourAssistImg} className="h-12" alt={'logo'} />
            </div>
            <div className="text-center mt-3 mb-5 text-lg font-semibold">{t('login.title')}</div>
            <FormInput
                name="email"
                value={formData.email}
                placeholder={'placeholder.email'}
                error={{ message: errors?.email, field: 'placeholder.email' }}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
            />
            <FormInput
                classes={{ label: 'mt-3' }}
                type={isShowPassword ? 'text' : 'password'}
                name="password"
                value={formData.password}
                placeholder={'placeholder.password'}
                error={{ message: errors?.password, field: 'placeholder.password' }}
                icon={isShowPassword ? EyeOff : Eye}
                handleChange={handleChange}
                handleIconClick={handleIconClick}
                handleKeyDown={handleKeyDown}
            />
            <div className="flex justify-center">
                <Button
                    text={'login.signin'}
                    loading={loading}
                    onClick={handleSubmit}
                    classes={{ button: 'mt-4 px-8 w-full font-medium' }}
                />
            </div>
            <div className="text-center my-5">
                {t('login.notAccount')}
                <Link to="/signup" className="text-t_purple_500 hover:underline ml-2">
                    {t('login.createAccount')}
                </Link>
            </div>
            <div className="mb-4">
                <Line />
                <p className="bg-white px-2 -mt-3 text-center w-fit mx-auto text-t_gray_500">
                    {t('login.or')}
                </p>
            </div>
            <GoogleButton onSuccess={handleGoogleSubmit} loading={loadingGoogle} />
            <div className="text-center text-xs flex items-center gap-2 justify-center mt-6">
                {t('login.terms')} <span className="h-2 w-[0.063rem] bg-gray-300 block"></span>{' '}
                {t('login.policy')}
            </div>
        </>
    )
}
